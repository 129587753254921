import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, Divider, FormControl, InputLabel, MenuItem, Select, Typography, Paper, InputBase, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import StatCard from './StatCard';
import TrainingSetup from './TrainingSetup';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;


    // State variables to manage different aspects of the component
const ProjectSelection = () => {
    const [projectid, setProjectid] = useState('No');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [projectLabels, setProjectLabels] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');
    const [selectedRules, setSelectedRules] = useState([]);
    const [newCondition, setNewCondition] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editedCondition, setEditedCondition] = useState('');

    // Static data for extraction rules, used as an initial state
    const extraction = [
        { name: "Name", id: 1, project_id: 2, meta: { ExtractionRules: ["The word will be alpha", "DOB in numbers"] }},
        { name: "Age", id: 2, project_id: 2, meta: { ExtractionRules: ["The word will be alpha", "Rule2"] }}
    ];

    // State to manage the extraction rules
    const [extractionRules, setExtractionRules] = useState(extraction);

    // Handles the selection of a label from the dropdown
    const handleChange = (event) => {
        const selectedLabelName = event.target.value;
        setSelectedLabel(selectedLabelName);
        const selectedRule = extractionRules.find(rule => rule.name === selectedLabelName);
        setSelectedRules(selectedRule ? selectedRule.meta.ExtractionRules : []);
    };

    // Handles adding a new condition to the selected rules
    const handleAddCondition = () => {
        if (newCondition.trim() === '') return;
        setSelectedRules(prevRules => [...prevRules, newCondition]);
        const updatedRules = extractionRules.map(rule => 
            rule.name === selectedLabel ? { ...rule, meta: { ExtractionRules: [...rule.meta.ExtractionRules, newCondition] }} : rule
        );
        setExtractionRules(updatedRules);
        setNewCondition('');
    };

    // Handles the 'Enter' key press to add or save a condition
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (editIndex !== null) {
                handleSaveEdit();
            } else {
                handleAddCondition();
            }
        }
    };

    // Handles deleting a condition from the selected rules
    const handleDeleteCondition = (index) => {
        const updatedRules = selectedRules.filter((_, i) => i !== index);
        setSelectedRules(updatedRules);
        const updatedExtractionRules = extractionRules.map(rule => 
            rule.name === selectedLabel ? { ...rule, meta: { ExtractionRules: updatedRules }} : rule
        );
        setExtractionRules(updatedExtractionRules);
    };

    // Handles the editing of a specific condition
    const handleEditCondition = (index) => {
        setEditIndex(index);
        setEditedCondition(selectedRules[index]);
    };

    // Saves the edited condition and updates the state
    const handleSaveEdit = () => {
        if (editedCondition.trim() === '') return;
        const updatedRules = selectedRules.map((rule, i) => i === editIndex ? editedCondition : rule);
        setSelectedRules(updatedRules);
        const updatedExtractionRules = extractionRules.map(rule => 
            rule.name === selectedLabel ? { ...rule, meta: { ExtractionRules: updatedRules }} : rule
        );
        setExtractionRules(updatedExtractionRules);
        setEditIndex(null);
        setEditedCondition('');
    };

    // Fetches the project ID when the component mounts
    useEffect(() => {
        setProjectid(window.globalVar1);
    }, []);

    // Fetches project data and labels when the component mounts or the project ID changes
    useEffect(() => {
        const fetchProjects = async (extractionType) => {
            try {
                const response = await axios.get(apiURL + `/projects/${extractionType}/`);
                setProjectData(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching projects');
            } finally {
                setLoading(false);
            }
        };

        const fetchProjectLabels = async (extractionType) => {
            try {
                const response = await axios.get(apiURL + `/projects/${extractionType}/labels/`);
                setProjectLabels(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching project labels');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects(window.globalVar1);
        fetchProjectLabels(window.globalVar1);
    }, []);

    // Static list for rendering selection cards
    const selectionList = [
        { id: 1, header: 'Train Model', content: 'Create your own', icon: ModelTrainingOutlinedIcon },
        { id: 2, header: 'Annotate Dataset', content: 'Create your own', icon: FormatShapesOutlinedIcon },
        { id: 3, header: 'Add Extraction Rules', content: 'Create your own', icon: RuleFolderOutlinedIcon },
        { id: 4, header: 'Edit Models', content: 'Create your own', icon: EditNoteOutlinedIcon },
    ];

    // Handles the card click event and updates the selected ID
    const handleCardClick = (id) => {
        setSelectedId(id);
    };

    return (
        <Box>
            <h1>Project Selection</h1>
            <Divider />
            <Divider />
            <Box sx={{ display: 'flex', gap: 7, py: 3 }}>
                {selectionList.map((list) => {
                    const IconComponent = list.icon;
                    return (
                        <Card
                            key={list.id}
                            onClick={() => handleCardClick(list.id)}
                            sx={{
                                width: 300,
                                height: 100,
                                borderRadius: 3,
                                boxShadow: 0.2,
                                background: 'linear-gradient(to right, #fff, #e3d4f9)',
                                '&:hover': { border: '1px solid #d8d8d8' }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    height: 100,
                                    width: 300,
                                    p: 2,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "left",
                                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                                        borderRadius: '0% 50% 90% 0%',
                                        height: 100,
                                        width: 200,
                                        position: 'absolute',
                                        left: -80
                                    }}
                                >
                                    <IconComponent sx={{ position: 'absolute', fontSize: 50, color: '#fff', left: 100, top: 20 }} />
                                </Box>
                                <Box sx={{ ml: 15 }}>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", color: '#674f87' }}>
                                        {list.header}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    );
                })}
            </Box>

            {selectedId === 3 ? (
                <Box id="dropdown">
                    <h1>Custom Your Extraction Rules</h1>
                    <Divider />
                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ m: 1, minWidth: 220, height: '40px' }} size="small">
                            <InputLabel id="demo-select-small-label">Select Label</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedLabel}
                                label="Select Label"
                                onChange={handleChange}
                                sx={{
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, -6px) scale(0.75)'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            padding: '10px 14px',
                                        },
                                    },
                                }}
                            >
                                {extractionRules.map((rule, index) => (
                                    <MenuItem key={index} value={rule.name}>{rule.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Paper
                            component="form"
                            sx={{ display: 'flex', alignItems: 'center', width: 600, height: '40px', m: 1 }}
                            onKeyPress={handleKeyPress}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Add new condition"
                                value={newCondition}
                                onChange={(e) => setNewCondition(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} onClick={handleAddCondition}>
                                <AddIcon sx={{color:'#ef798a'}}/>
                            </IconButton>
                        </Paper>
                    </Box>
                    <Box sx={{ display: 'flex', marginLeft:1}}>
                        <TableContainer component={Paper} sx={{maxWidth: 850}}>
                            <Table sx={{ minWidth: 350, maxWidth: 850 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', color: '#674f87' }}>CONDITIONS</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', color: '#674f87' }}>EDIT</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', color: '#674f87' }}>DELETE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedRules.map((rule, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {editIndex === index ? (
                                                    <InputBase
                                                        sx={{ ml: 1, flex: 1 }}
                                                        value={editedCondition}
                                                        onChange={(e) => setEditedCondition(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    rule
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ cursor: 'pointer' }}>
                                                {editIndex === index ? (
                                                    <IconButton onClick={handleSaveEdit}>
                                                        <AddIcon />
                                                    </IconButton>
                                                ) : (
                                                    <EditNoteIcon sx={{color:'#ef798a'}} onClick={() => handleEditCondition(index)} />
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ cursor: 'pointer' }}>
                                                <DeleteIcon sx={{color:'#ef798a'}} onClick={() => handleDeleteCondition(index)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
             ) : selectedId === 2 ? (
                <div >
                <TrainingSetup></TrainingSetup>
                </div> 
            ) : (
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <StatCard title="Project Name" value={projectData.name} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Project Type" value={projectData.project_type} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Created Date" value={projectData.created_at} icon={ModelTrainingOutlinedIcon} isIncrease={false} />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <StatCard title="No of Trainings" value={"2"} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Label Count" value={"20"} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Last Trained Date" value={projectData.created_at} icon={ModelTrainingOutlinedIcon} isIncrease={false} />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <StatCard title="Active Version" value={"Version-2"} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Confidence Score" value={".97"} icon={ModelTrainingOutlinedIcon} isIncrease={true} />
                        <StatCard title="Trained Date" value={projectData.created_at} icon={ModelTrainingOutlinedIcon} isIncrease={false} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ProjectSelection;
