import React, { useState } from 'react';
import { Drawer, Toolbar, Divider, Box, List, ListItem, ListItemIcon, ListItemText, Tooltip, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mainNavbarItems, mainProfileItems } from './consts/navbarItems';
import { navbarStyles } from './styles';
import { useMediaQuery } from '@mui/material';
import { useNavbar } from './NavbarContext';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Navbar = () => {
    const navigate = useNavigate();
    const { collapsed, setCollapsed } = useNavbar();
    const isSmallerScreen = useMediaQuery('(max-width:600px)'); // Define your breakpoint

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    // Automatically toggle collapse based on screen size
    React.useEffect(() => {
        if (isSmallerScreen) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [isSmallerScreen]);

    const [open, setOpen] = useState({});

    const handleClick = (id) => {
        setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
    };

    return (
        <Drawer
            sx={{ ...navbarStyles.drawer, width: collapsed ? 57 : 200 }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar />
            <Divider />
            <Box sx={{ mt: 12 }}>
                <List>
                    {mainNavbarItems.map((item) => (
                        <React.Fragment key={item.id}>
                            {item.contents ? (
                                <>
                                    <ListItem
                                        sx={navbarStyles.list}
                                        button
                                        onClick={() => handleClick(item.id)}
                                    >
                                        {collapsed ? (
                                            <Tooltip title={item.label}>
                                                <ListItemIcon sx={navbarStyles.icons}>
                                                    {item.icon}
                                                </ListItemIcon>
                                            </Tooltip>
                                        ) : (
                                            <>
                                                <ListItemIcon sx={navbarStyles.icons}>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.label} />
                                                {open[item.id] ? <ExpandLess /> : <ExpandMore />}
                                            </>
                                        )}
                                    </ListItem>
                                    <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.contents.map((content) => (
                                                <ListItem
                                                    sx={{ ...navbarStyles.list, pl: 4 }}
                                                    button
                                                    key={content.id}
                                                    onClick={() => navigate(content.route)}
                                                >
                                                    <ListItemIcon sx={navbarStyles.icons}>
                                                        <content.icon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={content.header}  />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            ) : (
                                <ListItem
                                    sx={navbarStyles.list}
                                    button
                                    onClick={() => navigate(item.route)}
                                >
                                    {collapsed ? (
                                        <Tooltip title={item.label}>
                                            <ListItemIcon sx={navbarStyles.icons}>
                                                {item.icon}
                                            </ListItemIcon>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            <ListItemIcon sx={navbarStyles.icons}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.label} />
                                        </>
                                    )}
                                </ListItem>
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, mt: 12 }}>
                <List>
                    {mainProfileItems.map((item) => (
                        <ListItem
                            sx={navbarStyles.list}
                            button
                            key={item.id}
                            onClick={() => navigate(item.route)}
                        >
                            {collapsed ? (
                                <Tooltip title={item.label}>
                                    <ListItemIcon sx={navbarStyles.icons}>
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            ) : (
                                <>
                                    <ListItemIcon sx={navbarStyles.icons}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
                {!isSmallerScreen && (
                    <button onClick={toggleCollapse}>Toggle Collapse</button>
                )}
            </Box>
        </Drawer>
    );
};

export default Navbar;
