import React, { useState, useEffect } from 'react';
import GridWrapper from "../common/GridWrapper/GridWrapper";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Sample from '../../pages/images/PO.jpg';
// import Sample1 from '../../pages/images/007896060_00008.jpg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactPictureAnnotation } from "react-picture-annotation";
import './trainingSetup.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import _ from 'lodash';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const ImageArray = [
    { 'imagename': Sample }
]
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ImageAnnotator() {
    const [annotations, setAnnotations] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(100); // Zoom level state
    const [position, setPosition] = useState({ x: 0, y: 0 }); // Position state
    const [boxId, setBoxId] = useState()
    const [annotationColor, setAnnotationColor] = useState(null)
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [rightClick, setRightClick] = useState(false);
    const navigate = useNavigate();
    const [ocrDatas, setOcrDatas] = useState([]);
    const [initialTableLabels, setInitialTableLabels] = useState([
        { id: 1, labelName: 'Header', labelColor: '#000000', annotation_text: [] },
        { id: 2, labelName: 'Row', labelColor: '#1302fa', annotation_text: [] }
      ]);
    // getting data from previous page
    const location = useLocation();
    const { labelsInitial } = location.state || {};
    const { rows } = location.state || {};
    const { image_id } = location.state || {};
    const [image_details, setImageDetails] = useState(rows);
    const [line_image_details, setLineImageDetails] = useState([]);

    const [defaultLabels, setDefaultLabels] = useState(labelsInitial);

    const [defaultTableLabels, setDefaultTableLabels] = useState(initialTableLabels);
    const [selectedTab, setSelectedTab] = useState('field')

    const [pageSize, setPageSize] = useState({
        width: (window.innerWidth) - (window.innerWidth * (35 / 100)),
        height: (window.innerHeight) - (window.innerHeight * (16 / 100))
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEditTableAnnotation = (event, id) => {
        const updatedLabels = defaultTableLabels.map((label) =>
            label.id === id ? { ...label, annotation_text: event.target.value } : label
        );
        setDefaultTableLabels(updatedLabels);
    };

    const onResize = () => {
        setPageSize({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        fetchImageDetails();
        console.log('---> 123')
        return () => window.removeEventListener('resize', onResize);
    }, [annotations]);
    
    const fetchImageDetails = async () => {
        // console.log('--line_image_details--',line_image_details,_.isEmpty(line_image_details))
        if (_.isEmpty(line_image_details)) {
            try {
                const detailsResponse = await axios.get(apiURL + `/projects/${window.globalVar1}/single_image_details/${image_id}`);
                const details = detailsResponse.data.details;
                setLineImageDetails(details);

                if (details && details.ocr && details.ocr.ocr_data) {
                    setOcrDatas(details.ocr.ocr_data);
                }

                const imageResponse = await axios.get(apiURL + `/projects/${window.globalVar1}/image_file/${image_id}`, {
                    responseType: 'arraybuffer'
                });

                const contentType = imageResponse.headers['content-type'];
                if (contentType && contentType.includes('image/')) {
                    const blob = new Blob([imageResponse.data], { type: contentType });
                    setSelectedImage(URL.createObjectURL(blob));
                } else {
                    throw new Error(`Unexpected content type: ${contentType}`);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    // image_id, rows
    useEffect(() => {
        // Reset selection when rows or annotations change
        setSelectedRow(null);
        setSelectedAnnotation(null);
    }, [defaultLabels, annotations]);

    useEffect(() => {
        window.addEventListener('resize', onResize);


        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                    moveUp();
                    break;
                case 'ArrowDown':
                    moveDown();
                    break;
                case 'ArrowLeft':
                    moveLeft();
                    break;
                case 'ArrowRight':
                    moveRight();
                    break;
                default:
                    break;
            }
        };



        // window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('resize', onResize);
            // window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDragging]);


    const onSelect = (id) => {
        selectAnnotation(id)
        let selectedId = null;
        if (selectedTab === 'field') {
            defaultLabels.forEach(row => {
                if (row.annotation_text) {
                    row.annotation_text.forEach(annotation => {
                        if (annotation.bbox_ID === id) {
                            setSelectedLabel(row.id);
                            selectedId = row.id;
                        }
                    });
                }
            });
        } else if (selectedTab == 'table') {
            defaultTableLabels.forEach(row => {
                if (row.annotation_text) {
                    row.annotation_text.forEach(annotation => {
                        if (annotation.bbox_ID === id) {
                            setSelectedLabel(row.id);
                            selectedId = row.id;
                        }
                    });
                }
            });
        }

        if (selectedRow) {
            setSelectedRow(selectedRow);
            // setSelectedRowId(selectedRow.id);
        }
    };

    const selectAnnotation = (bboxIdString) => {
        if (typeof bboxIdString === 'string' && bboxIdString.trim() !== '') {
            const bboxId = bboxIdString; // Use the string directly
            const annotation = annotations.find(item => item.id === bboxId);
            if (annotation) {
                setSelectedAnnotation(annotation);
            } else {
                console.log('Annotation not found for bbox_ID:', bboxId);
            }
        } else {
            console.log('Invalid bbox_ID String:', bboxIdString);
        }
    };


    const addHeader = () => {
        const newHeader = { labelId: Date.now(), labelName: 'Header', labelColor: '#000000', annotation_text: [] };
        setDefaultTableLabels([...defaultTableLabels, newHeader]);
    };
    const addRow = () => {
        const newRow = { labelId: Date.now(), labelName: 'Row', labelColor: '#1302fa', annotation_text: [] };
        setDefaultTableLabels([...defaultTableLabels, newRow]);
    };
    const handleRightClick = (e) => {
        e.preventDefault(); // Prevent default context menu on right-click
        if (e.button === 2) {
            setRightClick(true); // Set rightClick state to true on right-click
        }
    };
    const onChange = (data) => {
        // Check if data is an array
        if (rightClick == false){
            if (!Array.isArray(data)) {
                return;
            } 
            if (selectedLabel) {
                setAnnotationColor(selectedLabel.labelColor);
                const lastAnnotation = data[data.length - 1];
                const updatedAnnotations = data.map(annotation =>
                    annotation.id === lastAnnotation.id ? lastAnnotation : annotation
                );
                setAnnotations(updatedAnnotations);
            } else {
                // setAnnotations(data);
            }
        }
    };


    const handleLabelClick = (label, box_id = '') => {
        setSelectedAnnotation(null);
        setSelectedLabel(label);
        setAnnotationColor(label.labelColor);

        if (box_id) {
            selectAnnotation(box_id); // Ensure bbox_ID is used correctly
        }

    };

    const handleEditAnnotation = (event, id) => {
        const newText = event.target.value;
        setDefaultLabels((prevLabels) =>
            prevLabels.map((label) =>
                label.id === id ? { ...label, annotation_text: newText } : label
            )
        );
    };

    const get_ocr_text = () => {
        let text = '';
        const { x, y, width, height } = annotations[annotations.length - 1].mark;
        const bbox = [x, y, x + width, y + height];

        let boxDetails = [];
        ocrDatas.forEach((item) => {
            const lineBox = item.bbox;
            if (
                lineBox[0] >= bbox[0] &&
                lineBox[1] >= bbox[1] &&
                lineBox[4] <= bbox[2] &&
                lineBox[5] <= bbox[3]
            ) {
                text = `${text} ${item.text}`;
                boxDetails.push(item);
            }
        });

        // console.log("Text_ID:",newItems);
        text = text.trim();
        // console.log(text);
        return {
            text,
            boxDetails
        };
    };


    useEffect(() => {
        updateTable();
        console.log('defaultLabels -->',defaultLabels)
    }, [annotations]);


    const updateTable = () => {
        if (selectedTab === 'field') {
            // Create sets of annotation IDs and row IDs
            const annotationIds = new Set(annotations.map(annotation => annotation.id));
            const rowIds = new Set(defaultLabels.flatMap(label => label.annotation_text.map(annotation => annotation.bbox_ID)));

            // Find missing IDs
            const missingIds = [...rowIds].filter(id => !annotationIds.has(id));

            if (missingIds.length > 0) {
                // Find the rows with the missing bbox_IDs and update them
                const updatedLabels = defaultLabels.map(label => {
                    const updatedAnnotationText = label.annotation_text.filter(annotation => !missingIds.includes(annotation.bbox_ID));

                    return {
                        ...label,
                        annotation_text: updatedAnnotationText
                    };
                });

                setDefaultLabels(updatedLabels);
            } else {
                // console.log('No missing IDs.');
                return [];
            }
        }
        else if (selectedTab === 'table') {
            // Create sets of annotation IDs and row IDs
            const annotationIds = new Set(annotations.map(annotation => annotation.id));
            const rowIds = new Set(defaultTableLabels.flatMap(label => label.annotation_text.map(annotation => annotation.bbox_ID)));

            // Find missing IDs
            const missingIds = [...rowIds].filter(id => !annotationIds.has(id));

            if (missingIds.length > 0) {
                // Find the rows with the missing bbox_IDs and update them
                const updatedLabels = defaultTableLabels.map(label => {
                    const updatedAnnotationText = label.annotation_text.filter(annotation => !missingIds.includes(annotation.bbox_ID));

                    return {
                        ...label,
                        annotation_text: updatedAnnotationText
                    };
                });

                setDefaultLabels(updatedLabels);
            } else {
                // console.log('No missing IDs.');
                return [];
            }
        }
    };


    // Function to push new annotation into the annotation_text array by id
    function addAnnotationDataById(list, id, annotation) {
        const item = list.find(item => item.id === id);
        if (item) {
            item.annotation_text.push(annotation);
        } else {
            console.log(`Item with id ${id} not found.`);
        }
    }
    const updateAnnotationText = (id, newText, newBoxID, TextBoxDetails) => {
        const newAnnotation = {
            bbox_ID: newBoxID,
            annoted_Text: newText,
            TextBoxDetails: TextBoxDetails
        };
        if (selectedTab == 'field') {
            addAnnotationDataById(defaultLabels, id, newAnnotation);
            console.warn(`DATA CHANGED:`, defaultLabels);
        } else if (selectedTab == 'table') {
            addAnnotationDataById(defaultTableLabels, id, newAnnotation);
        }
    };

    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);

    const handleMouseDown = (event) => {
        if (event.button === 2) { // Right mouse button
            event.preventDefault();
            setRightClick(true);
            setIsDragging(true);
            setDragStart({ x: event.clientX, y: event.clientY });
        }
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;

        const offsetX = event.clientX - dragStart.x;
        const offsetY = event.clientY - dragStart.y;

        setImagePosition((prevPosition) => ({
            x: prevPosition.x + offsetX,
            y: prevPosition.y + offsetY
        }));

        setDragStart({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setRightClick(false);
    };

    const handleZoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom-in level of 3x
    };

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom-out level of 0.5x
    };


    //Image dragging
    useEffect(() => {
        const handleContextMenu = (event) => {
            if (event.button === 2) { // Right mouse button
                event.preventDefault();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);

        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.key === '=') {
                    handleZoomIn();
                    event.preventDefault();
                } else if (event.key === '-') {
                    handleZoomOut();
                    event.preventDefault();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDragging]);

    //checking if the bbox-id present in list
    const isBBoxIdInTable = (bboxId, labels) => {
        for (const label of labels) {
            for (const annotation of label.annotation_text) {
                if (annotation.bbox_ID === bboxId) {
                    return true;
                }
            }
        }
        return false;
    };
    const addToTable = () => {
        if (!annotations || annotations.length === 0) {
            return;
        }

        const lastAnnotation = annotations[annotations.length - 1];
        if (!lastAnnotation.mark) {
            return;
        }

        // if box id already in list ignore
        const bboxId = lastAnnotation.id;
        if (isBBoxIdInTable(bboxId, defaultLabels)) {
            return;
        }

        const { height, width, x, y } = lastAnnotation.mark;
        const BBID = lastAnnotation.id;
        setBoxId(BBID);

        const corners = [
            [parseFloat(x.toFixed(3)), parseFloat(y.toFixed(3))], // Top-left corner
            [parseFloat((x + width).toFixed(3)), parseFloat(y.toFixed(3))], // Top-right corner
            [parseFloat((x + width).toFixed(3)), parseFloat((y + height).toFixed(3))], // Bottom-right corner
            [parseFloat(x.toFixed(3)), parseFloat((y + height).toFixed(3))] // Bottom-left corner
        ];

        const result = get_ocr_text();
        const data = result.text;
        const boxDetails = result.boxDetails;

        if (data === "") {
            return;
        }
        const label_ID = selectedLabel.id;

        updateAnnotationText(label_ID, data, BBID, boxDetails);
    };

    const handleBackClick = () => {
        navigate(-1); 
    };

    const getAnnotationStyle = () => {
        if (selectedLabel) {
            return {
                padding: 2,
                fontSize: 12,
                fontColor: "black",
                // fontBackground: selectedLabel.color,
                fontFamily: "Roboto",
                borderRadius: 999,
                lineWidth: 2,
                shapeBackground: "hsla(120, 100%, 50%, 0.3)",
                shapeStrokeStyle: annotationColor,
                shadowBlur: 10,
                shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
                transformerBackground: "#5c7cfa",
                transformerSize: 10
            };
        } else {
            return {
                padding: 2,
                fontSize: 12,
                fontColor: "red",
                fontBackground: "#f8f9fa",
                fontFamily: "Roboto",
                borderRadius: 999,
                lineWidth: 2,
                shapeBackground: "hsla(120, 100%, 50%, 0.3)",
                shapeStrokeStyle: "#f8f9fa",
                shadowBlur: 10,
                shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
                transformerBackground: "#5c7cfa",
                transformerSize: 10
            };
        }
    };

    // Control Buttons
    const zoomIn = () => {
        setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 10, 200)); // Max zoom level 200%
    };

    const zoomOut = () => {
        setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 10, 10)); // Min zoom level 10%
    };

    const moveLeft = () => {
        setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x - 10 }));
    };

    const moveRight = () => {
        setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x + 10 }));
    };

    const moveUp = () => {
        setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y - 10 }));
    };

    const moveDown = () => {
        setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y + 10 }));
    };
    
    const submitClick = async () => {
        try {
            const payload = {
                annotations: {
                    fieldLabels: defaultLabels,
                    tableLabels: defaultTableLabels
                }
            };
    
            const response = await axios.post(
                apiURL + `/projects/${window.globalVar1}/single_image_details/${image_id}`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.status === 200) {
                console.log('Annotation updated successfully');
                // Optionally, you can update the UI or state here to reflect the successful update
            } else {
                console.error('Failed to update:', response.statusText);
                // Optionally, you can show an error message to the user
            }
        } catch (error) {
            console.error('Error updating:', error.message);
            // Optionally, you can show an error message to the user
        }
    };
    return (
        <GridWrapper>
            <Box
                component="main"
                sx={{ height: '100%', display: 'flex', bgcolor: 'background.default', paddingBlock: 1, }}
            >

                <Box
                    component="imageAnnotation"
                    sx={{ height: '100%', width: '95%', bgcolor: 'background.default', marginInline: 1, bgcolor: '#f1f1f1', }}
                >
                    <div
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{ height: '90%', width: '95%', overflow: 'scroll', position: 'relative', backgroundColor: "#f1f1f1", borderRadius: "10px", border: "5px solid #d8d8d8", margin: 'auto' }}>
                        <div onMouseUp={addToTable} style={{ transform: `scale(${zoomLevel / 100}) translate(${position.x}px, ${position.y}px)`, transformOrigin: 'top left', minHeight: '90%', minWidth: '90%', display: 'inline-block' }}>
                            <div id='baseimage-DIV'
                                style={{
                                    position: 'absolute',
                                    top: imagePosition.y,
                                    left: imagePosition.x,
                                    transform: `scale(${scale})`,
                                    transformOrigin: 'top left',
                                    cursor: isDragging ? 'grabbing' : 'grab'
                                }}>
                                <ReactPictureAnnotation
                                    image={selectedImage}
                                    onSelect={onSelect}
                                    onChange={onChange}
                                    width={990}
                                    height={800}
                                    annotationData={annotations}
                                    annotationStyle={getAnnotationStyle()}
                                    annotations={annotations}
                                    selectedId={selectedAnnotation ? selectedAnnotation.id : null}
                                    onContextMenu={handleRightClick}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={zoomOut}>-</Button>
                        <span style={{ margin: '0 10px' }}>{zoomLevel}%</span>
                        <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={zoomIn}>+</Button>

                        <Button sx={{ marginRight: "5px", marginLeft: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveLeft}>&larr;</Button>
                        <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveUp}>&uarr;</Button>
                        <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveDown}>&darr;</Button>
                        <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveRight}>&rarr;</Button>
                    </div>
                </Box>
                <Box component='LabelView' sx={{ bgcolor: '#f1f1f1', width: '35%', height: '100%' }}>
                    <Typography sx={{ paddingInline: 2 }}>
                        <h2> File Name : Test_123.jpg</h2>
                    </Typography>
                    <Divider variant="middle" sx={{ border: '2px solid #674f87' }} />
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab style={{ fontWeight: 'bold' }} label="Fields" {...a11yProps(0)} />
                                <Tab style={{ fontWeight: 'bold' }} label="Tables" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel onClick={() => setSelectedTab('field')} value={value} index={0}>
                            <Typography sx={{ paddingInline: 4 }}>
                                <h4> LABELS </h4>
                            </Typography>
                            <Table sx={{ width: '92%', marginInline: 2, overflowX: 'hidden' }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Label Name</h4>
                                        </TableCell>
                                        <TableCell align="right">
                                            <h4>Annotations</h4>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {defaultLabels.map((row) => (
                                        row.annotation_text.length > 0 ? (
                                            row.annotation_text.map((annotation, index) => (
                                                <TableRow
                                                    key={`${row.id}-${index}`}
                                                    onClick={() => handleLabelClick(row, annotation.bbox_ID)}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: selectedLabel === row.id ? '#ef798a' : 'transparent',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: '#ef798a',
                                                        }
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <span style={{ borderLeft: '6px solid', borderColor: row.labelColor, padding: '4px' }}>
                                                            {row.labelName}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <div style={{ textAlign: 'left', backgroundColor: '#d8d8d8' }}>
                                                            <TextField
                                                                style={{ margin: '0px' }}
                                                                value={annotation.annoted_Text}
                                                                onChange={(event) => handleEditAnnotation(event, row.id, index)}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => handleLabelClick(row)}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: selectedLabel === row.id ? '#ef798a' : 'transparent',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: '#ef798a',
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <span style={{ borderLeft: '6px solid', borderColor: row.labelColor, padding: '4px' }}>
                                                        {row.labelName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div style={{ textAlign: 'left' }}>
                                                        <TextField
                                                            style={{ margin: '0px' }}
                                                            value=""
                                                            placeholder="No Annotation"
                                                            disabled
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button onClick={submitClick} sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)', color: '#ffffff' }}>
                                Submit
                            </Button>

                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel onClick={() => setSelectedTab('table')} value={value} index={1}>
                            <Typography sx={{ paddingInline: 4 }}>
                                <h4> TABLES</h4>
                            </Typography>
                            <Table sx={{ width: '92%', marginInline: 2, overflowX: 'hidden', maxHeight: '1000px' }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <h4>Label Name</h4>
                                        </TableCell>
                                        <TableCell align="right">
                                            <h4>Annotations</h4>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {defaultTableLabels.map((row) =>
                                        row.annotation_text.length > 0 ? (
                                            row.annotation_text.map((annotation, index) => (
                                                <TableRow
                                                    key={`${row.id}-${index}`}
                                                    onClick={() => handleLabelClick(row, annotation.bbox_ID)}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: selectedLabel === row.id ? '#ef798a' : 'transparent',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: '#ef798a',
                                                        }
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <span style={{ borderLeft: '6px solid', borderColor: row.labelColor, padding: '4px' }}>
                                                            {row.labelName}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <div style={{ textAlign: 'left', backgroundColor: '#d8d8d8' }}>
                                                            <TextField
                                                                style={{ margin: '0px' }}
                                                                value={annotation.annoted_Text}
                                                                onChange={(event) => handleEditAnnotation(event, row.id, index)}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => handleLabelClick(row)}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: selectedLabel === row.id ? '#ef798a' : 'transparent',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: '#ef798a',
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <span style={{ borderLeft: '6px solid', borderColor: row.labelColor, padding: '4px' }}>
                                                        {row.labelName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div style={{ textAlign: 'left' }}>
                                                        <TextField
                                                            style={{ margin: '0px' }}
                                                            value=""
                                                            placeholder="No Annotation"
                                                            disabled
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)', color: '#ffffff', mr: 2 }} onClick={addHeader}>
                                    Add Header
                                </Button>
                                <Button sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)', color: '#ffffff' }} onClick={addRow}>
                                    Add Row
                                </Button>
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </GridWrapper>
    );
}
