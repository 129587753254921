import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { makeStyles } from '@material-ui/core/styles';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Sample from "../../pages/images/007896060_00008.jpg";
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import './trainingSetup.css';
import { useNavigate } from "react-router-dom";
import { Table, TableBody,TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DropBox from './DropBox'
import axios from 'axios';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const labelsInitial = [
    {
        id:'1',
        labelName:'buyer_address',
        annotations: '0/10',
        labelColor :'#FA0606',
        annotation_text:[],
    }
];

const useStyles = makeStyles({
    button: {
      padding: '10px 12px',
      color: '#fff',
      background: 'linear-gradient(to right, #ef798a, #674f87)',
      borderRadius: '5px',
      border: '1px solid #f1f1f1',
      cursor: 'pointer',
      '&:hover': {
        background: 'linear-gradient(to right, #913A5D, #4E3160)',
      },
    },
  });

export default function LabTabs() {
  const [value, setValue] = React.useState('1');
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [labels, setLabels] = React.useState(labelsInitial);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [dbImageData,setDbImageData] = React.useState([]);

  

  const loadImages = async () => {
    try {
      const response = await axios.get(apiURL + `/projects/${window.globalVar1}/image_details`);
      const updatedRows = response.data.map(item => ({
        imageId: item.img_id,
        imageUrl: item.location,
        fileName: item.img_name,
        markedLabels: ['l1','l2'],  
        // markedLabels: item.labels,  // Assumes 'labels' are part of 'annotation'
        uploadDate: item.uploaded_date,
        thumbnail: `data:image/jpeg;base64,${item.thumbnail}`,  // Convert base64 to data URL
      }));
      console.log('updatedRows ==>',updatedRows);
      setRows(updatedRows);
    } catch (error) {
      console.error('Error fetching image details:', error);
    }
  }
  
  React.useEffect(() => {
    loadImages();
  }, []);

  React.useEffect(() => {
    if (value === '1') {
      loadImages();
    }
  }, [value]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddLabel = () =>{
    // Get the values from the form fields
    const labelName = document.getElementById('name').value;
    const annotationNo = document.getElementById('annotationNo').value;

    // Create a new label object
    const newLabel = {
        labelName: labelName,
        annotations: `0/${annotationNo}`, // Initialize annotations count
    };

    // Update the labels state with the new label
    setLabels([...labels, newLabel]);
  }
  const handleDoneClick = (image_id) => {
    navigate("image-annotation", { state: { labelsInitial, rows, image_id } });
};

  const gradientStyle = {
    paddingBottom:'10px',
    borderBottom: '3px solid #674f87'
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDelete = async (index, id) => {
    // Remove the row locally
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  
    // Log the ID
    console.log('ID', id);
  
    // Call the backend API to delete the image
    try {
      const response = await axios.post(apiURL + '/get/delete-image', { id });
  
      if (response.status === 200) {
        console.log('Image deleted successfully');
      } else {
        console.error('Failed to delete image', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting image', error);
    }
  };

  const filteredRows = rows.filter(rows => 
    rows.fileName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const containerStyle = {
    display: 'flex',
    height: '100vh',
  };

  const halfStyle = {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    scrollbarWidth: 'none', /* Firefox */
    msOverflowStyle: 'none',  /* Internet Explorer 10+ */
  };

  const hideScrollbarStyle = {
    '&::-webkit-scrollbar': {
      display: 'none', /* Safari and Chrome */
    }
  };

  return (
    <div style={containerStyle}>
        <div className='leftHalf' style={{...halfStyle, width: '73%', ...hideScrollbarStyle}}>
            <div>
                <h2 style={{borderBottom: '3px solid #f1f1f1', paddingBottom:'15px', color:'#5d5e5e'}}>Prepare model for training</h2>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab sx={{fontWeight:'bold'}} label={`All Files(${rows.length})`} value="1" />
                            <Tab sx={{fontWeight:'bold'}} label="Verified(0)" value="2"/>
                            <Tab sx={{fontWeight:'bold'}} label="Not Verified(3)" value="3" />
                            <Tab sx={{fontWeight:'bold'}} label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Upload Files &nbsp;
                                    <DriveFolderUploadIcon sx={{ marginRight: 1 }} />
                                </div>
                            } value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className='search' style={{marginBottom:'5px'}}>
                            <Paper
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 400, border:'1px solid #f1f1f1' }}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search for File names."
                                    inputProps={{ 'aria-label': 'search file names' }}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow style={{backgroundColor:'rgba(0, 0, 0, 0.08)', fontWeight:'bold'}}>
                                    <TableCell></TableCell>
                                    <TableCell sx={{fontWeight:'bold', color:'#5d5e5e'}}>Type</TableCell>
                                    <TableCell sx={{fontWeight:'bold', color:'#5d5e5e'}}>File Name</TableCell>
                                    <TableCell sx={{fontWeight:'bold', color:'#5d5e5e'}}>Marked Labels</TableCell>
                                    <TableCell sx={{fontWeight:'bold', color:'#5d5e5e'}}>Uploaded at</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {filteredRows.map((rows, index) => (
                                    <TableRow 
                                        key={index}
                                        sx={{
                                            '&:hover': {
                                              background: 'linear-gradient(90deg, #ede1e7, #dadde8)', // violet, blue
                                            }
                                        }}
                                    >
                                    <TableCell sx={{ width: '2%' }}>{index + 1}</TableCell>
                                    <TableCell sx={{ width: '10%' }}>
                                        <Avatar src={`data:image/png;base64,${rows.thumbnail}`}
                                        sx={{ width: 60, height: 60, borderRadius: 0 }} 
                                        />
                                    </TableCell>
                                    <TableCell 
                                        sx={{ 
                                            maxWidth: '100px', 
                                            overflow: 'hidden', 
                                            textOverflow: 'ellipsis', 
                                            whiteSpace: 'nowrap', 
                                            cursor: 'pointer' 
                                        }}
                                        title={rows.fileName} // This will show the full file name on hover
                                    >
                                        {rows.fileName}
                                    </TableCell>
                                    <TableCell sx={{ width: '15%' }}>{rows.markedLabels}</TableCell>
                                    <TableCell sx={{ width: '13%' }}>{rows.uploadDate}</TableCell>
                                    <TableCell sx={{ width: '6%' }}>
                                        <CreateIcon 
                                            onClick={() => handleDoneClick(rows.imageId)}
                                            sx={{
                                                padding:'4px',
                                                background: 'linear-gradient(to right, #ef798a, #674f87)',
                                                width:'27px', 
                                                height:'27px',
                                                color:'#fff', 
                                                borderRadius:'15px',
                                                marginTop:'2px',
                                                cursor:'pointer',
                                                '&:hover': {
                                                    padding:'6px',
                                                    width:'32px', 
                                                    height:'32px',
                                                }
                                            }}/>
                                    </TableCell>
                                    <TableCell sx={{ width: '6%' }}>
                                        <DoneIcon
                                            sx={{
                                                padding:'4px',
                                                background: 'linear-gradient(to right, #ef798a, #674f87)',
                                                width:'27px', 
                                                height:'27px',
                                                color:'#fff', 
                                                borderRadius:'15px',
                                                marginTop:'2px',
                                                cursor:'pointer',
                                                '&:hover': {
                                                    padding:'6px',
                                                    width:'32px', 
                                                    height:'32px',
                                                }
                                            }}/>
                                    </TableCell>
                                    <TableCell sx={{ width: '6%' }}>
                                        <DeleteTwoToneIcon
                                            sx={{
                                                padding:'4px',
                                                background: 'linear-gradient(to right, #ef798a, #674f87)',
                                                width:'27px', 
                                                height:'27px',
                                                color:'#fff', 
                                                borderRadius:'15px',
                                                marginTop:'2px',
                                                cursor:'pointer',
                                                '&:hover': {
                                                    padding:'6px',
                                                    width:'32px', 
                                                    height:'32px',
                                                }
                                            }}
                                            onClick={() => handleDelete(index,rows.imageId)}/>
                                    </TableCell>
                                    {/* <TableCell style={{display:'none'}}>{rows.imageId}</TableCell> */}
                                    <TableCell></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    <TabPanel value="2">Item Two</TabPanel>
                    <TabPanel value="3">Item Three</TabPanel>      
                    <TabPanel value="4">
                        <DropBox/>
                    </TabPanel>      
                </TabContext>
            </Box>
        </div>
        <div className='rightHalf' 
            style={{
                ...halfStyle, 
                width: '27%', 
                ...hideScrollbarStyle, 
                backgroundColor:'#f1f1f1', 
                padding:'5px 10px',
            }}>
            <div>
                <h2 style={{color:'#5d5e5e',margin:'0px',marginTop:'15px'}}>Model details</h2>
                <p style={gradientStyle}>Train models once all labels have at least 'n' examples</p>
                <div style={{display:'flex'}}>
                    <div style={{width:'65%'}}>
                        <h4 style={{color:'#5d5e5e'}}>LABELS({labels.length})</h4>
                    </div>
                    <div style={{alignContent:'center'}}>
                        <button className={classes.button} onClick={handleClickOpen}>+ ADD LABELS</button>
                    </div>
                    <React.Fragment>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                event.preventDefault();
                                handleClose();
                            },
                            }}
                        >
                            <DialogTitle sx={{fontWeight:'bold'}}>Add Label</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Label Name"
                                    type="text"
                                    variant="standard"
                                />
                            </DialogContent>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="annotationNo"
                                    label="Number of Annotations"
                                    type="number"
                                    variant="standard"
                                />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit" onClick={handleAddLabel}>ADD</Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                    
                </div>
                <TableContainer style={{backgroundColor:'transparent', border:'1px solid #f1f1f1'}}  component={Paper}>
                    <Table style={{backgroundColor:'transparent'}} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight:'bold', color:'#5d5e5e'}}>Label Name</TableCell>
                            <TableCell style={{fontWeight:'bold', color:'#5d5e5e'}}>Annotations</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {labels.map((label) => (
                                <TableRow key={label.labelName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{fontWeight:'bold', color:'#000'}}>{label.labelName}</TableCell>
                                    <TableCell style={{fontWeight:'bold',color:'#000'}}>{label.annotations}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    </div>
  );
}
