import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import BasicCard from '../common/BasicCard/BasicCardIconT2'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EntryIcon from '../images/Entry.svg';
import HandIcon from '../images/Handwritten.svg';
import TableIcon from '../images/Table.svg';
import TamilIcon from '../images/Tamil.svg';
import DirectoryIcon from '../images/Directory.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import axios from 'axios';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const CreateModel = () => {
  const [base_project, setBaseProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trainingType, setTrainingType] = useState("Base");
  const [createList, setCreateList] =  useState([]);
  const [projects, setProjects] =  useState([]);
  const iconMap = {
      DocumentScannerOutlinedIcon: DocumentScannerOutlinedIcon,
      ReceiptOutlinedIcon: ReceiptOutlinedIcon,
      ReceiptLongOutlinedIcon: ReceiptLongOutlinedIcon,
      DescriptionOutlinedIcon: DescriptionOutlinedIcon
  };
  useEffect(() => {
      const fetchProjects = async () => {
          try {
              const response = await axios.get(apiURL + `/projects/training_type/Base`);
              const projectsData = response.data.map(project => ({
                  ...project,
                  icon: iconMap[project.icon] // Map the icon string to the actual icon component
              }));
              setCreateList(projectsData);
          } catch (err) {
              setError(err.response ? err.response.data.detail : 'Error fetching projects');
          } finally {
              setLoading(false);
          }
      };
      const fetchCustomProjects = async () => {
          try {
              const response = await axios.get(apiURL + `/projects/training_type/Custom Trained`);
              const projectsData = response.data.map(project => ({
                  ...project,
                  icon: iconMap[project.icon] || DescriptionOutlinedIcon // Map the icon string to the actual icon component
              }));
              setProjects(projectsData);
              console.log('projects--,',projectsData)
          } catch (err) {
              setError(err.response ? err.response.data.detail : 'Error fetching projects');
          } finally {
              setLoading(false);
          }
      };

      fetchProjects();
      fetchCustomProjects();
  }, [trainingType]);


  const navigate = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleHeaderHover = () => {
    setIsHovered(true);
  };

  const handleHeaderLeave = () => {
    setIsHovered(false);
  };
  const handleDoneClick = (list) => {
    const serializableList = {
      id: list.project_id,
      content: list.content,
      header: list.header,
      icon: list.icon.displayName // If you need to pass the icon name
    };
    navigate("project-config", { state: { list: serializableList } });
  };
  const exploreMoreStyles = {
  
    moreContainer2: {
      padding:'10px 0px' 
      // Add any styles for the more container here
    },
    moreContainerH1: {
      width: 'fit-content',
      padding: '2px 0px',
      fontSize: '20px',
    },
    imageContainer: {
      display: 'inline-block',
      marginRight: '50px',
    },
    imageBox: {
      width: '200px',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(to right, #ef798a 1%, #674f87 40%)',
      borderRadius: '25px',
      cursor: 'pointer',
      transition: 'transform 0.3s ease', // Adding transition for smoother hover effect
      transform: 'scale(1)', // Initial scale
    },
    imageBoxHover: {
      transform: 'scale(1.1)', // Enlarge the image box on hover
    },
    imageBoxImg: {
      maxWidth: '100%',
      maxHeight: '100%',
      cursor: 'pointer',
    },
    description: {
      width: '200px',
      textAlign: 'center',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '20px',
      cursor: 'pointer',
      color: '#000',
      transition: 'color 0.3s ease',
    },
    descriptionHover: {
      color: '#7c7a7a',
    },
  };


  return (
    <div>
      <h1
        style={exploreMoreStyles.moreContainerH1}
      >
        Select or Create a New Model
      </h1>
      <Divider/>
      <div style={{display:'flex', gap: '20px', paddingBlock: '10px'}}>
        {createList.map((list) => {
          const IconComponent = list.icon;
          return (
            <Card key={list.id}  onClick={() => handleDoneClick(list)}
              sx={{ width: 180,
                    height: 180,
                    borderRadius: 5,
                    background: 'linear-gradient(to right, #ef798a, #674f87)',
                    '&:hover': { border: '2px solid #d8d8d8' } 
                  }}
            >
              <CardActionArea sx={{ height: '100%', width: '100%' }}>
                <CardMedia
                  component="div"
                  sx={{ width: '50%', height: '50%', marginInline: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <IconComponent sx={{ fontSize: '4rem', color: '#ffffff' }} />
                </CardMedia>
                <CardContent sx={{ height: '40%', width: '90%', bgcolor: '#f1f1f1', borderRadius: '10px', marginInline: 'auto', textAlign: 'center', }}>
                  <div  style={{ fontWeight: 'lighter'}}>
                    {list.content}
                  </div>
                  <div  style={{ fontWeight: 'bolder', fontSize: '15px' }}>
                    {list.header}
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </div>
      <Divider/>
      <div style={exploreMoreStyles.moreContainer2}>
          <Typography component="div" variant="h8" sx={{ fontWeight: 'bold' }} >
            Your projects
          </Typography>
            <Grid container spacing={4}   style={{ marginBottom: '16px' }} sx={{width:'92%'}}> 
              {projects.map((project) => (
                <Grid item xs={4} key={project.id}>
                  <BasicCard
                    header={project.header}
                    content={project.content}
                    icon={project.icon}
                    click_element={'project-selection'}
                    project_id={project.project_id}
                  />
                </Grid>
              ))}
            </Grid>
      </div>
    </div>
  ); 
};

export default CreateModel;
