import React, { useState, useRef, useEffect } from 'react';
import './chatWithFiles.css';
import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@mui/icons-material/Image';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import RecordIcon from '@material-ui/icons/FiberManualRecord';
import MicIcon from '@material-ui/icons/Mic';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import commentIcon from './uploads/message.svg'
import voiceIcon from './uploads/voiceIcon.svg'

const apiURL = process.env.REACT_APP_BACKEND_API_URL;


const ChatWithFiles = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundImage: `url(${commentIcon})`, // Use the imported image here
          filter: 'brightness(0) invert(1)', // Set image color to white
          backgroundSize: 'cover', // Fit the image within the thumb
          backgroundPosition: 'center',
          width: '60%', // Set width of the image
          height: '60%',
          margin: '6px',
          id: 'msgIcon',
      },
  },
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url(${voiceIcon})`, // Use the imported image here
              filter: 'brightness(0) invert(1)', // Set image color to white
              backgroundSize: 'cover', // Fit the image within the thumb
              backgroundPosition: 'center center',
              width: '60%', // Set width of the image
              height: '60%',
              margin: '6px',
              id: 'voiceIcon',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null); // State to store recorded audio blob
  const [q_response, setQ_response] = useState(''); // State to store response from server
  // const [uploadedFile, setUploadedFile] = useState(null); // State to store uploaded file
  const messagesEndRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  // const handleSaveClick = () => {
  //   setIsEditing(false);
  //   console.log('New title:', title);
  // };
  const ToggleMsgOrVoice = () => {
    setChecked(!checked);
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsTyping(e.target.value.trim() !== '');
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '') {
      const userMessage = { type: 'user', text: inputValue, sender: 'You' };
      if (isRecording) {
        userMessage.audio = recordedAudio;
        userMessage.className = 'audio'; // Add the 'audio' class to identify audio messages
        
      }
      else {
        console.log('User Message:', inputValue);
        const formData = new FormData();
        formData.append('question', inputValue);
        const response = await fetch(apiURL + '/llm_text_query', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
        else {
          const responseData = await response.json();
          const q_response = responseData['answer'];
          console.log('Response:', q_response);
          setMessages([
            ...messages,
            userMessage,
            { type: 'bot', text: q_response, sender: 'HR Bot' },
          ]);
        }
        console.log('User response:', response);
      }
      // setMessages([
      //   ...messages,
      //   userMessage,
      //   { type: 'bot', text: 'Lorem ipsum dolor sit amet, consectetuer message?', sender: 'HR Bot' },
      // ]);
      setInputValue('');
      setIsTyping(false);
    }
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    setUploadedFiles(prevFiles => [...prevFiles, file.name]);
    if (file) {
      // Upon successful upload, display a bot message
      const botMessage = {
        type: 'bot',
        text: `File "${file.name}" uploaded successfully.`,
        sender: 'HR Bot'
      };
      setMessages([...messages, botMessage]);
      const formData = new FormData();
      formData.append('file', file);
  
      fetch(apiURL + '/upload', {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        // Optionally, display an error message to the user
      });
    }
  };
  

  const handleRecordToggle = () => {
    if (!isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          mediaRecorder.ondataavailable = handleDataAvailable;
          mediaRecorder.start();
          setIsRecording(true);
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    } else {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDataAvailable = async (event) => {
    chunksRef.current.push(event.data);
    if (mediaRecorderRef.current.state === 'inactive') {
      const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
      setRecordedAudio(blob); // Save recorded audio blob to state
      const formData = new FormData();
      formData.append('audio', blob);
      const response = await fetch(apiURL + '/upload-audio', {
        method: 'POST',
        body: formData,
        responseType: 'blob'
      });
     
      try {
        
        const blob_res = await response.blob();
        console.log('Audio Response:', blob_res);
        setQ_response(blob_res);
        // const botMessage = { 
        //   type: 'bot', 
        //   audio: blob_res, // Use 'audio' property for audio Blob
        //   sender: 'HR Bot' 
        // };
        // setMessages([...messages, botMessage]); 
        
      }
      catch (error) {
        console.log('Audio Error:', error);
      }
      chunksRef.current = [];
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (recordedAudio) {
      const userAudioMessage = {
        type: 'user',
        audio: recordedAudio,
        sender: 'You'
      };
  
      setMessages([...messages, userAudioMessage]); // Add recorded audio message and bot message to messages state
      setRecordedAudio(null); // Reset recorded audio state
    }
  },  [recordedAudio, messages]);

  useEffect(() => {
      const botMessage = { 
        type: 'bot', 
        audio: q_response, 
        sender: 'HR Bot' 
      };
  
      setMessages([...messages,botMessage]); // Add recorded audio message and bot message to messages state
      setRecordedAudio(null); // Reset recorded audio state
    
  },  [q_response]);

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     handleSaveClick();
  //   }
  // };
  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
        <div className='chatFile-wrapper'>
        <div className='chatFile-content'>
            <div className='chatFile-left-half'>
                <div className='chatFile-insert'>
                    <label htmlFor="chatFile-insert">Insert:</label>
                    <ImageIcon className='chatFile-icon'/>
                    <label htmlFor="imageInput"><b> image</b></label>
                    <input type="file" id="imageInput" accept="image/*" style={{display: 'none'}} onChange={handleUploadFile} />
                    <VideoLibraryIcon className='chatFile-icon'/>
                    <label htmlFor="videoInput"><b>video</b></label>
                    <input type="file" id="videoInput" accept="video/*" style={{display: 'none'}} onChange={handleUploadFile} />
                    <AudioFileIcon className='chatFile-icon'/>
                    <label htmlFor="audioInput"><b>audio</b></label>
                    <input type="file" id="audioInput" accept="audio/*" style={{display: 'none'}} onChange={handleUploadFile} />
                    <InsertDriveFileIcon className='chatFile-icon'/>
                    <label htmlFor="fileInput"><b>file</b></label>
                    <input type="file" id="fileInput" style={{display: 'none'}} onChange={handleUploadFile} />
                </div>
                <div className="chatFile-chat-container">
                    <div className="chatFile-chat-header">
                        <div className="chatFile-bot-name">Chat with Your Documents</div>
                        <FormGroup>
                        <FormControlLabel
                            control={<MaterialUISwitch checked={checked} onChange={ToggleMsgOrVoice} sx={{ m: 1 }} />}
                            label=""
                        />
                        </FormGroup>
                    </div>
                    <div className="chatFile-messages">
                        {messages.map((message, index) => (
                        <div key={index} className={`chatFile-message-container ${message.type}`}>
                            <div className={`icon ${message.type}`} style={{ backgroundColor:'white',marginLeft:'10px', marginRight: '5px'}}>
                            {message.type === 'user' && (
                                <div className='chatFile-user-icon'><AccountCircleIcon style={{ fontSize: 30, color: 'grey'}}/></div>
                            )}
                            {message.type === 'bot' && (
                                <img src="./botIcons/header-logo.svg" alt="Bot Icon" />
                            )}
                            </div>
                            <div className="chatFile-message">
                            
                            {message.audio ? (
                                <div className="chatFile-audio-message">
                                <audio controls>
                                    <source src={URL.createObjectURL(message.audio)} type="audio/wav" />
                                    Your browser does not support the audio element.
                                </audio>
                                </div>                  
                            ) : (
                                <div>
                                <div className="chatFile-text" data-sender={message.sender}>
                                    {message.text}
                                </div>
                                </div>
                            )}
                            </div>
                        </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="chatFile-input-container">
                        <input
                        type="text"
                        placeholder="Enter your message"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleEnterKeyPress}
                        />
                        {isTyping ? (
                        <button className='chatFile-btnsend' onClick={handleSendMessage}>
                            <span><SendIcon /></span>
                        </button>
                        ) : (
                        <>
                            <button  className='chatFile-btnrcrd' onClick={handleRecordToggle}>
                                <span style={{ color: isRecording ? 'red' : 'inherit',padding:'0px' }}>
                                    {isRecording ?  <RecordIcon /> : <MicIcon />}
                                </span>
                            </button>
                        </>
                        )}
                    </div>
                </div>
            </div>
            <div className='chatFile-right-half'>
            <ul>
                <li>List of Uploaded documents</li>
                {uploadedFiles.map((file, index) => (
                    <li key={index}>
                        <input type="checkbox" id={`fileCheckbox-${index}`} defaultChecked={false} />
                        <label htmlFor={`fileCheckbox-${index}`}>
                            <span className="chatFile-filename">{file}</span>
                        </label>
                    </li>
                ))}
            </ul>
            </div>
        </div>
    </div>
  );
};

export default ChatWithFiles;
