import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const StatCard = ({ title, value, icon: IconComponent, isIncrease })  => {
  return (
    // display: 'flex', alignItems: 'center', p: 1, borderRadius: 2, boxShadow: 1
    <Card sx={{  background:'#f8f5ff',paddingLeft:'1%',display: 'flex', alignItems: 'center', flex: 1, m: 3, borderRadius: 2, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px solid #ef798a', borderRadius: '50%', width: '15%', height: '70%', mr: 2 }}>
      {IconComponent && <IconComponent sx={{  width: '80%', height: '80%',color: '#ef798a' }} />}
      </Box>
      <CardContent>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
