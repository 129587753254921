import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { DataGrid } from '@mui/x-data-grid';
import { ReactPictureAnnotation } from "react-picture-annotation";
import { useLocation } from "react-router-dom";
import Sample from "../../pages/images/007896060_00008.jpg";
import { Chip, Button } from "@mui/material";
import { type } from '@testing-library/user-event/dist/type';


import Dialog from '@mui/material/Dialog';
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TableChartIcon from '@mui/icons-material/TableChartOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import TableViewIcon from '@mui/icons-material/TableViewOutlined';





//icons
import DeleteIcon from '@mui/icons-material/Delete';
import { selectedIdsLookupSelector } from '@mui/x-data-grid';


const ocrDatas = [{'id': 845,
  'start_y': 698,
  'start_x': 437,
  'text': '--',
  'bbox': [437, 698, 446, 698, 446, 735, 437, 735]},
 {'id': 0,
  'start_y': 258,
  'start_x': 473,
  'text': '(',
  'bbox': [473, 258, 481, 258, 481, 274, 473, 274]},
 {'id': 18,
  'start_y': 363,
  'start_x': 474,
  'text': 'NUMBER',
  'bbox': [474, 363, 541, 363, 541, 376, 474, 376]},
 {'id': 1,
  'start_y': 257,
  'start_x': 479,
  'text': 'Municipal',
  'bbox': [479, 257, 547, 257, 547, 273, 479, 273]},
 {'id': 168,
  'start_y': 494,
  'start_x': 488,
  'text': '2',
  'bbox': [488, 494, 509, 494, 509, 515, 488, 515]},
 {'id': 770,
  'start_y': 1285,
  'start_x': 488,
  'text': '24',
  'bbox': [488, 1285, 516, 1285, 516, 1305, 488, 1305]},
 {'id': 777,
  'start_y': 1327,
  'start_x': 489,
  'text': '25',
  'bbox': [489, 1327, 512, 1327, 512, 1342, 489, 1342]},
 {'id': 388,
  'start_y': 706,
  'start_x': 490,
  'text': '82-13-65',
  'bbox': [490, 708, 628, 706, 628, 726, 490, 728]},
 {'id': 571,
  'start_y': 1072,
  'start_x': 490,
  'text': '18',
  'bbox': [489, 1073, 516, 1072, 517, 1093, 490, 1094]},
 {'id': 635,
  'start_y': 1218,
  'start_x': 490,
  'text': '22',
  'bbox': [490, 1218, 525, 1218, 525, 1237, 490, 1237]},
 {'id': 366,
  'start_y': 674,
  'start_x': 491,
  'text': 'J',
  'bbox': [491, 674, 505, 674, 505, 690, 491, 690]},
 {'id': 400,
  'start_y': 743,
  'start_x': 491,
  'text': '9',
  'bbox': [491, 743, 512, 743, 512, 761, 491, 761]},
 {'id': 406,
  'start_y': 854,
  'start_x': 491,
  'text': '12',
  'bbox': [491, 854, 510, 854, 510, 871, 491, 871]},
 {'id': 506,
  'start_y': 889,
  'start_x': 491,
  'text': '13',
  'bbox': [491, 889, 515, 889, 515, 907, 491, 907]},
 {'id': 534,
  'start_y': 924,
  'start_x': 491,
  'text': '143-21-15',
  'bbox': [490, 927, 626, 924, 626, 946, 491, 949]},
 {'id': 604,
  'start_y': 1107,
  'start_x': 491,
  'text': '19',
  'bbox': [491, 1106, 513, 1107, 513, 1125, 491, 1124]},
 {'id': 172,
  'start_y': 566,
  'start_x': 492,
  'text': '4',
  'bbox': [492, 566, 518, 566, 518, 583, 492, 583]},
 {'id': 176,
  'start_y': 601,
  'start_x': 492,
  'text': '5',
  'bbox': [492, 601, 511, 601, 511, 617, 492, 617]},
 {'id': 179,
  'start_y': 639,
  'start_x': 492,
  'text': '6',
  'bbox': [492, 639, 511, 639, 511, 655, 492, 655]},
 {'id': 762,
  'start_y': 1256,
  'start_x': 492,
  'text': '23',
  'bbox': [492, 1256, 518, 1256, 518, 1269, 492, 1269]},
 {'id': 402,
  'start_y': 780,
  'start_x': 493,
  'text': '10',
  'bbox': [493, 780, 514, 780, 514, 799, 493, 799]},
 {'id': 557,
  'start_y': 961,
  'start_x': 493,
  'text': '15',
  'bbox': [493, 961, 516, 961, 516, 981, 493, 981]},
 {'id': 609,
  'start_y': 1143,
  'start_x': 493,
  'text': '20',
  'bbox': [493, 1143, 518, 1143, 518, 1161, 493, 1161]},
 {'id': 625,
  'start_y': 1179,
  'start_x': 493,
  'text': '21',
  'bbox': [493, 1179, 515, 1179, 515, 1196, 493, 1196]},
 {'id': 17,
  'start_y': 344,
  'start_x': 494,
  'text': 'LCR',
  'bbox': [494, 344, 524, 344, 524, 356, 494, 356]},
 {'id': 562,
  'start_y': 995,
  'start_x': 494,
  'text': '16',
  'bbox': [494, 995, 515, 995, 515, 1014, 494, 1014]},
 {'id': 119,
  'start_y': 417,
  'start_x': 495,
  'text': '(',
  'bbox': [495, 417, 502, 417, 502, 432, 495, 432]},
 {'id': 566,
  'start_y': 1035,
  'start_x': 496,
  'text': '17',
  'bbox': [496, 1034, 519, 1035, 519, 1051, 496, 1050]},
 {'id': 120,
  'start_y': 417,
  'start_x': 502,
  'text': '1',
  'bbox': [502, 417, 509, 417, 509, 432, 502, 432]},
 {'id': 563,
  'start_y': 995,
  'start_x': 503,
  'text': "'",
  'bbox': [503, 995, 517, 995, 517, 1014, 503, 1014]},
 {'id': 121,
  'start_y': 417,
  'start_x': 507,
  'text': ')',
  'bbox': [507, 417, 513, 417, 513, 432, 507, 432]},
 {'id': 169,
  'start_y': 496,
  'start_x': 509,
  'text': '23',
  'bbox': [493, 551, 493, 496, 509, 496, 509, 551]},
 {'id': 403,
  'start_y': 780,
  'start_x': 509,
  'text': '°',
  'bbox': [509, 780, 521, 780, 521, 799, 509, 799]},
 {'id': 407,
  'start_y': 854,
  'start_x': 511,
  'text': '"',
  'bbox': [511, 854, 517, 854, 517, 871, 511, 871]},
 {'id': 846,
  'start_y': 1375,
  'start_x': 514,
  'text': '1',
  'bbox': [514, 1375, 525, 1375, 525, 1393, 514, 1393]},
 {'id': 173,
  'start_y': 566,
  'start_x': 516,
  'text': '.',
  'bbox': [516, 566, 528, 566, 528, 583, 516, 583]},
 {'id': 167,
  'start_y': 488,
  'start_x': 523,
  'text': '$',
  'bbox': [523, 488, 530, 488, 530, 497, 523, 497]},
 {'id': 847,
  'start_y': 1375,
  'start_x': 530,
  'text': 'Type',
  'bbox': [530, 1375, 568, 1375, 568, 1393, 530, 1393]},
 {'id': 605,
  'start_y': 1106,
  'start_x': 548,
  'text': '8-9-65',
  'bbox': [549, 1103, 616, 1106, 615, 1123, 548, 1120]},
 {'id': 405,
  'start_y': 817,
  'start_x': 550,
  'text': '3-12-65',
  'bbox': [549, 819, 617, 817, 618, 836, 550, 838]},
 {'id': 564,
  'start_y': 997,
  'start_x': 550,
  'text': '5-11-65',
  'bbox': [550, 996, 621, 997, 621, 1018, 550, 1017]},
 {'id': 610,
  'start_y': 1141,
  'start_x': 550,
  'text': '8-2-65',
  'bbox': [550, 1141, 624, 1141, 624, 1163, 550, 1163]},
 {'id': 626,
  'start_y': 1173,
  'start_x': 550,
  'text': '8-1465',
  'bbox': [550, 1173, 623, 1173, 623, 1198, 550, 1198]},
 {'id': 636,
  'start_y': 1218,
  'start_x': 550,
  'text': '775',
  'bbox': [550, 1218, 620, 1218, 620, 1237, 550, 1237]},
 {'id': 771,
  'start_y': 1286,
  'start_x': 550,
  'text': '9-10-65',
  'bbox': [550, 1287, 619, 1286, 619, 1305, 550, 1306]},
 {'id': 408,
  'start_y': 852,
  'start_x': 551,
  'text': '3-15-65',
  'bbox': [551, 853, 622, 852, 622, 870, 551, 871]},
 {'id': 507,
  'start_y': 890,
  'start_x': 551,
  'text': '3-18-65',
  'bbox': [551, 890, 621, 890, 621, 906, 551, 906]},
 {'id': 558,
  'start_y': 961,
  'start_x': 551,
  'text': '3-2445',
  'bbox': [551, 959, 624, 961, 624, 981, 551, 979]},
 {'id': 567,
  'start_y': 1032,
  'start_x': 551,
  'text': '7-2-5',
  'bbox': [550, 1034, 617, 1032, 618, 1050, 551, 1052]},
 {'id': 778,
  'start_y': 1319,
  'start_x': 551,
  'text': '11-4-65',
  'bbox': [551, 1319, 623, 1319, 623, 1351, 551, 1351]},
 {'id': 2,
  'start_y': 257,
  'start_x': 552,
  'text': 'Form',
  'bbox': [552, 257, 588, 257, 588, 273, 552, 273]},
 {'id': 171,
  'start_y': 526,
  'start_x': 552,
  'text': '2-1-65',
  'bbox': [552, 525, 629, 526, 629, 545, 552, 544]},
 {'id': 174,
  'start_y': 563,
  'start_x': 552,
  'text': '2465',
  'bbox': [552, 563, 628, 563, 628, 583, 552, 583]},
 {'id': 401,
  'start_y': 746,
  'start_x': 552,
  'text': '2-15-65',
  'bbox': [552, 744, 623, 746, 623, 764, 552, 762]},
 {'id': 404,
  'start_y': 781,
  'start_x': 552,
  'text': '2-28-15',
  'bbox': [552, 780, 625, 781, 625, 799, 552, 798]},
 {'id': 165,
  'start_y': 460,
  'start_x': 553,
  'text': '1-7-45',
  'bbox': [553, 459, 619, 460, 619, 478, 553, 477]},
 {'id': 170,
  'start_y': 493,
  'start_x': 553,
  'text': '1-22-45',
  'bbox': [553, 492, 627, 493, 627, 512, 553, 511]},
 {'id': 367,
  'start_y': 673,
  'start_x': 553,
  'text': '2513-65',
  'bbox': [553, 672, 625, 673, 625, 690, 553, 689]},
 {'id': 763,
  'start_y': 1253,
  'start_x': 553,
  'text': '9-8-65',
  'bbox': [553, 1254, 617, 1253, 617, 1273, 553, 1274]},
 {'id': 180,
  'start_y': 637,
  'start_x': 555,
  'text': '2-5-65',
  'bbox': [555, 637, 624, 637, 624, 654, 555, 654]},
 {'id': 177,
  'start_y': 602,
  'start_x': 557,
  'text': '2-5-65',
  'bbox': [557, 602, 629, 602, 629, 619, 557, 619]},
 {'id': 19,
  'start_y': 339,
  'start_x': 559,
  'text': 'REGISTRA',
  'bbox': [559, 339, 621, 339, 621, 352, 559, 352]},
 {'id': 21,
  'start_y': 371,
  'start_x': 563,
  'text': '(',
  'bbox': [563, 371, 569, 371, 569, 384, 563, 384]},
 {'id': 15,
  'start_y': 326,
  'start_x': 564,
  'text': 'DATE',
  'bbox': [564, 326, 596, 326, 596, 336, 564, 336]},
 {'id': 22,
  'start_y': 369,
  'start_x': 565,
  'text': 'Day',
  'bbox': [565, 370, 589, 369, 589, 382, 565, 383]},
 {'id': 572,
  'start_y': 1068,
  'start_x': 567,
  'text': '265',
  'bbox': [566, 1070, 611, 1068, 612, 1089, 567, 1091]},
 {'id': 14,
  'start_y': 288,
  'start_x': 571,
  'text': '1965',
  'bbox': [571, 288, 618, 288, 618, 306, 571, 306]},
 {'id': 20,
  'start_y': 355,
  'start_x': 575,
  'text': 'TION',
  'bbox': [575, 355, 605, 355, 605, 366, 575, 366]},
 {'id': 848,
  'start_y': 1375,
  'start_x': 576,
  'text': '(',
  'bbox': [576, 1375, 582, 1375, 582, 1393, 576, 1393]},
 {'id': 849,
  'start_y': 1375,
  'start_x': 581,
  'text': 'Col.',
  'bbox': [581, 1375, 609, 1375, 609, 1393, 581, 1393]},
 {'id': 122,
  'start_y': 417,
  'start_x': 583,
  'text': '(',
  'bbox': [582, 418, 590, 417, 591, 433, 583, 434]},
 {'id': 123,
  'start_y': 416,
  'start_x': 586,
  'text': '2',
  'bbox': [585, 417, 597, 416, 598, 432, 586, 433]},
 {'id': 23,
  'start_y': 370,
  'start_x': 587,
  'text': '/',
  'bbox': [587, 370, 594, 370, 594, 383, 587, 383]},
 {'id': 3,
  'start_y': 256,
  'start_x': 591,
  'text': 'No.',
  'bbox': [591, 256, 616, 256, 616, 273, 591, 273]},
 {'id': 24,
  'start_y': 368,
  'start_x': 592,
  'text': 'Mo',
  'bbox': [592, 369, 611, 368, 611, 382, 592, 383]},
 {'id': 124,
  'start_y': 416,
  'start_x': 594,
  'text': ')',
  'bbox': [593, 417, 602, 416, 603, 432, 594, 433]},
 {'id': 16,
  'start_y': 326,
  'start_x': 599,
  'text': 'OF',
  'bbox': [599, 326, 619, 326, 619, 336, 599, 336]},
 {'id': 125,
  'start_y': 415,
  'start_x': 604,
  'text': '(',
  'bbox': [603, 416, 612, 415, 613, 431, 604, 432]},
 {'id': 126,
  'start_y': 414,
  'start_x': 609,
  'text': 'PS',
  'bbox': [608, 416, 630, 414, 631, 430, 609, 432]},
 {'id': 850,
  'start_y': 1375,
  'start_x': 614,
  'text': '10',
  'bbox': [614, 1375, 630, 1375, 630, 1393, 614, 1393]},
 {'id': 4,
  'start_y': 256,
  'start_x': 618,
  'text': '26-1',
  'bbox': [618, 256, 648, 256, 648, 272, 618, 272]},
 {'id': 127,
  'start_y': 415,
  'start_x': 624,
  'text': ')',
  'bbox': [623, 415, 630, 415, 631, 430, 624, 431]},
 {'id': 851,
  'start_y': 1375,
  'start_x': 629,
  'text': ')',
  'bbox': [629, 1375, 637, 1375, 637, 1393, 629, 1393]},
 {'id': 33,
  'start_y': 352,
  'start_x': 640,
  'text': 'aani',
  'bbox': [640, 352, 676, 352, 676, 364, 640, 364]},
 {'id': 852,
  'start_y': 1375,
  'start_x': 643,
  'text': 'Enter',
  'bbox': [643, 1375, 682, 1375, 682, 1393, 643, 1393]},
 {'id': 5,
  'start_y': 256,
  'start_x': 645,
  'text': ',',
  'bbox': [645, 256, 651, 256, 651, 272, 645, 272]},
 {'id': 779,
  'start_y': 1319,
  'start_x': 651,
  'text': 'Maria',
  'bbox': [651, 1319, 709, 1319, 709, 1351, 651, 1351]},
 {'id': 36,
  'start_y': 368,
  'start_x': 652,
  'text': '(',
  'bbox': [652, 368, 659, 368, 659, 381, 652, 381]},
 {'id': 573,
  'start_y': 1068,
  'start_x': 652,
  'text': 'Julian',
  'bbox': [653, 1065, 711, 1068, 710, 1087, 652, 1084]},
 {'id': 37,
  'start_y': 366,
  'start_x': 653,
  'text': 'First',
  'bbox': [653, 367, 680, 366, 680, 380, 653, 381]},
 {'id': 6,
  'start_y': 256,
  'start_x': 655,
  'text': 'Revised',
  'bbox': [655, 256, 708, 256, 708, 272, 655, 272]},
 {'id': 606,
  'start_y': 1108,
  'start_x': 655,
  'text': 'Jonathan',
  'bbox': [656, 1104, 744, 1108, 743, 1126, 655, 1122]},
 {'id': 611,
  'start_y': 1141,
  'start_x': 655,
  'text': 'Maria',
  'bbox': [655, 1141, 719, 1141, 719, 1163, 655, 1163]},
 {'id': 764,
  'start_y': 1251,
  'start_x': 655,
  'text': 'Ana',
  'bbox': [655, 1252, 697, 1251, 697, 1271, 655, 1272]},
 {'id': 772,
  'start_y': 1286,
  'start_x': 656,
  'text': 'Franesses',
  'bbox': [656, 1286, 744, 1286, 744, 1308, 656, 1308]},
 {'id': 892,
  'start_y': 1437,
  'start_x': 656,
  'text': 'RARTEIO',
  'bbox': [656, 1438, 706, 1437, 706, 1450, 656, 1451]},
 {'id': 627,
  'start_y': 1173,
  'start_x': 657,
  'text': 'Francises',
  'bbox': [657, 1173, 751, 1173, 751, 1198, 657, 1198]},
 {'id': 637,
  'start_y': 1214,
  'start_x': 658,
  'text': 'Lino',
  'bbox': [658, 1214, 699, 1214, 699, 1235, 658, 1235]},
 {'id': 26,
  'start_y': 333,
  'start_x': 660,
  'text': 'by',
  'bbox': [660, 334, 683, 333, 683, 348, 660, 349]},
 {'id': 559,
  'start_y': 961,
  'start_x': 665,
  'text': 'Danke',
  'bbox': [665, 961, 722, 961, 722, 981, 665, 981]},
 {'id': 568,
  'start_y': 1032,
  'start_x': 665,
  'text': 'Marlon',
  'bbox': [666, 1029, 737, 1032, 736, 1053, 665, 1050]},
 {'id': 414,
  'start_y': 853,
  'start_x': 666,
  'text': 'Albino',
  'bbox': [666, 852, 729, 853, 729, 875, 666, 874]},
 {'id': 535,
  'start_y': 923,
  'start_x': 666,
  'text': 'Elisa',
  'bbox': [666, 923, 714, 923, 714, 942, 666, 942]},
 {'id': 565,
  'start_y': 996,
  'start_x': 666,
  'text': 'Polamin',
  'bbox': [666, 995, 751, 996, 751, 1020, 666, 1019]},
 {'id': 175,
  'start_y': 563,
  'start_x': 667,
  'text': 'Arlene',
  'bbox': [667, 563, 734, 563, 734, 583, 667, 583]},
 {'id': 508,
  'start_y': 887,
  'start_x': 667,
  'text': 'Alex',
  'bbox': [667, 887, 712, 887, 712, 909, 667, 909]},
 {'id': 178,
  'start_y': 596,
  'start_x': 668,
  'text': 'Joel',
  'bbox': [667, 599, 716, 596, 717, 618, 668, 621]},
 {'id': 368,
  'start_y': 671,
  'start_x': 668,
  'text': 'Rolando',
  'bbox': [668, 671, 744, 671, 744, 691, 668, 691]},
 {'id': 191,
  'start_y': 526,
  'start_x': 669,
  'text': 'Emmanuel',
  'bbox': [669, 526, 761, 526, 761, 548, 669, 548]},
 {'id': 410,
  'start_y': 780,
  'start_x': 669,
  'text': 'Andres',
  'bbox': [670, 778, 741, 780, 740, 801, 669, 799]},
 {'id': 411,
  'start_y': 814,
  'start_x': 669,
  'text': 'Maribel',
  'bbox': [669, 814, 756, 814, 756, 839, 669, 839]},
 {'id': 188,
  'start_y': 487,
  'start_x': 670,
  'text': 'Emmanuel',
  'bbox': [670, 486, 771, 487, 771, 517, 670, 516]},
 {'id': 166,
  'start_y': 450,
  'start_x': 671,
  'text': 'Eva',
  'bbox': [671, 450, 712, 450, 712, 472, 671, 472]},
 {'id': 181,
  'start_y': 637,
  'start_x': 671,
  'text': 'Rolina',
  'bbox': [671, 636, 752, 637, 752, 657, 671, 656]},
 {'id': 389,
  'start_y': 710,
  'start_x': 671,
  'text': 'Gregoria',
  'bbox': [672, 708, 751, 710, 750, 733, 671, 731]},
 {'id': 409,
  'start_y': 745,
  'start_x': 673,
  'text': 'Maria',
  'bbox': [673, 745, 736, 745, 736, 766, 673, 766]},
 {'id': 853,
  'start_y': 1375,
  'start_x': 684,
  'text': 'whether',
  'bbox': [684, 1375, 743, 1375, 743, 1393, 684, 1393]},
 {'id': 38,
  'start_y': 365,
  'start_x': 685,
  'text': 'gea',
  'bbox': [685, 366, 720, 365, 720, 379, 685, 380]},
 {'id': 34,
  'start_y': 352,
  'start_x': 686,
  'text': 'to',
  'bbox': [686, 352, 696, 352, 696, 363, 686, 363]},
 {'id': 35,
  'start_y': 352,
  'start_x': 701,
  'text': 'pogat',
  'bbox': [701, 352, 739, 352, 739, 363, 701, 363]},
 {'id': 638,
  'start_y': 1214,
  'start_x': 709,
  'text': 'Guilbert',
  'bbox': [709, 1214, 787, 1214, 787, 1235, 709, 1235]},
 {'id': 7,
  'start_y': 256,
  'start_x': 712,
  'text': '1984',
  'bbox': [712, 256, 742, 256, 742, 272, 712, 272]},
 {'id': 780,
  'start_y': 1319,
  'start_x': 715,
  'text': 'Rosa',
  'bbox': [715, 1319, 758, 1319, 758, 1351, 715, 1351]},
 {'id': 574,
  'start_y': 1070,
  'start_x': 717,
  'text': 'Edwin',
  'bbox': [718, 1068, 769, 1070, 768, 1089, 717, 1087]},
 {'id': 612,
  'start_y': 1141,
  'start_x': 725,
  'text': 'Belen',
  'bbox': [725, 1141, 776, 1141, 776, 1163, 725, 1163]},
 {'id': 536,
  'start_y': 929,
  'start_x': 735,
  'text': '"',
  'bbox': [735, 929, 743, 929, 743, 934, 735, 934]},
 {'id': 27,
  'start_y': 331,
  'start_x': 737,
  'text': 'NAME',
  'bbox': [737, 332, 781, 331, 781, 346, 737, 347]},
 {'id': 893,
  'start_y': 1436,
  'start_x': 738,
  'text': 'VIDJADOS',
  'bbox': [738, 1437, 801, 1436, 801, 1448, 738, 1449]},
 {'id': 8,
  'start_y': 256,
  'start_x': 741,
  'text': ')',
  'bbox': [741, 256, 748, 256, 748, 272, 741, 272]},
 {'id': 854,
  'start_y': 1375,
  'start_x': 749,
  'text': 'single',
  'bbox': [749, 1375, 791, 1375, 791, 1393, 749, 1393]},
 {'id': 186,
  'start_y': 454,
  'start_x': 768,
  'text': 'T.',
  'bbox': [768, 454, 803, 454, 803, 476, 768, 476]},
 {'id': 773,
  'start_y': 1286,
  'start_x': 769,
  'text': 'G.',
  'bbox': [769, 1286, 800, 1286, 800, 1308, 769, 1308]},
 {'id': 560,
  'start_y': 959,
  'start_x': 775,
  'text': 'R.',
  'bbox': [775, 959, 805, 959, 805, 981, 775, 981]},
 {'id': 781,
  'start_y': 1319,
  'start_x': 777,
  'text': 'C.',
  'bbox': [777, 1319, 818, 1319, 818, 1351, 777, 1351]},
 {'id': 478,
  'start_y': 853,
  'start_x': 778,
  'text': 'S.',
  'bbox': [778, 853, 808, 853, 808, 872, 778, 872]},
 {'id': 25,
  'start_y': 364,
  'start_x': 779,
  'text': 'Middle',
  'bbox': [780, 362, 820, 364, 819, 376, 779, 374]},
 {'id': 189,
  'start_y': 489,
  'start_x': 782,
  'text': 'R.',
  'bbox': [782, 488, 822, 489, 822, 518, 782, 517]},
 {'id': 194,
  'start_y': 563,
  'start_x': 782,
  'text': 'J.',
  'bbox': [782, 563, 805, 563, 805, 580, 782, 580]},
 {'id': 182,
  'start_y': 599,
  'start_x': 783,
  'text': 'J.',
  'bbox': [783, 599, 805, 599, 805, 618, 783, 618]},
 {'id': 628,
  'start_y': 1173,
  'start_x': 783,
  'text': 'A.',
  'bbox': [783, 1173, 810, 1173, 810, 1198, 783, 1198]},
 {'id': 28,
  'start_y': 330,
  'start_x': 784,
  'text': 'OF',
  'bbox': [784, 331, 807, 330, 807, 345, 784, 346]},
 {'id': 192,
  'start_y': 526,
  'start_x': 784,
  'text': 'M.',
  'bbox': [784, 526, 820, 526, 820, 547, 784, 547]},
 {'id': 607,
  'start_y': 1101,
  'start_x': 785,
  'text': 'C.',
  'bbox': [785, 1102, 813, 1101, 813, 1123, 785, 1124]},
 {'id': 369,
  'start_y': 671,
  'start_x': 786,
  'text': 'M.',
  'bbox': [788, 668, 827, 671, 825, 698, 786, 695]},
 {'id': 423,
  'start_y': 777,
  'start_x': 786,
  'text': 'E.',
  'bbox': [786, 777, 814, 777, 814, 797, 786, 797]},
 {'id': 415,
  'start_y': 745,
  'start_x': 787,
  'text': 'f',
  'bbox': [787, 745, 802, 745, 802, 764, 787, 764]},
 {'id': 183,
  'start_y': 633,
  'start_x': 788,
  'text': 'B.',
  'bbox': [789, 631, 830, 633, 828, 660, 788, 658]},
 {'id': 390,
  'start_y': 704,
  'start_x': 790,
  'text': 'C.',
  'bbox': [791, 703, 819, 704, 818, 730, 790, 729]},
 {'id': 128,
  'start_y': 416,
  'start_x': 793,
  'text': '(',
  'bbox': [793, 416, 799, 416, 799, 431, 793, 431]},
 {'id': 412,
  'start_y': 814,
  'start_x': 793,
  'text': '.',
  'bbox': [793, 814, 810, 814, 810, 839, 793, 839]},
 {'id': 613,
  'start_y': 1141,
  'start_x': 793,
  'text': 'P.',
  'bbox': [793, 1141, 817, 1141, 817, 1163, 793, 1163]},
 {'id': 765,
  'start_y': 1249,
  'start_x': 794,
  'text': 'Sevillano',
  'bbox': [794, 1249, 883, 1249, 883, 1270, 794, 1270]},
 {'id': 855,
  'start_y': 1375,
  'start_x': 794,
  'text': 'birth',
  'bbox': [794, 1375, 825, 1375, 825, 1393, 794, 1393]},
 {'id': 129,
  'start_y': 416,
  'start_x': 797,
  'text': '3',
  'bbox': [797, 416, 807, 416, 807, 431, 797, 431]},
 {'id': 416,
  'start_y': 745,
  'start_x': 797,
  'text': '.',
  'bbox': [797, 745, 812, 745, 812, 764, 797, 764]},
 {'id': 575,
  'start_y': 1068,
  'start_x': 797,
  'text': 'P.',
  'bbox': [797, 1068, 819, 1068, 819, 1088, 797, 1088]},
 {'id': 130,
  'start_y': 416,
  'start_x': 805,
  'text': ')',
  'bbox': [805, 416, 811, 416, 811, 431, 805, 431]},
 {'id': 29,
  'start_y': 330,
  'start_x': 809,
  'text': 'CHILD',
  'bbox': [809, 331, 857, 330, 857, 345, 809, 346]},
 {'id': 537,
  'start_y': 929,
  'start_x': 810,
  'text': 'Cainong',
  'bbox': [810, 928, 897, 929, 897, 953, 810, 952]},
 {'id': 774,
  'start_y': 1286,
  'start_x': 811,
  'text': 'Olais',
  'bbox': [811, 1286, 862, 1286, 862, 1308, 811, 1308]},
 {'id': 509,
  'start_y': 886,
  'start_x': 812,
  'text': 'Sampelo',
  'bbox': [812, 886, 891, 886, 891, 912, 812, 912]},
 {'id': 639,
  'start_y': 1214,
  'start_x': 812,
  'text': 'K.',
  'bbox': [812, 1214, 846, 1214, 846, 1235, 812, 1235]},
 {'id': 569,
  'start_y': 998,
  'start_x': 815,
  'text': 'Sabado',
  'bbox': [815, 997, 890, 998, 890, 1016, 815, 1015]},
 {'id': 782,
  'start_y': 1319,
  'start_x': 817,
  'text': 'Managbanag',
  'bbox': [817, 1319, 938, 1319, 938, 1351, 817, 1351]},
 {'id': 570,
  'start_y': 1030,
  'start_x': 818,
  'text': 'Nik',
  'bbox': [819, 1028, 871, 1030, 870, 1052, 818, 1050]},
 {'id': 561,
  'start_y': 959,
  'start_x': 821,
  'text': 'Palima',
  'bbox': [821, 959, 885, 959, 885, 980, 821, 980]},
 {'id': 417,
  'start_y': 745,
  'start_x': 823,
  'text': 'Hermano',
  'bbox': [823, 745, 896, 745, 896, 764, 823, 764]},
 {'id': 479,
  'start_y': 853,
  'start_x': 823,
  'text': 'Amar',
  'bbox': [823, 853, 874, 853, 874, 871, 823, 871]},
 {'id': 424,
  'start_y': 777,
  'start_x': 825,
  'text': 'lastimado',
  'bbox': [825, 777, 920, 777, 920, 797, 825, 797]},
 {'id': 856,
  'start_y': 1375,
  'start_x': 825,
  'text': ',',
  'bbox': [825, 1375, 829, 1375, 829, 1393, 825, 1393]},
 {'id': 193,
  'start_y': 526,
  'start_x': 826,
  'text': 'Sevillano',
  'bbox': [826, 526, 912, 526, 912, 547, 826, 547]},
 {'id': 370,
  'start_y': 675,
  'start_x': 826,
  'text': 'Algopers',
  'bbox': [828, 670, 906, 675, 904, 703, 826, 698]},
 {'id': 187,
  'start_y': 454,
  'start_x': 827,
  'text': 'Alvarez',
  'bbox': [827, 454, 916, 454, 916, 476, 827, 476]},
 {'id': 190,
  'start_y': 489,
  'start_x': 827,
  'text': 'Malquisto',
  'bbox': [827, 488, 922, 489, 922, 519, 827, 518]},
 {'id': 391,
  'start_y': 708,
  'start_x': 827,
  'text': 'Algopera',
  'bbox': [828, 705, 903, 708, 902, 733, 827, 730]},
 {'id': 413,
  'start_y': 814,
  'start_x': 827,
  'text': 'Norega',
  'bbox': [827, 814, 904, 814, 904, 839, 827, 839]},
 {'id': 576,
  'start_y': 1069,
  'start_x': 829,
  'text': 'Montaño',
  'bbox': [829, 1068, 906, 1069, 906, 1090, 829, 1089]},
 {'id': 614,
  'start_y': 1141,
  'start_x': 830,
  'text': 'Cinco',
  'bbox': [830, 1141, 884, 1141, 884, 1163, 830, 1163]},
 {'id': 857,
  'start_y': 1375,
  'start_x': 832,
  'text': 'twin',
  'bbox': [832, 1375, 862, 1375, 862, 1393, 832, 1393]},
 {'id': 608,
  'start_y': 1100,
  'start_x': 834,
  'text': 'Lamrin',
  'bbox': [834, 1101, 903, 1100, 903, 1122, 834, 1123]},
 {'id': 629,
  'start_y': 1174,
  'start_x': 834,
  'text': 'lumbre',
  'bbox': [834, 1174, 901, 1174, 901, 1199, 834, 1199]},
 {'id': 195,
  'start_y': 558,
  'start_x': 836,
  'text': 'Go',
  'bbox': [836, 558, 866, 558, 866, 582, 836, 582]},
 {'id': 184,
  'start_y': 637,
  'start_x': 837,
  'text': 'Say',
  'bbox': [838, 634, 891, 637, 890, 663, 837, 660]},
 {'id': 185,
  'start_y': 598,
  'start_x': 840,
  'text': 'Cua',
  'bbox': [840, 598, 878, 598, 878, 615, 840, 615]},
 {'id': 640,
  'start_y': 1214,
  'start_x': 850,
  'text': 'Parine',
  'bbox': [850, 1214, 914, 1214, 914, 1235, 850, 1235]},
 {'id': 858,
  'start_y': 1375,
  'start_x': 860,
  'text': ',',
  'bbox': [860, 1375, 864, 1375, 864, 1393, 860, 1393]},
 {'id': 859,
  'start_y': 1375,
  'start_x': 868,
  'text': 'three',
  'bbox': [868, 1375, 907, 1375, 907, 1393, 868, 1393]},
 {'id': 30,
  'start_y': 329,
  'start_x': 875,
  'text': 'AS',
  'bbox': [875, 329, 897, 329, 897, 344, 875, 344]},
 {'id': 45,
  'start_y': 364,
  'start_x': 898,
  'text': 'Last',
  'bbox': [898, 364, 924, 364, 924, 377, 898, 377]},
 {'id': 31,
  'start_y': 329,
  'start_x': 902,
  'text': 'RO',
  'bbox': [902, 329, 924, 329, 924, 343, 902, 343]},
 {'id': 860,
  'start_y': 1375,
  'start_x': 909,
  'text': 'or',
  'bbox': [909, 1375, 925, 1375, 925, 1393, 909, 1393]},
 {'id': 131,
  'start_y': 415,
  'start_x': 910,
  'text': '(',
  'bbox': [910, 415, 916, 415, 916, 429, 910, 429]},
 {'id': 132,
  'start_y': 415,
  'start_x': 916,
  'text': 'ES',
  'bbox': [916, 415, 934, 415, 934, 429, 916, 429]},
 {'id': 46,
  'start_y': 364,
  'start_x': 920,
  'text': ')',
  'bbox': [920, 364, 928, 364, 928, 376, 920, 376]},
 {'id': 861,
  'start_y': 1375,
  'start_x': 927,
  'text': 'more',
  'bbox': [927, 1375, 965, 1375, 965, 1393, 927, 1393]},
 {'id': 32,
  'start_y': 326,
  'start_x': 928,
  'text': '30ARRAM',
  'bbox': [928, 328, 1005, 326, 1005, 341, 928, 343]},
 {'id': 133,
  'start_y': 415,
  'start_x': 932,
  'text': ')',
  'bbox': [932, 415, 939, 415, 939, 429, 932, 429]},
 {'id': 862,
  'start_y': 1375,
  'start_x': 964,
  'text': '.',
  'bbox': [964, 1375, 969, 1375, 969, 1393, 964, 1393]},
 {'id': 783,
  'start_y': 1319,
  'start_x': 965,
  'text': 'F',
  'bbox': [965, 1319, 992, 1319, 992, 1351, 965, 1351]},
 {'id': 582,
  'start_y': 1066,
  'start_x': 968,
  'text': 'M2',
  'bbox': [968, 1067, 1029, 1066, 1029, 1092, 968, 1093]},
 {'id': 630,
  'start_y': 1177,
  'start_x': 968,
  'text': 'M',
  'bbox': [968, 1177, 989, 1177, 989, 1207, 968, 1207]},
 {'id': 585,
  'start_y': 963,
  'start_x': 969,
  'text': 'M18',
  'bbox': [969, 963, 1031, 963, 1031, 981, 969, 981]},
 {'id': 588,
  'start_y': 992,
  'start_x': 969,
  'text': 'M',
  'bbox': [970, 991, 991, 992, 990, 1015, 969, 1014]},
 {'id': 510,
  'start_y': 887,
  'start_x': 970,
  'text': 'M21',
  'bbox': [970, 888, 1030, 887, 1030, 906, 970, 907]},
 {'id': 538,
  'start_y': 923,
  'start_x': 970,
  'text': 'F2',
  'bbox': [969, 925, 1023, 923, 1024, 945, 970, 947]},
 {'id': 580,
  'start_y': 1033,
  'start_x': 970,
  'text': 'MJune',
  'bbox': [970, 1034, 1085, 1033, 1085, 1051, 970, 1052]},
 {'id': 615,
  'start_y': 1142,
  'start_x': 970,
  'text': 'F',
  'bbox': [970, 1142, 984, 1142, 984, 1159, 970, 1159]},
 {'id': 616,
  'start_y': 1105,
  'start_x': 970,
  'text': 'M21',
  'bbox': [969, 1109, 1039, 1105, 1040, 1130, 970, 1134]},
 {'id': 775,
  'start_y': 1287,
  'start_x': 970,
  'text': 'M20',
  'bbox': [970, 1288, 1035, 1287, 1035, 1310, 970, 1311]},
 {'id': 392,
  'start_y': 709,
  'start_x': 971,
  'text': 'F',
  'bbox': [971, 709, 989, 709, 989, 727, 971, 727]},
 {'id': 477,
  'start_y': 814,
  'start_x': 971,
  'text': 'F',
  'bbox': [971, 814, 987, 814, 987, 831, 971, 831]},
 {'id': 483,
  'start_y': 853,
  'start_x': 971,
  'text': 'M5',
  'bbox': [971, 854, 1032, 853, 1032, 873, 971, 874]},
 {'id': 766,
  'start_y': 1251,
  'start_x': 971,
  'text': 'F',
  'bbox': [971, 1251, 987, 1251, 987, 1272, 971, 1272]},
 {'id': 641,
  'start_y': 1213,
  'start_x': 972,
  'text': 'M3',
  'bbox': [970, 1217, 1034, 1213, 1036, 1241, 972, 1245]},
 {'id': 425,
  'start_y': 779,
  'start_x': 973,
  'text': 'M4',
  'bbox': [973, 778, 1032, 779, 1032, 801, 973, 800]},
 {'id': 44,
  'start_y': 352,
  'start_x': 974,
  'text': 'SEX',
  'bbox': [974, 352, 1002, 352, 1002, 366, 974, 366]},
 {'id': 371,
  'start_y': 671,
  'start_x': 974,
  'text': 'M',
  'bbox': [974, 671, 990, 671, 990, 687, 974, 687]},
 {'id': 207,
  'start_y': 452,
  'start_x': 975,
  'text': 'F',
  'bbox': [975, 452, 994, 452, 994, 474, 975, 474]},
 {'id': 205,
  'start_y': 635,
  'start_x': 976,
  'text': 'FG',
  'bbox': [976, 635, 1033, 635, 1033, 654, 976, 654]},
 {'id': 211,
  'start_y': 489,
  'start_x': 976,
  'text': 'M',
  'bbox': [976, 489, 994, 489, 994, 510, 976, 510]},
 {'id': 418,
  'start_y': 741,
  'start_x': 977,
  'text': 'F',
  'bbox': [977, 741, 993, 741, 993, 762, 977, 762]},
 {'id': 134,
  'start_y': 414,
  'start_x': 978,
  'text': '(',
  'bbox': [978, 414, 986, 414, 986, 430, 978, 430]},
 {'id': 196,
  'start_y': 528,
  'start_x': 978,
  'text': 'M12',
  'bbox': [978, 529, 1039, 528, 1039, 547, 978, 548]},
 {'id': 202,
  'start_y': 600,
  'start_x': 978,
  'text': 'M5',
  'bbox': [978, 600, 1037, 600, 1037, 619, 978, 619]},
 {'id': 199,
  'start_y': 562,
  'start_x': 979,
  'text': 'F26',
  'bbox': [978, 564, 1037, 562, 1038, 583, 979, 585]},
 {'id': 863,
  'start_y': 1375,
  'start_x': 982,
  'text': 'eu',
  'bbox': [982, 1375, 998, 1375, 998, 1393, 982, 1393]},
 {'id': 135,
  'start_y': 414,
  'start_x': 983,
  'text': '4',
  'bbox': [983, 414, 994, 414, 994, 430, 983, 430]},
 {'id': 136,
  'start_y': 414,
  'start_x': 990,
  'text': ')',
  'bbox': [990, 414, 999, 414, 999, 430, 990, 430]},
 {'id': 784,
  'start_y': 1319,
  'start_x': 1007,
  'text': '29',
  'bbox': [1007, 1319, 1034, 1319, 1034, 1351, 1007, 1351]},
 {'id': 767,
  'start_y': 1251,
  'start_x': 1010,
  'text': '26',
  'bbox': [1010, 1251, 1036, 1251, 1036, 1272, 1010, 1272]},
 {'id': 631,
  'start_y': 1176,
  'start_x': 1011,
  'text': '1',
  'bbox': [1011, 1176, 1033, 1176, 1033, 1206, 1011, 1206]},
 {'id': 208,
  'start_y': 451,
  'start_x': 1013,
  'text': '30',
  'bbox': [1013, 451, 1040, 451, 1040, 473, 1013, 473]},
 {'id': 577,
  'start_y': 1003,
  'start_x': 1013,
  'text': '2',
  'bbox': [1011, 1004, 1029, 1003, 1031, 1023, 1013, 1024]},
 {'id': 480,
  'start_y': 818,
  'start_x': 1014,
  'text': '2',
  'bbox': [1013, 819, 1030, 818, 1031, 836, 1014, 837]},
 {'id': 212,
  'start_y': 486,
  'start_x': 1015,
  'text': '25',
  'bbox': [1015, 487, 1047, 486, 1047, 508, 1015, 509]},
 {'id': 619,
  'start_y': 1142,
  'start_x': 1015,
  'text': '7',
  'bbox': [1014, 1143, 1030, 1142, 1031, 1163, 1015, 1164]},
 {'id': 73,
  'start_y': 374,
  'start_x': 1016,
  'text': 'Day',
  'bbox': [1016, 374, 1041, 374, 1041, 387, 1016, 387]},
 {'id': 137,
  'start_y': 414,
  'start_x': 1019,
  'text': '(',
  'bbox': [1019, 414, 1026, 414, 1026, 430, 1019, 430]},
 {'id': 372,
  'start_y': 670,
  'start_x': 1019,
  'text': '1',
  'bbox': [1019, 670, 1032, 670, 1032, 688, 1019, 688]},
 {'id': 864,
  'start_y': 1375,
  'start_x': 1020,
  'text': 'spiser',
  'bbox': [1020, 1375, 1081, 1375, 1081, 1393, 1020, 1393]},
 {'id': 393,
  'start_y': 708,
  'start_x': 1022,
  'text': '6',
  'bbox': [1022, 708, 1037, 708, 1037, 727, 1022, 727]},
 {'id': 138,
  'start_y': 414,
  'start_x': 1023,
  'text': '5',
  'bbox': [1023, 414, 1033, 414, 1033, 430, 1023, 430]},
 {'id': 419,
  'start_y': 741,
  'start_x': 1023,
  'text': '15',
  'bbox': [1023, 741, 1045, 741, 1045, 762, 1023, 762]},
 {'id': 139,
  'start_y': 414,
  'start_x': 1031,
  'text': ')',
  'bbox': [1031, 414, 1038, 414, 1038, 430, 1031, 430]},
 {'id': 583,
  'start_y': 1066,
  'start_x': 1043,
  'text': 'July',
  'bbox': [1043, 1066, 1079, 1066, 1079, 1092, 1043, 1092]},
 {'id': 776,
  'start_y': 1287,
  'start_x': 1043,
  'text': 'AP',
  'bbox': [1043, 1288, 1111, 1287, 1111, 1309, 1043, 1310]},
 {'id': 617,
  'start_y': 1103,
  'start_x': 1044,
  'text': 'May',
  'bbox': [1043, 1105, 1085, 1103, 1086, 1128, 1044, 1130]},
 {'id': 632,
  'start_y': 1175,
  'start_x': 1044,
  'text': 'tag',
  'bbox': [1044, 1175, 1084, 1175, 1084, 1206, 1044, 1206]},
 {'id': 484,
  'start_y': 851,
  'start_x': 1045,
  'text': 'Feb.',
  'bbox': [1045, 852, 1088, 851, 1088, 871, 1045, 872]},
 {'id': 620,
  'start_y': 1140,
  'start_x': 1045,
  'text': 'July',
  'bbox': [1044, 1141, 1084, 1140, 1085, 1162, 1045, 1163]},
 {'id': 642,
  'start_y': 1209,
  'start_x': 1045,
  'text': 'Sept',
  'bbox': [1043, 1212, 1085, 1209, 1087, 1237, 1045, 1240]},
 {'id': 785,
  'start_y': 1319,
  'start_x': 1045,
  'text': 'Arg',
  'bbox': [1045, 1319, 1087, 1319, 1087, 1351, 1045, 1351]},
 {'id': 39,
  'start_y': 322,
  'start_x': 1046,
  'text': 'DATE',
  'bbox': [1046, 322, 1087, 322, 1087, 335, 1046, 335]},
 {'id': 511,
  'start_y': 885,
  'start_x': 1046,
  'text': 'Feb.',
  'bbox': [1046, 886, 1092, 885, 1092, 905, 1046, 906]},
 {'id': 539,
  'start_y': 921,
  'start_x': 1046,
  'text': 'Jan',
  'bbox': [1045, 922, 1083, 921, 1084, 943, 1046, 944]},
 {'id': 768,
  'start_y': 1252,
  'start_x': 1046,
  'text': 'June',
  'bbox': [1046, 1251, 1088, 1252, 1088, 1274, 1046, 1273]},
 {'id': 394,
  'start_y': 705,
  'start_x': 1047,
  'text': 'Jun',
  'bbox': [1047, 706, 1080, 705, 1080, 725, 1047, 726]},
 {'id': 197,
  'start_y': 526,
  'start_x': 1048,
  'text': 'Jan.',
  'bbox': [1048, 527, 1091, 526, 1091, 545, 1048, 546]},
 {'id': 203,
  'start_y': 600,
  'start_x': 1048,
  'text': 'Jan.',
  'bbox': [1048, 600, 1095, 600, 1095, 619, 1048, 619]},
 {'id': 373,
  'start_y': 669,
  'start_x': 1048,
  'text': 'Feb.',
  'bbox': [1048, 669, 1085, 669, 1085, 687, 1048, 687]},
 {'id': 578,
  'start_y': 998,
  'start_x': 1048,
  'text': 'May',
  'bbox': [1046, 1001, 1086, 998, 1087, 1018, 1048, 1021]},
 {'id': 200,
  'start_y': 561,
  'start_x': 1049,
  'text': 'Jan.',
  'bbox': [1048, 562, 1088, 561, 1089, 582, 1049, 583]},
 {'id': 213,
  'start_y': 485,
  'start_x': 1049,
  'text': 'Dec.',
  'bbox': [1049, 486, 1088, 485, 1088, 507, 1049, 508]},
 {'id': 426,
  'start_y': 778,
  'start_x': 1049,
  'text': 'Feb.',
  'bbox': [1049, 778, 1089, 778, 1089, 801, 1049, 801]},
 {'id': 481,
  'start_y': 816,
  'start_x': 1049,
  'text': 'Mar.',
  'bbox': [1048, 817, 1092, 816, 1093, 835, 1049, 836]},
 {'id': 206,
  'start_y': 635,
  'start_x': 1050,
  'text': 'JP',
  'bbox': [1050, 635, 1117, 635, 1117, 654, 1050, 654]},
 {'id': 420,
  'start_y': 743,
  'start_x': 1051,
  'text': 'Feb.',
  'bbox': [1051, 742, 1090, 743, 1090, 764, 1051, 763]},
 {'id': 586,
  'start_y': 963,
  'start_x': 1052,
  'text': 'Mar',
  'bbox': [1052, 963, 1087, 963, 1087, 981, 1052, 981]},
 {'id': 209,
  'start_y': 451,
  'start_x': 1057,
  'text': 'Nov',
  'bbox': [1057, 451, 1092, 451, 1092, 473, 1057, 473]},
 {'id': 74,
  'start_y': 374,
  'start_x': 1060,
  'text': 'Mo.',
  'bbox': [1060, 374, 1085, 374, 1085, 387, 1060, 387]},
 {'id': 140,
  'start_y': 414,
  'start_x': 1063,
  'text': '(',
  'bbox': [1063, 414, 1070, 414, 1070, 429, 1063, 429]},
 {'id': 141,
  'start_y': 414,
  'start_x': 1068,
  'text': '6',
  'bbox': [1068, 414, 1078, 414, 1078, 429, 1068, 429]},
 {'id': 42,
  'start_y': 342,
  'start_x': 1069,
  'text': 'OF',
  'bbox': [1069, 342, 1091, 342, 1091, 354, 1069, 354]},
 {'id': 142,
  'start_y': 414,
  'start_x': 1075,
  'text': ')',
  'bbox': [1075, 414, 1083, 414, 1083, 429, 1075, 429]},
 {'id': 395,
  'start_y': 706,
  'start_x': 1076,
  'text': '.',
  'bbox': [1076, 706, 1087, 706, 1087, 725, 1076, 725]},
 {'id': 633,
  'start_y': 1175,
  'start_x': 1076,
  'text': '.',
  'bbox': [1076, 1175, 1090, 1175, 1090, 1205, 1076, 1205]},
 {'id': 786,
  'start_y': 1319,
  'start_x': 1079,
  'text': '.',
  'bbox': [1079, 1319, 1099, 1319, 1099, 1351, 1079, 1351]},
 {'id': 40,
  'start_y': 322,
  'start_x': 1089,
  'text': 'AND',
  'bbox': [1089, 322, 1124, 322, 1124, 335, 1089, 335]},
 {'id': 581,
  'start_y': 1033,
  'start_x': 1092,
  'text': '1945',
  'bbox': [1092, 1033, 1130, 1033, 1130, 1050, 1092, 1050]},
 {'id': 584,
  'start_y': 1066,
  'start_x': 1093,
  'text': '1965',
  'bbox': [1093, 1066, 1128, 1066, 1128, 1091, 1093, 1091]},
 {'id': 396,
  'start_y': 704,
  'start_x': 1094,
  'text': '1966',
  'bbox': [1094, 705, 1134, 704, 1134, 724, 1094, 725]},
 {'id': 485,
  'start_y': 850,
  'start_x': 1094,
  'text': '1965',
  'bbox': [1094, 851, 1137, 850, 1137, 870, 1094, 871]},
 {'id': 587,
  'start_y': 963,
  'start_x': 1094,
  'text': '1965',
  'bbox': [1094, 963, 1133, 963, 1133, 981, 1094, 981]},
 {'id': 43,
  'start_y': 342,
  'start_x': 1095,
  'text': 'BIRTH',
  'bbox': [1095, 342, 1139, 342, 1139, 354, 1095, 354]},
 {'id': 214,
  'start_y': 486,
  'start_x': 1095,
  'text': 'K',
  'bbox': [1095, 486, 1115, 486, 1115, 507, 1095, 507]},
 {'id': 512,
  'start_y': 884,
  'start_x': 1095,
  'text': '1745',
  'bbox': [1095, 885, 1133, 884, 1133, 903, 1095, 904]},
 {'id': 618,
  'start_y': 1100,
  'start_x': 1095,
  'text': '165',
  'bbox': [1094, 1102, 1133, 1100, 1134, 1125, 1095, 1127]},
 {'id': 198,
  'start_y': 525,
  'start_x': 1096,
  'text': '1965',
  'bbox': [1096, 526, 1135, 525, 1135, 545, 1096, 546]},
 {'id': 374,
  'start_y': 668,
  'start_x': 1096,
  'text': '1965',
  'bbox': [1096, 668, 1132, 668, 1132, 686, 1096, 686]},
 {'id': 427,
  'start_y': 779,
  'start_x': 1096,
  'text': '1965',
  'bbox': [1096, 779, 1135, 779, 1135, 801, 1096, 801]},
 {'id': 579,
  'start_y': 994,
  'start_x': 1096,
  'text': '1965',
  'bbox': [1094, 997, 1130, 994, 1131, 1014, 1096, 1017]},
 {'id': 621,
  'start_y': 1138,
  'start_x': 1096,
  'text': '1965',
  'bbox': [1095, 1139, 1132, 1138, 1133, 1160, 1096, 1161]},
 {'id': 201,
  'start_y': 560,
  'start_x': 1097,
  'text': '1965',
  'bbox': [1096, 561, 1135, 560, 1136, 580, 1097, 581]},
 {'id': 643,
  'start_y': 1206,
  'start_x': 1097,
  'text': '1965',
  'bbox': [1095, 1208, 1132, 1206, 1134, 1235, 1097, 1237]},
 {'id': 769,
  'start_y': 1253,
  'start_x': 1097,
  'text': '1965',
  'bbox': [1097, 1252, 1132, 1253, 1132, 1275, 1097, 1274]},
 {'id': 787,
  'start_y': 1319,
  'start_x': 1097,
  'text': '1765',
  'bbox': [1097, 1319, 1133, 1319, 1133, 1351, 1097, 1351]},
 {'id': 482,
  'start_y': 814,
  'start_x': 1098,
  'text': '165',
  'bbox': [1097, 815, 1132, 814, 1133, 833, 1098, 834]},
 {'id': 540,
  'start_y': 920,
  'start_x': 1098,
  'text': '1965',
  'bbox': [1097, 921, 1134, 920, 1135, 941, 1098, 942]},
 {'id': 75,
  'start_y': 374,
  'start_x': 1100,
  'text': 'Year',
  'bbox': [1100, 374, 1129, 374, 1129, 387, 1100, 387]},
 {'id': 421,
  'start_y': 743,
  'start_x': 1100,
  'text': '1965',
  'bbox': [1100, 742, 1139, 743, 1139, 765, 1100, 764]},
 {'id': 865,
  'start_y': 1375,
  'start_x': 1100,
  'text': 'inte2',
  'bbox': [1100, 1375, 1146, 1375, 1146, 1393, 1100, 1393]},
 {'id': 210,
  'start_y': 451,
  'start_x': 1101,
  'text': '1964',
  'bbox': [1101, 451, 1140, 451, 1140, 473, 1101, 473]},
 {'id': 634,
  'start_y': 1175,
  'start_x': 1102,
  'text': '1945',
  'bbox': [1102, 1175, 1138, 1175, 1138, 1205, 1102, 1205]},
 {'id': 204,
  'start_y': 600,
  'start_x': 1103,
  'text': '1945',
  'bbox': [1103, 600, 1139, 600, 1139, 619, 1103, 619]},
 {'id': 143,
  'start_y': 433,
  'start_x': 1105,
  'text': 'E',
  'bbox': [1127, 411, 1127, 433, 1105, 433, 1105, 411]},
 {'id': 144,
  'start_y': 415,
  'start_x': 1106,
  'text': '(',
  'bbox': [1106, 415, 1120, 415, 1120, 430, 1106, 430]},
 {'id': 145,
  'start_y': 414,
  'start_x': 1109,
  'text': '7',
  'bbox': [1109, 414, 1125, 414, 1125, 429, 1109, 429]},
 {'id': 146,
  'start_y': 414,
  'start_x': 1114,
  'text': ')',
  'bbox': [1114, 414, 1127, 414, 1127, 429, 1114, 429]},
 {'id': 41,
  'start_y': 322,
  'start_x': 1127,
  'text': 'TIME',
  'bbox': [1127, 322, 1165, 322, 1165, 335, 1127, 335]},
 {'id': 887,
  'start_y': 1393,
  'start_x': 1132,
  'text': 'guito',
  'bbox': [1132, 1393, 1168, 1393, 1168, 1404, 1132, 1404]},
 {'id': 76,
  'start_y': 374,
  'start_x': 1149,
  'text': 'Time',
  'bbox': [1149, 374, 1180, 374, 1180, 387, 1149, 387]},
 {'id': 866,
  'start_y': 1375,
  'start_x': 1153,
  'text': 'Order',
  'bbox': [1153, 1375, 1195, 1375, 1195, 1393, 1153, 1393]},
 {'id': 147,
  'start_y': 413,
  'start_x': 1158,
  'text': '(',
  'bbox': [1158, 413, 1166, 413, 1166, 428, 1158, 428]},
 {'id': 148,
  'start_y': 413,
  'start_x': 1163,
  'text': '8',
  'bbox': [1163, 413, 1174, 413, 1174, 428, 1163, 428]},
 {'id': 149,
  'start_y': 413,
  'start_x': 1170,
  'text': ')',
  'bbox': [1170, 413, 1180, 413, 1180, 428, 1170, 428]},
 {'id': 888,
  'start_y': 1393,
  'start_x': 1172,
  'text': 'ai',
  'bbox': [1172, 1393, 1187, 1393, 1187, 1404, 1172, 1404]},
 {'id': 867,
  'start_y': 1375,
  'start_x': 1200,
  'text': '(',
  'bbox': [1200, 1375, 1205, 1375, 1205, 1393, 1200, 1393]},
 {'id': 678,
  'start_y': 1213,
  'start_x': 1203,
  'text': 'Realst',
  'bbox': [1204, 1211, 1272, 1213, 1271, 1240, 1203, 1238]},
 {'id': 868,
  'start_y': 1375,
  'start_x': 1204,
  'text': 'Col.11',
  'bbox': [1204, 1375, 1248, 1375, 1248, 1393, 1204, 1393]},
 {'id': 655,
  'start_y': 1065,
  'start_x': 1205,
  'text': 'Zamora',
  'bbox': [1205, 1065, 1272, 1065, 1272, 1096, 1205, 1096]},
 {'id': 788,
  'start_y': 1251,
  'start_x': 1205,
  'text': 'Talisayan',
  'bbox': [1205, 1252, 1301, 1251, 1301, 1285, 1205, 1286]},
 {'id': 644,
  'start_y': 1031,
  'start_x': 1206,
  'text': 'Talisayan',
  'bbox': [1206, 1032, 1300, 1031, 1300, 1062, 1206, 1063]},
 {'id': 796,
  'start_y': 1323,
  'start_x': 1206,
  'text': 'Bugho',
  'bbox': [1209, 1315, 1282, 1323, 1278, 1352, 1206, 1344]},
 {'id': 792,
  'start_y': 1287,
  'start_x': 1210,
  'text': 'Oding',
  'bbox': [1211, 1285, 1279, 1287, 1278, 1313, 1210, 1311]},
 {'id': 666,
  'start_y': 1103,
  'start_x': 1211,
  'text': 'Bugho',
  'bbox': [1212, 1101, 1278, 1103, 1277, 1129, 1211, 1127]},
 {'id': 672,
  'start_y': 1141,
  'start_x': 1211,
  'text': 'Bugho',
  'bbox': [1211, 1141, 1276, 1141, 1276, 1167, 1211, 1167]},
 {'id': 677,
  'start_y': 1176,
  'start_x': 1211,
  'text': 'Talisayan',
  'bbox': [1212, 1174, 1332, 1176, 1331, 1208, 1211, 1206]},
 {'id': 889,
  'start_y': 1394,
  'start_x': 1213,
  'text': 'hapo',
  'bbox': [1213, 1393, 1281, 1394, 1281, 1406, 1213, 1405]},
 {'id': 513,
  'start_y': 885,
  'start_x': 1217,
  'text': 'Bugh',
  'bbox': [1219, 881, 1283, 885, 1281, 911, 1217, 907]},
 {'id': 505,
  'start_y': 852,
  'start_x': 1219,
  'text': 'Malibogay',
  'bbox': [1221, 846, 1329, 852, 1327, 884, 1219, 878]},
 {'id': 150,
  'start_y': 411,
  'start_x': 1220,
  'text': '(',
  'bbox': [1220, 411, 1225, 411, 1225, 424, 1220, 424]},
 {'id': 215,
  'start_y': 484,
  'start_x': 1222,
  'text': 'Tacloban',
  'bbox': [1223, 481, 1317, 484, 1316, 510, 1222, 507]},
 {'id': 224,
  'start_y': 447,
  'start_x': 1222,
  'text': 'Bugho',
  'bbox': [1221, 449, 1282, 447, 1283, 477, 1222, 479]},
 {'id': 151,
  'start_y': 410,
  'start_x': 1223,
  'text': 'er',
  'bbox': [1223, 410, 1241, 410, 1241, 423, 1223, 423]},
 {'id': 486,
  'start_y': 813,
  'start_x': 1224,
  'text': 'Tambis',
  'bbox': [1225, 810, 1300, 813, 1299, 835, 1224, 832]},
 {'id': 541,
  'start_y': 923,
  'start_x': 1224,
  'text': 'Bugm',
  'bbox': [1224, 922, 1281, 923, 1281, 944, 1224, 943]},
 {'id': 246,
  'start_y': 557,
  'start_x': 1226,
  'text': 'Tachban',
  'bbox': [1227, 553, 1321, 557, 1320, 583, 1226, 579]},
 {'id': 422,
  'start_y': 743,
  'start_x': 1226,
  'text': 'Pugho',
  'bbox': [1226, 743, 1294, 743, 1294, 766, 1226, 766]},
 {'id': 428,
  'start_y': 777,
  'start_x': 1227,
  'text': 'Rizal',
  'bbox': [1227, 777, 1282, 777, 1282, 799, 1227, 799]},
 {'id': 240,
  'start_y': 520,
  'start_x': 1229,
  'text': 'Bughs',
  'bbox': [1229, 520, 1290, 520, 1290, 554, 1229, 554]},
 {'id': 253,
  'start_y': 595,
  'start_x': 1230,
  'text': 'Binulhi',
  'bbox': [1230, 595, 1301, 595, 1301, 621, 1230, 621]},
 {'id': 50,
  'start_y': 346,
  'start_x': 1232,
  'text': 'Name',
  'bbox': [1232, 346, 1268, 346, 1268, 359, 1232, 359]},
 {'id': 152,
  'start_y': 410,
  'start_x': 1240,
  'text': ')',
  'bbox': [1240, 410, 1245, 410, 1245, 423, 1240, 423]},
 {'id': 869,
  'start_y': 1375,
  'start_x': 1249,
  'text': ')',
  'bbox': [1249, 1375, 1255, 1375, 1255, 1393, 1249, 1393]},
 {'id': 59,
  'start_y': 361,
  'start_x': 1253,
  'text': 'hospital',
  'bbox': [1253, 361, 1302, 361, 1302, 374, 1253, 374]},
 {'id': 679,
  'start_y': 1213,
  'start_x': 1263,
  'text': '.',
  'bbox': [1264, 1213, 1277, 1213, 1276, 1239, 1263, 1239]},
 {'id': 870,
  'start_y': 1375,
  'start_x': 1263,
  'text': 'If',
  'bbox': [1263, 1375, 1275, 1375, 1275, 1393, 1263, 1393]},
 {'id': 51,
  'start_y': 346,
  'start_x': 1270,
  'text': 'of',
  'bbox': [1270, 346, 1283, 346, 1283, 358, 1270, 358]},
 {'id': 871,
  'start_y': 1375,
  'start_x': 1276,
  'text': 'multiple',
  'bbox': [1276, 1375, 1332, 1375, 1332, 1393, 1276, 1393]},
 {'id': 47,
  'start_y': 327,
  'start_x': 1277,
  'text': 'PLACE',
  'bbox': [1277, 327, 1326, 327, 1326, 341, 1277, 341]},
 {'id': 153,
  'start_y': 409,
  'start_x': 1277,
  'text': '(',
  'bbox': [1277, 409, 1282, 409, 1282, 425, 1277, 425]},
 {'id': 656,
  'start_y': 1065,
  'start_x': 1279,
  'text': 'St',
  'bbox': [1279, 1065, 1305, 1065, 1305, 1096, 1279, 1096]},
 {'id': 154,
  'start_y': 408,
  'start_x': 1280,
  'text': '8',
  'bbox': [1280, 408, 1290, 408, 1290, 424, 1280, 424]},
 {'id': 398,
  'start_y': 709,
  'start_x': 1283,
  'text': '4',
  'bbox': [1283, 709, 1294, 709, 1294, 719, 1283, 719]},
 {'id': 429,
  'start_y': 777,
  'start_x': 1283,
  'text': '.',
  'bbox': [1283, 777, 1295, 777, 1295, 799, 1283, 799]},
 {'id': 52,
  'start_y': 346,
  'start_x': 1285,
  'text': 'Hospital',
  'bbox': [1285, 346, 1333, 346, 1333, 358, 1285, 358]},
 {'id': 680,
  'start_y': 1215,
  'start_x': 1286,
  'text': 'Bugh',
  'bbox': [1287, 1213, 1345, 1215, 1344, 1242, 1286, 1240]},
 {'id': 592,
  'start_y': 1000,
  'start_x': 1287,
  'text': '24',
  'bbox': [1287, 1000, 1295, 1000, 1295, 1008, 1287, 1008]},
 {'id': 622,
  'start_y': 1123,
  'start_x': 1293,
  'text': 'J',
  'bbox': [1293, 1123, 1304, 1123, 1304, 1132, 1293, 1132]},
 {'id': 890,
  'start_y': 1394,
  'start_x': 1293,
  'text': 'of',
  'bbox': [1293, 1394, 1308, 1394, 1308, 1405, 1293, 1405]},
 {'id': 155,
  'start_y': 408,
  'start_x': 1296,
  'text': ')',
  'bbox': [1296, 408, 1302, 408, 1302, 424, 1296, 424]},
 {'id': 645,
  'start_y': 1031,
  'start_x': 1300,
  'text': ',',
  'bbox': [1300, 1031, 1309, 1031, 1309, 1061, 1300, 1061]},
 {'id': 657,
  'start_y': 1065,
  'start_x': 1300,
  'text': ',',
  'bbox': [1300, 1065, 1311, 1065, 1311, 1096, 1300, 1096]},
 {'id': 60,
  'start_y': 361,
  'start_x': 1301,
  'text': ',',
  'bbox': [1301, 361, 1305, 361, 1305, 374, 1301, 374]},
 {'id': 61,
  'start_y': 361,
  'start_x': 1306,
  'text': 'give',
  'bbox': [1306, 361, 1331, 361, 1331, 374, 1306, 374]},
 {'id': 891,
  'start_y': 1395,
  'start_x': 1310,
  'text': 'began',
  'bbox': [1310, 1394, 1369, 1395, 1369, 1407, 1310, 1406]},
 {'id': 556,
  'start_y': 942,
  'start_x': 1315,
  'text': '2',
  'bbox': [1315, 942, 1328, 942, 1328, 953, 1315, 953]},
 {'id': 646,
  'start_y': 1030,
  'start_x': 1315,
  'text': 'Bugho',
  'bbox': [1315, 1031, 1373, 1030, 1373, 1061, 1315, 1062]},
 {'id': 658,
  'start_y': 1065,
  'start_x': 1318,
  'text': 'Bughs',
  'bbox': [1318, 1065, 1378, 1065, 1378, 1096, 1318, 1096]},
 {'id': 591,
  'start_y': 979,
  'start_x': 1319,
  'text': '+',
  'bbox': [1318, 980, 1333, 979, 1334, 993, 1319, 994]},
 {'id': 789,
  'start_y': 1249,
  'start_x': 1319,
  'text': 'Bughs',
  'bbox': [1319, 1250, 1379, 1249, 1379, 1283, 1319, 1284]},
 {'id': 793,
  'start_y': 1283,
  'start_x': 1324,
  'text': 'Bughs',
  'bbox': [1326, 1279, 1393, 1283, 1391, 1316, 1324, 1312]},
 {'id': 487,
  'start_y': 810,
  'start_x': 1326,
  'text': 'RE',
  'bbox': [1327, 809, 1355, 810, 1354, 838, 1326, 837]},
 {'id': 623,
  'start_y': 1104,
  'start_x': 1327,
  'text': 'Leiste',
  'bbox': [1327, 1104, 1376, 1104, 1376, 1130, 1327, 1130]},
 {'id': 624,
  'start_y': 1143,
  'start_x': 1327,
  'text': 'Leyte',
  'bbox': [1327, 1143, 1373, 1143, 1373, 1172, 1327, 1172]},
 {'id': 515,
  'start_y': 883,
  'start_x': 1329,
  'text': 'W',
  'bbox': [1330, 883, 1350, 883, 1349, 910, 1329, 910]},
 {'id': 48,
  'start_y': 327,
  'start_x': 1330,
  'text': 'OF',
  'bbox': [1330, 327, 1352, 327, 1352, 340, 1330, 340]},
 {'id': 53,
  'start_y': 346,
  'start_x': 1332,
  'text': '/',
  'bbox': [1332, 346, 1337, 346, 1337, 358, 1332, 358]},
 {'id': 62,
  'start_y': 361,
  'start_x': 1334,
  'text': 'street',
  'bbox': [1334, 361, 1370, 361, 1370, 374, 1334, 374]},
 {'id': 54,
  'start_y': 345,
  'start_x': 1335,
  'text': 'Institution',
  'bbox': [1335, 345, 1391, 345, 1391, 358, 1335, 358]},
 {'id': 589,
  'start_y': 962,
  'start_x': 1335,
  'text': 'M',
  'bbox': [1335, 962, 1349, 962, 1349, 976, 1335, 976]},
 {'id': 797,
  'start_y': 1322,
  'start_x': 1335,
  'text': 'Leyte',
  'bbox': [1335, 1322, 1383, 1322, 1383, 1348, 1335, 1348]},
 {'id': 872,
  'start_y': 1375,
  'start_x': 1335,
  'text': 'birth',
  'bbox': [1335, 1375, 1367, 1375, 1367, 1393, 1335, 1393]},
 {'id': 238,
  'start_y': 483,
  'start_x': 1339,
  'text': '-City',
  'bbox': [1338, 486, 1393, 483, 1394, 510, 1339, 513]},
 {'id': 225,
  'start_y': 445,
  'start_x': 1340,
  'text': 'teyte',
  'bbox': [1339, 446, 1391, 445, 1392, 475, 1340, 476]},
 {'id': 156,
  'start_y': 412,
  'start_x': 1346,
  'text': '(',
  'bbox': [1346, 412, 1355, 412, 1355, 427, 1346, 427]},
 {'id': 157,
  'start_y': 412,
  'start_x': 1349,
  'text': '9',
  'bbox': [1349, 412, 1361, 412, 1361, 427, 1349, 427]},
 {'id': 681,
  'start_y': 1215,
  'start_x': 1350,
  'text': ',',
  'bbox': [1351, 1215, 1364, 1215, 1363, 1241, 1350, 1241]},
 {'id': 241,
  'start_y': 520,
  'start_x': 1352,
  'text': 'leyte',
  'bbox': [1352, 520, 1402, 520, 1402, 554, 1352, 554]},
 {'id': 49,
  'start_y': 327,
  'start_x': 1357,
  'text': 'BIRTH',
  'bbox': [1357, 327, 1399, 327, 1399, 340, 1357, 340]},
 {'id': 158,
  'start_y': 412,
  'start_x': 1357,
  'text': ')',
  'bbox': [1357, 412, 1365, 412, 1365, 427, 1357, 427]},
 {'id': 247,
  'start_y': 561,
  'start_x': 1357,
  'text': 'City',
  'bbox': [1358, 559, 1406, 561, 1405, 586, 1357, 584]},
 {'id': 516,
  'start_y': 886,
  'start_x': 1362,
  'text': 'lestemnimal',
  'bbox': [1363, 883, 1475, 886, 1474, 914, 1362, 911]},
 {'id': 432,
  'start_y': 776,
  'start_x': 1363,
  'text': 'Bugho',
  'bbox': [1363, 775, 1426, 776, 1426, 804, 1363, 803]},
 {'id': 514,
  'start_y': 849,
  'start_x': 1363,
  'text': 'Banghay',
  'bbox': [1363, 848, 1474, 849, 1474, 881, 1363, 880]},
 {'id': 488,
  'start_y': 811,
  'start_x': 1364,
  'text': 'Bughos',
  'bbox': [1365, 809, 1447, 811, 1446, 840, 1364, 838]},
 {'id': 63,
  'start_y': 361,
  'start_x': 1365,
  'text': '/',
  'bbox': [1365, 361, 1372, 361, 1372, 374, 1365, 374]},
 {'id': 216,
  'start_y': 595,
  'start_x': 1367,
  'text': 'Bugho',
  'bbox': [1367, 595, 1428, 595, 1428, 621, 1367, 621]},
 {'id': 64,
  'start_y': 361,
  'start_x': 1368,
  'text': 'barangay',
  'bbox': [1368, 361, 1427, 361, 1427, 374, 1368, 374]},
 {'id': 430,
  'start_y': 736,
  'start_x': 1369,
  'text': 'Tyte',
  'bbox': [1371, 733, 1421, 736, 1419, 765, 1369, 762]},
 {'id': 873,
  'start_y': 1375,
  'start_x': 1371,
  'text': 'in',
  'bbox': [1371, 1375, 1382, 1375, 1382, 1393, 1371, 1393]},
 {'id': 647,
  'start_y': 1030,
  'start_x': 1372,
  'text': ',',
  'bbox': [1372, 1030, 1383, 1030, 1383, 1060, 1372, 1060]},
 {'id': 659,
  'start_y': 1065,
  'start_x': 1373,
  'text': ',',
  'bbox': [1373, 1065, 1385, 1065, 1385, 1096, 1373, 1096]},
 {'id': 682,
  'start_y': 1217,
  'start_x': 1376,
  'text': 'ley',
  'bbox': [1377, 1216, 1409, 1217, 1408, 1243, 1376, 1242]},
 {'id': 590,
  'start_y': 962,
  'start_x': 1377,
  'text': '9',
  'bbox': [1377, 962, 1385, 962, 1385, 971, 1377, 971]},
 {'id': 790,
  'start_y': 1250,
  'start_x': 1381,
  'text': ',',
  'bbox': [1381, 1250, 1393, 1250, 1393, 1283, 1381, 1283]},
 {'id': 874,
  'start_y': 1375,
  'start_x': 1386,
  'text': 'Col.',
  'bbox': [1386, 1375, 1414, 1375, 1414, 1393, 1386, 1393]},
 {'id': 794,
  'start_y': 1284,
  'start_x': 1387,
  'text': ',',
  'bbox': [1389, 1283, 1403, 1284, 1401, 1316, 1387, 1315]},
 {'id': 648,
  'start_y': 1029,
  'start_x': 1389,
  'text': 'Leyte',
  'bbox': [1389, 1030, 1435, 1029, 1435, 1059, 1389, 1060]},
 {'id': 335,
  'start_y': 634,
  'start_x': 1390,
  'text': '"',
  'bbox': [1390, 634, 1398, 634, 1398, 645, 1390, 645]},
 {'id': 660,
  'start_y': 1065,
  'start_x': 1391,
  'text': 'Leyte',
  'bbox': [1391, 1065, 1435, 1065, 1435, 1096, 1391, 1096]},
 {'id': 55,
  'start_y': 345,
  'start_x': 1392,
  'text': ';',
  'bbox': [1392, 345, 1397, 345, 1397, 357, 1392, 357]},
 {'id': 397,
  'start_y': 704,
  'start_x': 1393,
  'text': '#',
  'bbox': [1393, 704, 1400, 704, 1400, 712, 1393, 712]},
 {'id': 56,
  'start_y': 345,
  'start_x': 1399,
  'text': 'if',
  'bbox': [1399, 345, 1407, 345, 1407, 357, 1399, 357]},
 {'id': 77,
  'start_y': 380,
  'start_x': 1400,
  'text': '17433',
  'bbox': [1400, 380, 1428, 380, 1428, 392, 1400, 392]},
 {'id': 791,
  'start_y': 1249,
  'start_x': 1400,
  'text': 'leyte',
  'bbox': [1400, 1250, 1440, 1249, 1440, 1282, 1400, 1283]},
 {'id': 57,
  'start_y': 345,
  'start_x': 1410,
  'text': 'not',
  'bbox': [1410, 345, 1429, 345, 1429, 357, 1410, 357]},
 {'id': 795,
  'start_y': 1286,
  'start_x': 1410,
  'text': 'leyle',
  'bbox': [1412, 1284, 1453, 1286, 1451, 1319, 1410, 1317]},
 {'id': 875,
  'start_y': 1375,
  'start_x': 1417,
  'text': '10',
  'bbox': [1417, 1375, 1432, 1375, 1432, 1393, 1417, 1393]},
 {'id': 239,
  'start_y': 479,
  'start_x': 1419,
  'text': 'what',
  'bbox': [1418, 482, 1483, 479, 1484, 506, 1419, 509]},
 {'id': 673,
  'start_y': 1138,
  'start_x': 1426,
  'text': 'www',
  'bbox': [1426, 1138, 1489, 1138, 1489, 1163, 1426, 1163]},
 {'id': 876,
  'start_y': 1375,
  'start_x': 1431,
  'text': ',',
  'bbox': [1431, 1375, 1436, 1375, 1436, 1393, 1431, 1393]},
 {'id': 58,
  'start_y': 345,
  'start_x': 1432,
  'text': 'in',
  'bbox': [1432, 345, 1441, 345, 1441, 357, 1432, 357]},
 {'id': 877,
  'start_y': 1375,
  'start_x': 1439,
  'text': 'was',
  'bbox': [1439, 1375, 1470, 1375, 1470, 1393, 1439, 1393]},
 {'id': 431,
  'start_y': 740,
  'start_x': 1441,
  'text': 'in',
  'bbox': [1443, 738, 1467, 740, 1465, 769, 1441, 767]},
 {'id': 217,
  'start_y': 595,
  'start_x': 1442,
  'text': '...',
  'bbox': [1442, 595, 1471, 595, 1471, 621, 1442, 621]},
 {'id': 489,
  'start_y': 817,
  'start_x': 1447,
  'text': '201',
  'bbox': [1447, 817, 1466, 817, 1466, 829, 1447, 829]},
 {'id': 226,
  'start_y': 442,
  'start_x': 1469,
  'text': 'Single',
  'bbox': [1468, 443, 1518, 442, 1519, 472, 1469, 473]},
 {'id': 878,
  'start_y': 1375,
  'start_x': 1472,
  'text': 'child',
  'bbox': [1472, 1375, 1505, 1375, 1505, 1393, 1472, 1393]},
 {'id': 78,
  'start_y': 378,
  'start_x': 1481,
  'text': 'Type',
  'bbox': [1481, 378, 1512, 378, 1512, 389, 1481, 389]},
 {'id': 649,
  'start_y': 1029,
  'start_x': 1484,
  'text': '"',
  'bbox': [1484, 1029, 1501, 1029, 1501, 1059, 1484, 1059]},
 {'id': 159,
  'start_y': 411,
  'start_x': 1485,
  'text': '(',
  'bbox': [1485, 411, 1491, 411, 1491, 426, 1485, 426]},
 {'id': 742,
  'start_y': 1214,
  'start_x': 1485,
  'text': 'Und',
  'bbox': [1485, 1214, 1565, 1214, 1565, 1237, 1485, 1237]},
 {'id': 248,
  'start_y': 558,
  'start_x': 1486,
  'text': '"',
  'bbox': [1486, 558, 1502, 558, 1502, 582, 1486, 582]},
 {'id': 798,
  'start_y': 1252,
  'start_x': 1486,
  'text': '11',
  'bbox': [1486, 1252, 1498, 1252, 1498, 1263, 1486, 1263]},
 {'id': 832,
  'start_y': 1329,
  'start_x': 1487,
  'text': 'U',
  'bbox': [1487, 1329, 1495, 1329, 1495, 1338, 1487, 1338]},
 {'id': 542,
  'start_y': 925,
  'start_x': 1488,
  'text': 'U',
  'bbox': [1488, 925, 1496, 925, 1496, 933, 1488, 933]},
 {'id': 461,
  'start_y': 779,
  'start_x': 1489,
  'text': '4',
  'bbox': [1489, 779, 1500, 779, 1500, 791, 1489, 791]},
 {'id': 490,
  'start_y': 815,
  'start_x': 1489,
  'text': '"',
  'bbox': [1489, 815, 1499, 815, 1499, 823, 1489, 823]},
 {'id': 160,
  'start_y': 411,
  'start_x': 1490,
  'text': '10',
  'bbox': [1490, 411, 1509, 411, 1509, 426, 1490, 426]},
 {'id': 517,
  'start_y': 852,
  'start_x': 1490,
  'text': 'U',
  'bbox': [1490, 852, 1498, 852, 1498, 862, 1490, 862]},
 {'id': 821,
  'start_y': 1289,
  'start_x': 1490,
  'text': '"',
  'bbox': [1490, 1289, 1498, 1289, 1498, 1299, 1490, 1299]},
 {'id': 433,
  'start_y': 745,
  'start_x': 1491,
  'text': '"',
  'bbox': [1491, 745, 1500, 745, 1500, 754, 1491, 754]},
 {'id': 69,
  'start_y': 363,
  'start_x': 1492,
  'text': '1',
  'bbox': [1492, 363, 1500, 363, 1500, 371, 1492, 371]},
 {'id': 65,
  'start_y': 318,
  'start_x': 1500,
  'text': 'TYPE',
  'bbox': [1500, 318, 1533, 318, 1533, 330, 1500, 330]},
 {'id': 161,
  'start_y': 411,
  'start_x': 1506,
  'text': ')',
  'bbox': [1506, 411, 1513, 411, 1513, 426, 1506, 426]},
 {'id': 879,
  'start_y': 1375,
  'start_x': 1509,
  'text': 'first',
  'bbox': [1509, 1375, 1535, 1375, 1535, 1393, 1509, 1393]},
 {'id': 67,
  'start_y': 335,
  'start_x': 1510,
  'text': 'BIRTH',
  'bbox': [1510, 335, 1546, 335, 1546, 344, 1510, 344]},
 {'id': 533,
  'start_y': 885,
  'start_x': 1517,
  'text': 'ord',
  'bbox': [1519, 882, 1561, 885, 1559, 909, 1517, 906]},
 {'id': 491,
  'start_y': 808,
  'start_x': 1519,
  'text': '15th',
  'bbox': [1520, 807, 1571, 808, 1570, 839, 1519, 838]},
 {'id': 650,
  'start_y': 1027,
  'start_x': 1519,
  'text': '2nd',
  'bbox': [1519, 1028, 1561, 1027, 1561, 1058, 1519, 1059]},
 {'id': 438,
  'start_y': 737,
  'start_x': 1522,
  'text': '11th',
  'bbox': [1522, 737, 1559, 737, 1559, 762, 1522, 762]},
 {'id': 738,
  'start_y': 1175,
  'start_x': 1522,
  'text': '5th',
  'bbox': [1522, 1174, 1566, 1175, 1566, 1206, 1522, 1205]},
 {'id': 822,
  'start_y': 1287,
  'start_x': 1522,
  'text': '2nd',
  'bbox': [1522, 1287, 1561, 1287, 1561, 1308, 1522, 1308]},
 {'id': 242,
  'start_y': 520,
  'start_x': 1523,
  'text': 'gth',
  'bbox': [1523, 520, 1559, 520, 1559, 554, 1523, 554]},
 {'id': 667,
  'start_y': 1103,
  'start_x': 1523,
  'text': '2nd',
  'bbox': [1523, 1103, 1566, 1103, 1566, 1127, 1523, 1127]},
 {'id': 799,
  'start_y': 1247,
  'start_x': 1523,
  'text': 'and',
  'bbox': [1523, 1248, 1562, 1247, 1562, 1280, 1523, 1281]},
 {'id': 399,
  'start_y': 702,
  'start_x': 1525,
  'text': '5th',
  'bbox': [1523, 706, 1557, 702, 1559, 720, 1525, 724]},
 {'id': 462,
  'start_y': 775,
  'start_x': 1525,
  'text': '7th',
  'bbox': [1525, 775, 1562, 775, 1562, 801, 1525, 801]},
 {'id': 593,
  'start_y': 959,
  'start_x': 1525,
  'text': '4th',
  'bbox': [1525, 959, 1565, 959, 1565, 985, 1525, 985]},
 {'id': 833,
  'start_y': 1323,
  'start_x': 1525,
  'text': 'eta',
  'bbox': [1524, 1325, 1555, 1323, 1556, 1343, 1525, 1345]},
 {'id': 547,
  'start_y': 920,
  'start_x': 1526,
  'text': 'Nt',
  'bbox': [1526, 920, 1555, 920, 1555, 943, 1526, 943]},
 {'id': 597,
  'start_y': 994,
  'start_x': 1527,
  'text': 'at',
  'bbox': [1527, 994, 1557, 994, 1557, 1016, 1527, 1016]},
 {'id': 661,
  'start_y': 1065,
  'start_x': 1527,
  'text': 'sst',
  'bbox': [1527, 1065, 1557, 1065, 1557, 1096, 1527, 1096]},
 {'id': 375,
  'start_y': 666,
  'start_x': 1528,
  'text': '2nd',
  'bbox': [1528, 666, 1568, 666, 1568, 688, 1528, 688]},
 {'id': 518,
  'start_y': 845,
  'start_x': 1528,
  'text': '4th',
  'bbox': [1529, 844, 1562, 845, 1561, 872, 1528, 871]},
 {'id': 227,
  'start_y': 440,
  'start_x': 1530,
  'text': '8th',
  'bbox': [1529, 441, 1567, 440, 1568, 470, 1530, 471]},
 {'id': 249,
  'start_y': 558,
  'start_x': 1530,
  'text': '0th',
  'bbox': [1530, 558, 1565, 558, 1565, 582, 1530, 582]},
 {'id': 338,
  'start_y': 630,
  'start_x': 1531,
  'text': '7th',
  'bbox': [1531, 630, 1568, 630, 1568, 654, 1531, 654]},
 {'id': 79,
  'start_y': 377,
  'start_x': 1533,
  'text': 'Order',
  'bbox': [1533, 377, 1567, 377, 1567, 387, 1533, 387]},
 {'id': 336,
  'start_y': 595,
  'start_x': 1533,
  'text': '#h',
  'bbox': [1532, 597, 1567, 595, 1568, 614, 1533, 616]},
 {'id': 66,
  'start_y': 318,
  'start_x': 1535,
  'text': 'OF',
  'bbox': [1535, 318, 1553, 318, 1553, 329, 1535, 329]},
 {'id': 880,
  'start_y': 1375,
  'start_x': 1535,
  'text': ',',
  'bbox': [1535, 1375, 1540, 1375, 1540, 1393, 1535, 1393]},
 {'id': 162,
  'start_y': 411,
  'start_x': 1537,
  'text': '(',
  'bbox': [1537, 411, 1544, 411, 1544, 426, 1537, 426]},
 {'id': 233,
  'start_y': 480,
  'start_x': 1538,
  'text': 'st',
  'bbox': [1538, 480, 1562, 480, 1562, 506, 1538, 506]},
 {'id': 163,
  'start_y': 411,
  'start_x': 1544,
  'text': '11',
  'bbox': [1544, 411, 1560, 411, 1560, 426, 1544, 426]},
 {'id': 881,
  'start_y': 1375,
  'start_x': 1544,
  'text': 'second',
  'bbox': [1544, 1375, 1595, 1375, 1595, 1393, 1544, 1393]},
 {'id': 10,
  'start_y': 246,
  'start_x': 1546,
  'text': 'REGISTER',
  'bbox': [1546, 247, 1738, 246, 1738, 277, 1546, 278]},
 {'id': 70,
  'start_y': 364,
  'start_x': 1546,
  'text': '2',
  'bbox': [1546, 364, 1554, 364, 1554, 372, 1546, 372]},
 {'id': 164,
  'start_y': 411,
  'start_x': 1559,
  'text': ')',
  'bbox': [1559, 411, 1565, 411, 1565, 426, 1559, 426]},
 {'id': 709,
  'start_y': 1041,
  'start_x': 1572,
  'text': 'IN',
  'bbox': [1572, 1041, 1589, 1041, 1589, 1053, 1572, 1053]},
 {'id': 250,
  'start_y': 558,
  'start_x': 1581,
  'text': 'Sofa',
  'bbox': [1581, 558, 1666, 558, 1666, 582, 1581, 582]},
 {'id': 492,
  'start_y': 811,
  'start_x': 1583,
  'text': 'Felicision',
  'bbox': [1584, 809, 1667, 811, 1666, 842, 1583, 840]},
 {'id': 234,
  'start_y': 482,
  'start_x': 1584,
  'text': 'Justina',
  'bbox': [1585, 480, 1669, 482, 1668, 509, 1584, 507]},
 {'id': 594,
  'start_y': 959,
  'start_x': 1584,
  'text': 'Natividad',
  'bbox': [1584, 959, 1691, 959, 1691, 985, 1584, 985]},
 {'id': 834,
  'start_y': 1320,
  'start_x': 1584,
  'text': 'Elisabeth',
  'bbox': [1584, 1322, 1679, 1320, 1679, 1343, 1584, 1345]},
 {'id': 519,
  'start_y': 849,
  'start_x': 1585,
  'text': 'Tarcela',
  'bbox': [1586, 846, 1658, 849, 1657, 876, 1585, 873]},
 {'id': 548,
  'start_y': 920,
  'start_x': 1585,
  'text': 'Amalia',
  'bbox': [1585, 920, 1663, 920, 1663, 943, 1585, 943]},
 {'id': 243,
  'start_y': 520,
  'start_x': 1586,
  'text': 'froifana',
  'bbox': [1586, 520, 1669, 520, 1669, 554, 1586, 554]},
 {'id': 823,
  'start_y': 1287,
  'start_x': 1586,
  'text': 'Teresa',
  'bbox': [1586, 1287, 1658, 1287, 1658, 1307, 1586, 1307]},
 {'id': 543,
  'start_y': 883,
  'start_x': 1587,
  'text': 'Maximin',
  'bbox': [1587, 884, 1666, 883, 1666, 913, 1587, 914]},
 {'id': 598,
  'start_y': 994,
  'start_x': 1587,
  'text': 'beritaja',
  'bbox': [1587, 994, 1690, 994, 1690, 1016, 1587, 1016]},
 {'id': 228,
  'start_y': 438,
  'start_x': 1588,
  'text': 'Maria',
  'bbox': [1587, 440, 1651, 438, 1652, 467, 1588, 469]},
 {'id': 434,
  'start_y': 700,
  'start_x': 1589,
  'text': 'Bonita',
  'bbox': [1589, 701, 1650, 700, 1650, 724, 1589, 725]},
 {'id': 662,
  'start_y': 1065,
  'start_x': 1589,
  'text': 'Virginia',
  'bbox': [1589, 1065, 1677, 1065, 1677, 1096, 1589, 1096]},
 {'id': 439,
  'start_y': 738,
  'start_x': 1590,
  'text': 'Ercolastica',
  'bbox': [1590, 737, 1710, 738, 1710, 763, 1590, 762]},
 {'id': 463,
  'start_y': 776,
  'start_x': 1590,
  'text': 'Ubaldo',
  'bbox': [1590, 775, 1663, 776, 1663, 803, 1590, 802]},
 {'id': 337,
  'start_y': 591,
  'start_x': 1591,
  'text': 'Lea',
  'bbox': [1590, 593, 1626, 591, 1627, 611, 1591, 613]},
 {'id': 376,
  'start_y': 667,
  'start_x': 1591,
  'text': 'Felonina',
  'bbox': [1591, 666, 1670, 667, 1670, 690, 1591, 689]},
 {'id': 668,
  'start_y': 1103,
  'start_x': 1591,
  'text': 'Erlinda',
  'bbox': [1591, 1103, 1668, 1103, 1668, 1127, 1591, 1127]},
 {'id': 674,
  'start_y': 1138,
  'start_x': 1591,
  'text': 'victoria',
  'bbox': [1591, 1138, 1680, 1138, 1680, 1162, 1591, 1162]},
 {'id': 339,
  'start_y': 631,
  'start_x': 1592,
  'text': 'Rosita',
  'bbox': [1592, 630, 1659, 631, 1659, 655, 1592, 654]},
 {'id': 800,
  'start_y': 1246,
  'start_x': 1593,
  'text': 'Gerania',
  'bbox': [1593, 1247, 1682, 1246, 1682, 1280, 1593, 1281]},
 {'id': 739,
  'start_y': 1175,
  'start_x': 1594,
  'text': 'Candelaria',
  'bbox': [1594, 1174, 1701, 1175, 1701, 1207, 1594, 1206]},
 {'id': 651,
  'start_y': 1026,
  'start_x': 1596,
  'text': 'Anacarita',
  'bbox': [1596, 1027, 1697, 1026, 1697, 1057, 1596, 1058]},
 {'id': 743,
  'start_y': 1214,
  'start_x': 1596,
  'text': 'Erlinda',
  'bbox': [1596, 1214, 1683, 1214, 1683, 1237, 1596, 1237]},
 {'id': 882,
  'start_y': 1375,
  'start_x': 1597,
  'text': ',',
  'bbox': [1597, 1375, 1602, 1375, 1602, 1393, 1597, 1393]},
 {'id': 883,
  'start_y': 1375,
  'start_x': 1604,
  'text': 'or',
  'bbox': [1604, 1375, 1623, 1375, 1623, 1393, 1604, 1393]},
 {'id': 884,
  'start_y': 1375,
  'start_x': 1622,
  'text': 'higher',
  'bbox': [1622, 1375, 1672, 1375, 1672, 1393, 1622, 1393]},
 {'id': 80,
  'start_y': 378,
  'start_x': 1624,
  'text': '(',
  'bbox': [1624, 378, 1631, 378, 1631, 390, 1624, 390]},
 {'id': 81,
  'start_y': 378,
  'start_x': 1627,
  'text': 'First',
  'bbox': [1627, 378, 1654, 378, 1654, 390, 1627, 390]},
 {'id': 71,
  'start_y': 360,
  'start_x': 1652,
  'text': 'Maiden',
  'bbox': [1652, 361, 1693, 360, 1693, 373, 1652, 374]},
 {'id': 885,
  'start_y': 1375,
  'start_x': 1672,
  'text': 'order',
  'bbox': [1672, 1375, 1714, 1375, 1714, 1393, 1672, 1393]},
 {'id': 340,
  'start_y': 631,
  'start_x': 1678,
  'text': 'E.',
  'bbox': [1678, 631, 1707, 631, 1707, 655, 1678, 655]},
 {'id': 435,
  'start_y': 701,
  'start_x': 1678,
  'text': 'A.',
  'bbox': [1678, 701, 1706, 701, 1706, 724, 1678, 724]},
 {'id': 520,
  'start_y': 851,
  'start_x': 1678,
  'text': 'C.',
  'bbox': [1679, 850, 1708, 851, 1707, 878, 1678, 877]},
 {'id': 544,
  'start_y': 884,
  'start_x': 1679,
  'text': 'v',
  'bbox': [1679, 884, 1702, 884, 1702, 913, 1679, 913]},
 {'id': 229,
  'start_y': 447,
  'start_x': 1680,
  'text': 'T-',
  'bbox': [1680, 447, 1708, 447, 1708, 473, 1680, 473]},
 {'id': 235,
  'start_y': 483,
  'start_x': 1680,
  'text': 'R.',
  'bbox': [1681, 482, 1717, 483, 1716, 510, 1680, 509]},
 {'id': 464,
  'start_y': 776,
  'start_x': 1682,
  'text': 'C.',
  'bbox': [1682, 776, 1713, 776, 1713, 803, 1682, 803]},
 {'id': 251,
  'start_y': 558,
  'start_x': 1684,
  'text': 'C.',
  'bbox': [1684, 558, 1712, 558, 1712, 582, 1684, 582]},
 {'id': 549,
  'start_y': 920,
  'start_x': 1684,
  'text': 'M.',
  'bbox': [1684, 920, 1713, 920, 1713, 943, 1684, 943]},
 {'id': 669,
  'start_y': 1103,
  'start_x': 1684,
  'text': 'S.',
  'bbox': [1684, 1103, 1715, 1103, 1715, 1127, 1684, 1127]},
 {'id': 244,
  'start_y': 520,
  'start_x': 1685,
  'text': 'I.',
  'bbox': [1685, 520, 1714, 520, 1714, 554, 1685, 554]},
 {'id': 377,
  'start_y': 667,
  'start_x': 1685,
  'text': 'B.',
  'bbox': [1685, 667, 1714, 667, 1714, 689, 1685, 689]},
 {'id': 493,
  'start_y': 813,
  'start_x': 1685,
  'text': 'M.',
  'bbox': [1686, 812, 1719, 813, 1718, 843, 1685, 842]},
 {'id': 663,
  'start_y': 1065,
  'start_x': 1687,
  'text': 'M.',
  'bbox': [1687, 1065, 1724, 1065, 1724, 1096, 1687, 1096]},
 {'id': 255,
  'start_y': 409,
  'start_x': 1689,
  'text': '(',
  'bbox': [1689, 409, 1696, 409, 1696, 424, 1689, 424]},
 {'id': 824,
  'start_y': 1285,
  'start_x': 1690,
  'text': 'Gallamos',
  'bbox': [1690, 1284, 1786, 1285, 1786, 1307, 1690, 1306]},
 {'id': 545,
  'start_y': 884,
  'start_x': 1692,
  'text': '.',
  'bbox': [1692, 884, 1712, 884, 1712, 913, 1692, 913]},
 {'id': 256,
  'start_y': 409,
  'start_x': 1695,
  'text': '12',
  'bbox': [1695, 409, 1712, 409, 1712, 424, 1695, 424]},
 {'id': 835,
  'start_y': 1319,
  'start_x': 1696,
  'text': 'M.',
  'bbox': [1696, 1320, 1731, 1319, 1731, 1341, 1696, 1342]},
 {'id': 72,
  'start_y': 359,
  'start_x': 1697,
  'text': 'Name',
  'bbox': [1697, 360, 1733, 359, 1733, 372, 1697, 373]},
 {'id': 801,
  'start_y': 1244,
  'start_x': 1702,
  'text': 'Natividad',
  'bbox': [1702, 1245, 1796, 1244, 1796, 1278, 1702, 1279]},
 {'id': 599,
  'start_y': 994,
  'start_x': 1703,
  'text': 'Juan',
  'bbox': [1703, 994, 1776, 994, 1776, 1016, 1703, 1016]},
 {'id': 675,
  'start_y': 1137,
  'start_x': 1703,
  'text': 'Por',
  'bbox': [1703, 1137, 1743, 1137, 1743, 1161, 1703, 1161]},
 {'id': 254,
  'start_y': 595,
  'start_x': 1706,
  'text': 'Jan',
  'bbox': [1707, 594, 1752, 595, 1751, 618, 1706, 617]},
 {'id': 744,
  'start_y': 1214,
  'start_x': 1708,
  'text': 'k',
  'bbox': [1708, 1214, 1728, 1214, 1728, 1237, 1708, 1237]},
 {'id': 257,
  'start_y': 409,
  'start_x': 1710,
  'text': ')',
  'bbox': [1710, 409, 1717, 409, 1717, 424, 1710, 424]},
 {'id': 595,
  'start_y': 959,
  'start_x': 1710,
  'text': 'B.',
  'bbox': [1710, 959, 1746, 959, 1746, 985, 1710, 985]},
 {'id': 436,
  'start_y': 699,
  'start_x': 1712,
  'text': 'Caminado',
  'bbox': [1712, 700, 1795, 699, 1795, 723, 1712, 724]},
 {'id': 886,
  'start_y': 1375,
  'start_x': 1712,
  'text': '?',
  'bbox': [1712, 1375, 1722, 1375, 1722, 1393, 1712, 1393]},
 {'id': 521,
  'start_y': 855,
  'start_x': 1713,
  'text': 'Vaguisa',
  'bbox': [1714, 852, 1785, 855, 1784, 881, 1713, 878]},
 {'id': 652,
  'start_y': 1025,
  'start_x': 1713,
  'text': 'Andrade',
  'bbox': [1713, 1026, 1791, 1025, 1791, 1056, 1713, 1057]},
 {'id': 230,
  'start_y': 446,
  'start_x': 1714,
  'text': 'Tinapan',
  'bbox': [1714, 447, 1791, 446, 1791, 471, 1714, 472]},
 {'id': 341,
  'start_y': 631,
  'start_x': 1714,
  'text': 'Benjero',
  'bbox': [1714, 631, 1776, 631, 1776, 655, 1714, 655]},
 {'id': 465,
  'start_y': 777,
  'start_x': 1715,
  'text': 'Egcarana',
  'bbox': [1715, 776, 1801, 777, 1801, 804, 1715, 803]},
 {'id': 236,
  'start_y': 484,
  'start_x': 1716,
  'text': 'Regis',
  'bbox': [1716, 483, 1768, 484, 1767, 510, 1716, 509]},
 {'id': 378,
  'start_y': 668,
  'start_x': 1716,
  'text': 'Mando',
  'bbox': [1716, 667, 1781, 668, 1781, 691, 1716, 690]},
 {'id': 740,
  'start_y': 1177,
  'start_x': 1716,
  'text': 'Amago',
  'bbox': [1716, 1176, 1791, 1177, 1791, 1208, 1716, 1207]},
 {'id': 546,
  'start_y': 883,
  'start_x': 1719,
  'text': 'Sampito',
  'bbox': [1719, 883, 1788, 883, 1788, 913, 1719, 913]},
 {'id': 440,
  'start_y': 737,
  'start_x': 1720,
  'text': 'A.',
  'bbox': [1720, 737, 1749, 737, 1749, 762, 1720, 762]},
 {'id': 670,
  'start_y': 1104,
  'start_x': 1720,
  'text': 'Camining',
  'bbox': [1720, 1104, 1804, 1104, 1804, 1128, 1720, 1128]},
 {'id': 245,
  'start_y': 520,
  'start_x': 1721,
  'text': 'Mañego',
  'bbox': [1721, 520, 1794, 520, 1794, 554, 1721, 554]},
 {'id': 252,
  'start_y': 558,
  'start_x': 1723,
  'text': 'Vason',
  'bbox': [1723, 558, 1780, 558, 1780, 582, 1723, 582]},
 {'id': 494,
  'start_y': 814,
  'start_x': 1723,
  'text': 'Sabojon',
  'bbox': [1724, 812, 1794, 814, 1793, 845, 1723, 843]},
 {'id': 664,
  'start_y': 1065,
  'start_x': 1729,
  'text': 'Rrition',
  'bbox': [1729, 1065, 1788, 1065, 1788, 1096, 1729, 1096]},
 {'id': 550,
  'start_y': 920,
  'start_x': 1730,
  'text': 'Obias',
  'bbox': [1730, 920, 1781, 920, 1781, 943, 1730, 943]},
 {'id': 82,
  'start_y': 377,
  'start_x': 1733,
  'text': 'Last',
  'bbox': [1733, 377, 1757, 377, 1757, 387, 1733, 387]},
 {'id': 836,
  'start_y': 1318,
  'start_x': 1733,
  'text': 'Cordua',
  'bbox': [1733, 1319, 1800, 1318, 1800, 1341, 1733, 1342]},
 {'id': 68,
  'start_y': 325,
  'start_x': 1735,
  'text': 'MOT',
  'bbox': [1735, 323, 1792, 325, 1792, 338, 1735, 337]},
 {'id': 596,
  'start_y': 959,
  'start_x': 1736,
  'text': 'Regis',
  'bbox': [1736, 959, 1792, 959, 1792, 985, 1736, 985]},
 {'id': 676,
  'start_y': 1137,
  'start_x': 1743,
  'text': 'las',
  'bbox': [1743, 1137, 1779, 1137, 1779, 1161, 1743, 1161]},
 {'id': 441,
  'start_y': 737,
  'start_x': 1748,
  'text': 'Poetin',
  'bbox': [1748, 737, 1803, 737, 1803, 763, 1748, 763]},
 {'id': 11,
  'start_y': 246,
  'start_x': 1750,
  'text': 'OF',
  'bbox': [1750, 246, 1796, 246, 1796, 276, 1750, 276]},
 {'id': 83,
  'start_y': 377,
  'start_x': 1755,
  'text': ')',
  'bbox': [1755, 377, 1759, 377, 1759, 387, 1755, 387]},
 {'id': 84,
  'start_y': 371,
  'start_x': 1781,
  'text': '10',
  'bbox': [1781, 371, 1796, 371, 1796, 383, 1781, 383]},
 {'id': 653,
  'start_y': 1025,
  'start_x': 1785,
  'text': ':',
  'bbox': [1785, 1025, 1796, 1025, 1796, 1055, 1785, 1055]},
 {'id': 85,
  'start_y': 371,
  'start_x': 1813,
  'text': 'Age',
  'bbox': [1813, 371, 1839, 371, 1839, 382, 1813, 382]},
 {'id': 231,
  'start_y': 446,
  'start_x': 1814,
  'text': '39',
  'bbox': [1814, 446, 1842, 446, 1842, 471, 1814, 471]},
 {'id': 12,
  'start_y': 246,
  'start_x': 1815,
  'text': 'LIVE',
  'bbox': [1815, 246, 1907, 246, 1907, 276, 1815, 276]},
 {'id': 317,
  'start_y': 520,
  'start_x': 1816,
  'text': '36',
  'bbox': [1816, 520, 1847, 520, 1847, 554, 1816, 554]},
 {'id': 218,
  'start_y': 410,
  'start_x': 1817,
  'text': '(',
  'bbox': [1817, 410, 1825, 410, 1825, 427, 1817, 427]},
 {'id': 237,
  'start_y': 486,
  'start_x': 1817,
  'text': '37',
  'bbox': [1817, 485, 1849, 486, 1849, 512, 1817, 511]},
 {'id': 327,
  'start_y': 558,
  'start_x': 1817,
  'text': '37',
  'bbox': [1817, 558, 1849, 558, 1849, 582, 1817, 582]},
 {'id': 442,
  'start_y': 738,
  'start_x': 1820,
  'text': '27',
  'bbox': [1820, 738, 1852, 738, 1852, 763, 1820, 763]},
 {'id': 495,
  'start_y': 816,
  'start_x': 1820,
  'text': '35',
  'bbox': [1821, 815, 1853, 816, 1852, 846, 1820, 845]},
 {'id': 466,
  'start_y': 778,
  'start_x': 1821,
  'text': '24',
  'bbox': [1821, 778, 1848, 778, 1848, 804, 1821, 804]},
 {'id': 219,
  'start_y': 409,
  'start_x': 1822,
  'text': '13',
  'bbox': [1822, 409, 1838, 409, 1838, 426, 1822, 426]},
 {'id': 437,
  'start_y': 699,
  'start_x': 1822,
  'text': '25',
  'bbox': [1822, 699, 1850, 699, 1850, 722, 1822, 722]},
 {'id': 665,
  'start_y': 1065,
  'start_x': 1822,
  'text': '28',
  'bbox': [1822, 1065, 1853, 1065, 1853, 1096, 1822, 1096]},
 {'id': 671,
  'start_y': 1104,
  'start_x': 1823,
  'text': '21',
  'bbox': [1823, 1104, 1852, 1104, 1852, 1128, 1823, 1128]},
 {'id': 600,
  'start_y': 959,
  'start_x': 1824,
  'text': '24',
  'bbox': [1824, 959, 1852, 959, 1852, 985, 1824, 985]},
 {'id': 342,
  'start_y': 592,
  'start_x': 1825,
  'text': '25',
  'bbox': [1824, 593, 1849, 592, 1850, 609, 1825, 610]},
 {'id': 654,
  'start_y': 1025,
  'start_x': 1825,
  'text': '28',
  'bbox': [1825, 1025, 1854, 1025, 1854, 1055, 1825, 1055]},
 {'id': 741,
  'start_y': 1177,
  'start_x': 1825,
  'text': '24',
  'bbox': [1825, 1177, 1857, 1177, 1857, 1208, 1825, 1208]},
 {'id': 802,
  'start_y': 1244,
  'start_x': 1825,
  'text': '40',
  'bbox': [1825, 1244, 1855, 1244, 1855, 1277, 1825, 1277]},
 {'id': 355,
  'start_y': 634,
  'start_x': 1826,
  'text': '34',
  'bbox': [1826, 634, 1848, 634, 1848, 652, 1826, 652]},
 {'id': 551,
  'start_y': 920,
  'start_x': 1826,
  'text': '21',
  'bbox': [1826, 920, 1851, 920, 1851, 943, 1826, 943]},
 {'id': 837,
  'start_y': 1317,
  'start_x': 1826,
  'text': '24',
  'bbox': [1826, 1318, 1858, 1317, 1858, 1339, 1826, 1340]},
 {'id': 523,
  'start_y': 853,
  'start_x': 1827,
  'text': '31',
  'bbox': [1827, 853, 1846, 853, 1846, 866, 1827, 866]},
 {'id': 602,
  'start_y': 994,
  'start_x': 1829,
  'text': '18',
  'bbox': [1829, 994, 1852, 994, 1852, 1016, 1829, 1016]},
 {'id': 86,
  'start_y': 325,
  'start_x': 1830,
  'text': 'THOE',
  'bbox': [1830, 326, 1875, 325, 1875, 339, 1830, 340]},
 {'id': 735,
  'start_y': 1141,
  'start_x': 1830,
  'text': '17',
  'bbox': [1830, 1141, 1851, 1141, 1851, 1158, 1830, 1158]},
 {'id': 758,
  'start_y': 1217,
  'start_x': 1830,
  'text': '27',
  'bbox': [1830, 1217, 1857, 1217, 1857, 1233, 1830, 1233]},
 {'id': 825,
  'start_y': 1286,
  'start_x': 1834,
  'text': '21',
  'bbox': [1834, 1286, 1852, 1286, 1852, 1301, 1834, 1301]},
 {'id': 220,
  'start_y': 409,
  'start_x': 1837,
  'text': ')',
  'bbox': [1837, 409, 1844, 409, 1844, 426, 1837, 426]},
 {'id': 88,
  'start_y': 363,
  'start_x': 1855,
  'text': 'Nations',
  'bbox': [1856, 359, 1904, 363, 1903, 378, 1855, 374]},
 {'id': 232,
  'start_y': 446,
  'start_x': 1855,
  'text': 'Filipin',
  'bbox': [1855, 446, 1906, 446, 1906, 471, 1855, 471]},
 {'id': 221,
  'start_y': 408,
  'start_x': 1867,
  'text': '(',
  'bbox': [1867, 408, 1875, 408, 1875, 425, 1867, 425]},
 {'id': 90,
  'start_y': 376,
  'start_x': 1869,
  'text': 'lity',
  'bbox': [1869, 376, 1889, 376, 1889, 389, 1869, 389]},
 {'id': 222,
  'start_y': 408,
  'start_x': 1873,
  'text': '14',
  'bbox': [1873, 408, 1890, 408, 1890, 425, 1873, 425]},
 {'id': 328,
  'start_y': 560,
  'start_x': 1875,
  'text': '"',
  'bbox': [1875, 560, 1884, 560, 1884, 570, 1875, 570]},
 {'id': 277,
  'start_y': 484,
  'start_x': 1878,
  'text': 'al',
  'bbox': [1878, 484, 1887, 484, 1887, 494, 1878, 494]},
 {'id': 356,
  'start_y': 631,
  'start_x': 1880,
  'text': '51',
  'bbox': [1880, 631, 1889, 631, 1889, 642, 1880, 642]},
 {'id': 379,
  'start_y': 667,
  'start_x': 1882,
  'text': '1',
  'bbox': [1882, 667, 1889, 667, 1889, 677, 1882, 677]},
 {'id': 443,
  'start_y': 708,
  'start_x': 1882,
  'text': '"',
  'bbox': [1882, 708, 1889, 708, 1889, 714, 1882, 714]},
 {'id': 683,
  'start_y': 1031,
  'start_x': 1882,
  'text': '.',
  'bbox': [1882, 1031, 1891, 1031, 1891, 1040, 1882, 1040]},
 {'id': 453,
  'start_y': 740,
  'start_x': 1883,
  'text': '"',
  'bbox': [1883, 740, 1893, 740, 1893, 750, 1883, 750]},
 {'id': 496,
  'start_y': 817,
  'start_x': 1883,
  'text': '"',
  'bbox': [1883, 817, 1892, 817, 1892, 824, 1883, 824]},
 {'id': 467,
  'start_y': 775,
  'start_x': 1885,
  'text': 'al',
  'bbox': [1885, 775, 1894, 775, 1894, 784, 1885, 784]},
 {'id': 603,
  'start_y': 998,
  'start_x': 1885,
  'text': '"',
  'bbox': [1885, 998, 1894, 998, 1894, 1006, 1885, 1006]},
 {'id': 87,
  'start_y': 325,
  'start_x': 1886,
  'text': 'R',
  'bbox': [1886, 325, 1897, 325, 1897, 338, 1886, 338]},
 {'id': 522,
  'start_y': 850,
  'start_x': 1886,
  'text': '0',
  'bbox': [1886, 850, 1893, 850, 1893, 857, 1886, 857]},
 {'id': 601,
  'start_y': 959,
  'start_x': 1887,
  'text': '60',
  'bbox': [1887, 959, 1894, 959, 1894, 967, 1887, 967]},
 {'id': 724,
  'start_y': 1103,
  'start_x': 1887,
  'text': 'n',
  'bbox': [1887, 1103, 1895, 1103, 1895, 1112, 1887, 1112]},
 {'id': 736,
  'start_y': 1139,
  'start_x': 1887,
  'text': '"',
  'bbox': [1887, 1139, 1895, 1139, 1895, 1150, 1887, 1150]},
 {'id': 223,
  'start_y': 408,
  'start_x': 1888,
  'text': ')',
  'bbox': [1888, 408, 1896, 408, 1896, 425, 1888, 425]},
 {'id': 552,
  'start_y': 889,
  'start_x': 1888,
  'text': '"',
  'bbox': [1888, 889, 1895, 889, 1895, 896, 1888, 896]},
 {'id': 826,
  'start_y': 1287,
  'start_x': 1888,
  'text': '"',
  'bbox': [1888, 1287, 1895, 1287, 1895, 1296, 1888, 1296]},
 {'id': 745,
  'start_y': 1178,
  'start_x': 1889,
  'text': '.',
  'bbox': [1889, 1178, 1896, 1178, 1896, 1186, 1889, 1186]},
 {'id': 759,
  'start_y': 1218,
  'start_x': 1889,
  'text': '"',
  'bbox': [1889, 1218, 1896, 1218, 1896, 1224, 1889, 1224]},
 {'id': 803,
  'start_y': 1250,
  'start_x': 1889,
  'text': '"',
  'bbox': [1889, 1250, 1899, 1250, 1899, 1261, 1889, 1261]},
 {'id': 838,
  'start_y': 1322,
  'start_x': 1891,
  'text': '4',
  'bbox': [1891, 1322, 1900, 1322, 1900, 1332, 1891, 1332]},
 {'id': 89,
  'start_y': 368,
  'start_x': 1911,
  'text': 'Religion',
  'bbox': [1912, 364, 1961, 368, 1960, 382, 1911, 378]},
 {'id': 13,
  'start_y': 244,
  'start_x': 1915,
  'text': 'BIRTHLA',
  'bbox': [1915, 245, 2076, 244, 2076, 275, 1915, 276]},
 {'id': 894,
  'start_y': 1368,
  'start_x': 1915,
  'text': 'Sisbio',
  'bbox': [1915, 1369, 1962, 1368, 1962, 1385, 1915, 1386]},
 {'id': 258,
  'start_y': 409,
  'start_x': 1927,
  'text': '(',
  'bbox': [1927, 409, 1933, 409, 1933, 424, 1927, 424]},
 {'id': 259,
  'start_y': 409,
  'start_x': 1933,
  'text': '15',
  'bbox': [1933, 409, 1950, 409, 1950, 424, 1933, 424]},
 {'id': 260,
  'start_y': 409,
  'start_x': 1947,
  'text': ')',
  'bbox': [1947, 409, 1953, 409, 1953, 424, 1947, 424]},
 {'id': 895,
  'start_y': 1367,
  'start_x': 1971,
  'text': 'aripiri',
  'bbox': [1971, 1368, 2013, 1367, 2013, 1384, 1971, 1385]},
 {'id': 279,
  'start_y': 440,
  'start_x': 1978,
  'text': 'Santiago',
  'bbox': [1977, 445, 2066, 440, 2067, 467, 1978, 472]},
 {'id': 278,
  'start_y': 482,
  'start_x': 1985,
  'text': 'Neceporo',
  'bbox': [1986, 480, 2065, 482, 2064, 507, 1985, 505]},
 {'id': 329,
  'start_y': 553,
  'start_x': 1985,
  'text': 'Juamto',
  'bbox': [1985, 553, 2065, 553, 2065, 574, 1985, 574]},
 {'id': 444,
  'start_y': 705,
  'start_x': 1985,
  'text': 'Felipe',
  'bbox': [1986, 703, 2042, 705, 2041, 731, 1985, 729]},
 {'id': 380,
  'start_y': 665,
  'start_x': 1988,
  'text': 'Felimon',
  'bbox': [1988, 664, 2048, 665, 2048, 687, 1988, 686]},
 {'id': 318,
  'start_y': 515,
  'start_x': 1989,
  'text': 'Martial',
  'bbox': [1990, 513, 2062, 515, 2061, 538, 1989, 536]},
 {'id': 468,
  'start_y': 772,
  'start_x': 1989,
  'text': 'Aurelio',
  'bbox': [1989, 771, 2063, 772, 2063, 791, 1989, 790]},
 {'id': 357,
  'start_y': 629,
  'start_x': 1990,
  'text': 'Hilario',
  'bbox': [1991, 627, 2057, 629, 2056, 650, 1990, 648]},
 {'id': 343,
  'start_y': 592,
  'start_x': 1991,
  'text': 'Federico',
  'bbox': [1992, 588, 2075, 592, 2074, 610, 1991, 606]},
 {'id': 454,
  'start_y': 739,
  'start_x': 1991,
  'text': 'Pedro',
  'bbox': [1991, 738, 2044, 739, 2043, 758, 1991, 757]},
 {'id': 497,
  'start_y': 809,
  'start_x': 1997,
  'text': 'Pablo',
  'bbox': [1996, 811, 2048, 809, 2049, 829, 1997, 831]},
 {'id': 524,
  'start_y': 842,
  'start_x': 1997,
  'text': 'Gregorio',
  'bbox': [1996, 846, 2076, 842, 2077, 869, 1997, 873]},
 {'id': 107,
  'start_y': 377,
  'start_x': 1999,
  'text': '(',
  'bbox': [1999, 377, 2005, 377, 2005, 388, 1999, 388]},
 {'id': 686,
  'start_y': 952,
  'start_x': 1999,
  'text': 'Ricardo',
  'bbox': [1998, 955, 2081, 952, 2082, 974, 1999, 977]},
 {'id': 108,
  'start_y': 376,
  'start_x': 2002,
  'text': 'First',
  'bbox': [2002, 376, 2027, 376, 2027, 387, 2002, 387]},
 {'id': 684,
  'start_y': 918,
  'start_x': 2002,
  'text': 'Prudencio',
  'bbox': [2003, 914, 2105, 918, 2104, 942, 2002, 938]},
 {'id': 710,
  'start_y': 1059,
  'start_x': 2002,
  'text': 'Clarencio',
  'bbox': [2002, 1060, 2098, 1059, 2098, 1079, 2002, 1080]},
 {'id': 690,
  'start_y': 990,
  'start_x': 2003,
  'text': 'Antonio',
  'bbox': [2003, 992, 2071, 990, 2071, 1007, 2003, 1009]},
 {'id': 553,
  'start_y': 883,
  'start_x': 2004,
  'text': 'kang',
  'bbox': [2004, 883, 2054, 883, 2054, 910, 2004, 910]},
 {'id': 691,
  'start_y': 1027,
  'start_x': 2005,
  'text': 'luis',
  'bbox': [2005, 1027, 2043, 1027, 2043, 1043, 2005, 1043]},
 {'id': 725,
  'start_y': 1097,
  'start_x': 2006,
  'text': 'Restituto',
  'bbox': [2006, 1099, 2091, 1097, 2091, 1117, 2006, 1119]},
 {'id': 737,
  'start_y': 1137,
  'start_x': 2008,
  'text': 'Zacarias',
  'bbox': [2008, 1137, 2093, 1137, 2093, 1155, 2008, 1155]},
 {'id': 746,
  'start_y': 1171,
  'start_x': 2010,
  'text': 'Feliciano',
  'bbox': [2010, 1171, 2091, 1171, 2091, 1194, 2010, 1194]},
 {'id': 827,
  'start_y': 1283,
  'start_x': 2010,
  'text': 'Alfonso',
  'bbox': [2010, 1283, 2087, 1283, 2087, 1304, 2010, 1304]},
 {'id': 839,
  'start_y': 1313,
  'start_x': 2010,
  'text': 'Exequiel',
  'bbox': [2009, 1316, 2104, 1313, 2105, 1341, 2010, 1344]},
 {'id': 804,
  'start_y': 1243,
  'start_x': 2012,
  'text': 'Nemesio',
  'bbox': [2012, 1245, 2083, 1243, 2083, 1261, 2012, 1263]},
 {'id': 760,
  'start_y': 1209,
  'start_x': 2014,
  'text': 'Lino',
  'bbox': [2014, 1209, 2051, 1209, 2051, 1227, 2014, 1227]},
 {'id': 896,
  'start_y': 1367,
  'start_x': 2020,
  'text': 'o',
  'bbox': [2020, 1367, 2030, 1367, 2030, 1383, 2020, 1383]},
 {'id': 897,
  'start_y': 1365,
  'start_x': 2037,
  'text': 'broosa',
  'bbox': [2037, 1366, 2091, 1365, 2091, 1382, 2037, 1383]},
 {'id': 261,
  'start_y': 409,
  'start_x': 2059,
  'text': '(',
  'bbox': [2058, 410, 2066, 409, 2067, 423, 2059, 424]},
 {'id': 262,
  'start_y': 407,
  'start_x': 2064,
  'text': 'tr',
  'bbox': [2063, 409, 2082, 407, 2083, 421, 2064, 423]},
 {'id': 91,
  'start_y': 317,
  'start_x': 2071,
  'text': 'RO',
  'bbox': [2071, 317, 2089, 317, 2089, 327, 2071, 327]},
 {'id': 103,
  'start_y': 361,
  'start_x': 2071,
  'text': '0',
  'bbox': [2071, 361, 2077, 361, 2077, 370, 2071, 370]},
 {'id': 93,
  'start_y': 332,
  'start_x': 2077,
  'text': 'HTRB',
  'bbox': [2077, 332, 2114, 332, 2114, 342, 2077, 342]},
 {'id': 263,
  'start_y': 406,
  'start_x': 2078,
  'text': ')',
  'bbox': [2077, 407, 2087, 406, 2088, 420, 2078, 421]},
 {'id': 109,
  'start_y': 375,
  'start_x': 2080,
  'text': 'C',
  'bbox': [2080, 375, 2089, 375, 2089, 385, 2080, 385]},
 {'id': 280,
  'start_y': 438,
  'start_x': 2085,
  'text': 'R.',
  'bbox': [2084, 440, 2119, 438, 2120, 464, 2085, 466]},
 {'id': 330,
  'start_y': 547,
  'start_x': 2088,
  'text': 'Trillos',
  'bbox': [2087, 548, 2147, 547, 2148, 570, 2088, 571]},
 {'id': 92,
  'start_y': 317,
  'start_x': 2089,
  'text': 'BAYT',
  'bbox': [2089, 317, 2123, 317, 2123, 327, 2089, 327]},
 {'id': 498,
  'start_y': 805,
  'start_x': 2090,
  'text': 'D.',
  'bbox': [2090, 805, 2122, 805, 2122, 830, 2090, 830]},
 {'id': 525,
  'start_y': 839,
  'start_x': 2092,
  'text': 'C.',
  'bbox': [2091, 841, 2124, 839, 2125, 866, 2092, 868]},
 {'id': 282,
  'start_y': 476,
  'start_x': 2093,
  'text': 'L.',
  'bbox': [2093, 476, 2119, 476, 2119, 506, 2093, 506]},
 {'id': 445,
  'start_y': 694,
  'start_x': 2094,
  'text': 'C.',
  'bbox': [2095, 693, 2126, 694, 2125, 719, 2094, 718]},
 {'id': 319,
  'start_y': 513,
  'start_x': 2095,
  'text': 'R.',
  'bbox': [2095, 513, 2121, 513, 2121, 534, 2095, 534]},
 {'id': 381,
  'start_y': 659,
  'start_x': 2095,
  'text': 'C.',
  'bbox': [2096, 658, 2125, 659, 2124, 684, 2095, 683]},
 {'id': 898,
  'start_y': 1364,
  'start_x': 2095,
  'text': 'jah',
  'bbox': [2095, 1365, 2123, 1364, 2123, 1380, 2095, 1381]},
 {'id': 104,
  'start_y': 357,
  'start_x': 2097,
  'text': 'Name',
  'bbox': [2097, 357, 2131, 357, 2131, 366, 2097, 366]},
 {'id': 358,
  'start_y': 625,
  'start_x': 2098,
  'text': 'H.',
  'bbox': [2100, 623, 2137, 625, 2135, 649, 2098, 647]},
 {'id': 455,
  'start_y': 734,
  'start_x': 2098,
  'text': 'G.',
  'bbox': [2098, 734, 2125, 734, 2125, 755, 2098, 755]},
 {'id': 469,
  'start_y': 771,
  'start_x': 2099,
  'text': 'V.',
  'bbox': [2099, 772, 2127, 771, 2127, 790, 2099, 791]},
 {'id': 554,
  'start_y': 880,
  'start_x': 2102,
  'text': 'Cua',
  'bbox': [2102, 880, 2143, 880, 2143, 899, 2102, 899]},
 {'id': 264,
  'start_y': 404,
  'start_x': 2103,
  'text': '(',
  'bbox': [2102, 405, 2109, 404, 2110, 418, 2103, 419]},
 {'id': 265,
  'start_y': 402,
  'start_x': 2109,
  'text': '16',
  'bbox': [2108, 404, 2125, 402, 2126, 416, 2109, 418]},
 {'id': 110,
  'start_y': 375,
  'start_x': 2112,
  'text': 'aqy',
  'bbox': [2112, 375, 2135, 375, 2135, 386, 2112, 386]},
 {'id': 687,
  'start_y': 950,
  'start_x': 2112,
  'text': 'G.',
  'bbox': [2111, 951, 2139, 950, 2140, 972, 2112, 973]},
 {'id': 711,
  'start_y': 1059,
  'start_x': 2122,
  'text': 'A',
  'bbox': [2122, 1059, 2140, 1059, 2140, 1078, 2122, 1078]},
 {'id': 761,
  'start_y': 1208,
  'start_x': 2122,
  'text': 'L',
  'bbox': [2122, 1208, 2137, 1208, 2137, 1226, 2122, 1226]},
 {'id': 266,
  'start_y': 401,
  'start_x': 2124,
  'text': ')',
  'bbox': [2123, 402, 2130, 401, 2131, 415, 2124, 416]},
 {'id': 692,
  'start_y': 1024,
  'start_x': 2126,
  'text': 'Lalik',
  'bbox': [2126, 1024, 2174, 1024, 2174, 1041, 2126, 1041]},
 {'id': 807,
  'start_y': 1239,
  'start_x': 2126,
  'text': 'G.',
  'bbox': [2126, 1239, 2149, 1239, 2149, 1258, 2126, 1258]},
 {'id': 840,
  'start_y': 1311,
  'start_x': 2126,
  'text': 'D.',
  'bbox': [2125, 1312, 2152, 1311, 2153, 1338, 2126, 1339]},
 {'id': 9,
  'start_y': 60,
  'start_x': 2127,
  'text': 'J',
  'bbox': [2157, 10, 2155, 60, 2125, 59, 2127, 9]},
 {'id': 899,
  'start_y': 1363,
  'start_x': 2127,
  'text': 'birlo',
  'bbox': [2127, 1364, 2160, 1363, 2160, 1380, 2127, 1381]},
 {'id': 726,
  'start_y': 1092,
  'start_x': 2129,
  'text': 'Llamvin',
  'bbox': [2129, 1094, 2229, 1092, 2229, 1111, 2129, 1113]},
 {'id': 747,
  'start_y': 1134,
  'start_x': 2129,
  'text': 'A.',
  'bbox': [2128, 1135, 2156, 1134, 2157, 1154, 2129, 1155]},
 {'id': 749,
  'start_y': 1168,
  'start_x': 2129,
  'text': 'A.',
  'bbox': [2129, 1168, 2157, 1168, 2157, 1186, 2129, 1186]},
 {'id': 685,
  'start_y': 922,
  'start_x': 2130,
  'text': 'Cainong',
  'bbox': [2131, 919, 2208, 922, 2207, 946, 2130, 943]},
 {'id': 828,
  'start_y': 1277,
  'start_x': 2130,
  'text': 'A',
  'bbox': [2129, 1278, 2149, 1277, 2150, 1297, 2130, 1298]},
 {'id': 283,
  'start_y': 476,
  'start_x': 2132,
  'text': 'Malquisto',
  'bbox': [2132, 476, 2232, 476, 2232, 505, 2132, 505]},
 {'id': 281,
  'start_y': 433,
  'start_x': 2133,
  'text': 'Alvarez',
  'bbox': [2132, 437, 2211, 433, 2212, 460, 2133, 464]},
 {'id': 320,
  'start_y': 513,
  'start_x': 2134,
  'text': 'Jovillano',
  'bbox': [2134, 513, 2217, 513, 2217, 534, 2134, 534]},
 {'id': 344,
  'start_y': 590,
  'start_x': 2137,
  'text': 'Cua',
  'bbox': [2137, 590, 2175, 590, 2175, 605, 2137, 605]},
 {'id': 446,
  'start_y': 695,
  'start_x': 2139,
  'text': 'Algopera',
  'bbox': [2140, 693, 2217, 695, 2216, 721, 2139, 719]},
 {'id': 689,
  'start_y': 989,
  'start_x': 2140,
  'text': 'Sabado',
  'bbox': [2140, 988, 2211, 989, 2211, 1008, 2140, 1007]},
 {'id': 526,
  'start_y': 836,
  'start_x': 2142,
  'text': 'Amar',
  'bbox': [2141, 839, 2195, 836, 2196, 862, 2142, 865]},
 {'id': 456,
  'start_y': 734,
  'start_x': 2144,
  'text': 'Hermano',
  'bbox': [2144, 734, 2216, 734, 2216, 755, 2144, 755]},
 {'id': 470,
  'start_y': 769,
  'start_x': 2145,
  'text': 'Lastimado',
  'bbox': [2145, 771, 2237, 769, 2237, 788, 2145, 790]},
 {'id': 359,
  'start_y': 631,
  'start_x': 2146,
  'text': 'Say',
  'bbox': [2148, 627, 2201, 631, 2199, 653, 2146, 650]},
 {'id': 499,
  'start_y': 805,
  'start_x': 2146,
  'text': 'Norega',
  'bbox': [2146, 805, 2218, 805, 2218, 830, 2146, 830]},
 {'id': 382,
  'start_y': 664,
  'start_x': 2147,
  'text': 'Algopera',
  'bbox': [2148, 660, 2224, 664, 2223, 690, 2147, 686]},
 {'id': 94,
  'start_y': 320,
  'start_x': 2149,
  'text': 'FATHER',
  'bbox': [2149, 320, 2270, 320, 2270, 333, 2149, 333]},
 {'id': 688,
  'start_y': 948,
  'start_x': 2154,
  'text': 'Palima',
  'bbox': [2153, 950, 2217, 948, 2218, 970, 2154, 972]},
 {'id': 841,
  'start_y': 1307,
  'start_x': 2158,
  'text': 'Managbanag',
  'bbox': [2157, 1311, 2266, 1307, 2267, 1335, 2158, 1339]},
 {'id': 712,
  'start_y': 1057,
  'start_x': 2161,
  'text': 'Montaño',
  'bbox': [2161, 1058, 2246, 1057, 2246, 1077, 2161, 1078]},
 {'id': 900,
  'start_y': 1363,
  'start_x': 2163,
  'text': 'aaw',
  'bbox': [2163, 1364, 2193, 1363, 2193, 1379, 2163, 1380]},
 {'id': 808,
  'start_y': 1240,
  'start_x': 2169,
  'text': 'Sevilland',
  'bbox': [2169, 1239, 2250, 1240, 2250, 1259, 2169, 1258]},
 {'id': 829,
  'start_y': 1274,
  'start_x': 2170,
  'text': 'Olais',
  'bbox': [2169, 1276, 2224, 1274, 2225, 1295, 2170, 1297]},
 {'id': 805,
  'start_y': 1205,
  'start_x': 2171,
  'text': 'Parone',
  'bbox': [2171, 1205, 2238, 1205, 2238, 1224, 2171, 1224]},
 {'id': 748,
  'start_y': 1131,
  'start_x': 2173,
  'text': 'Cinco',
  'bbox': [2172, 1133, 2228, 1131, 2229, 1151, 2173, 1153]},
 {'id': 331,
  'start_y': 545,
  'start_x': 2178,
  'text': 'Go',
  'bbox': [2177, 546, 2206, 545, 2207, 567, 2178, 568]},
 {'id': 750,
  'start_y': 1169,
  'start_x': 2178,
  'text': 'Lambre',
  'bbox': [2178, 1168, 2253, 1169, 2253, 1187, 2178, 1186]},
 {'id': 901,
  'start_y': 1363,
  'start_x': 2193,
  'text': ',',
  'bbox': [2193, 1363, 2199, 1363, 2199, 1379, 2193, 1379]},
 {'id': 111,
  'start_y': 376,
  'start_x': 2194,
  'text': 'Last',
  'bbox': [2194, 376, 2217, 376, 2217, 386, 2194, 386]},
 {'id': 105,
  'start_y': 355,
  'start_x': 2198,
  'text': 'Vened',
  'bbox': [2198, 357, 2251, 355, 2251, 365, 2198, 367]},
 {'id': 902,
  'start_y': 1363,
  'start_x': 2199,
  'text': 'ot',
  'bbox': [2199, 1363, 2215, 1363, 2215, 1379, 2199, 1379]},
 {'id': 112,
  'start_y': 376,
  'start_x': 2216,
  'text': ')',
  'bbox': [2216, 376, 2221, 376, 2221, 386, 2216, 386]},
 {'id': 903,
  'start_y': 1363,
  'start_x': 2218,
  'text': 'Jo',
  'bbox': [2218, 1363, 2235, 1363, 2235, 1379, 2218, 1379]},
 {'id': 904,
  'start_y': 1362,
  'start_x': 2247,
  'text': 'ni',
  'bbox': [2247, 1362, 2260, 1362, 2260, 1378, 2247, 1378]},
 {'id': 917,
  'start_y': 1378,
  'start_x': 2255,
  'text': 'presented',
  'bbox': [2255, 1380, 2320, 1378, 2320, 1393, 2255, 1395]},
 {'id': 457,
  'start_y': 738,
  'start_x': 2262,
  'text': '255',
  'bbox': [2262, 738, 2296, 738, 2296, 752, 2262, 752]},
 {'id': 905,
  'start_y': 1360,
  'start_x': 2263,
  'text': 'ithid',
  'bbox': [2263, 1361, 2296, 1360, 2296, 1377, 2263, 1378]},
 {'id': 285,
  'start_y': 437,
  'start_x': 2264,
  'text': '42',
  'bbox': [2266, 434, 2298, 437, 2296, 461, 2264, 459]},
 {'id': 106,
  'start_y': 366,
  'start_x': 2265,
  'text': 'Age',
  'bbox': [2265, 366, 2288, 366, 2288, 377, 2265, 377]},
 {'id': 284,
  'start_y': 476,
  'start_x': 2265,
  'text': '29',
  'bbox': [2265, 476, 2296, 476, 2296, 505, 2265, 505]},
 {'id': 267,
  'start_y': 403,
  'start_x': 2266,
  'text': '(',
  'bbox': [2266, 403, 2273, 403, 2273, 417, 2266, 417]},
 {'id': 500,
  'start_y': 808,
  'start_x': 2269,
  'text': '38',
  'bbox': [2269, 808, 2288, 808, 2288, 822, 2269, 822]},
 {'id': 527,
  'start_y': 841,
  'start_x': 2269,
  'text': '40',
  'bbox': [2269, 841, 2292, 841, 2292, 860, 2269, 860]},
 {'id': 693,
  'start_y': 915,
  'start_x': 2269,
  'text': '24',
  'bbox': [2270, 913, 2299, 915, 2298, 940, 2269, 938]},
 {'id': 334,
  'start_y': 555,
  'start_x': 2270,
  'text': '33',
  'bbox': [2270, 555, 2294, 555, 2294, 569, 2270, 569]},
 {'id': 345,
  'start_y': 590,
  'start_x': 2270,
  'text': '37',
  'bbox': [2270, 590, 2291, 590, 2291, 603, 2270, 603]},
 {'id': 713,
  'start_y': 1057,
  'start_x': 2270,
  'text': '36',
  'bbox': [2270, 1057, 2296, 1057, 2296, 1076, 2270, 1076]},
 {'id': 268,
  'start_y': 403,
  'start_x': 2271,
  'text': '17',
  'bbox': [2271, 403, 2290, 403, 2290, 417, 2271, 417]},
 {'id': 384,
  'start_y': 665,
  'start_x': 2272,
  'text': '39',
  'bbox': [2272, 665, 2295, 665, 2295, 678, 2272, 678]},
 {'id': 447,
  'start_y': 696,
  'start_x': 2272,
  'text': '27',
  'bbox': [2272, 696, 2295, 696, 2295, 710, 2272, 710]},
 {'id': 471,
  'start_y': 771,
  'start_x': 2272,
  'text': '62',
  'bbox': [2272, 771, 2295, 771, 2295, 786, 2272, 786]},
 {'id': 699,
  'start_y': 951,
  'start_x': 2272,
  'text': '27',
  'bbox': [2272, 951, 2297, 951, 2297, 967, 2272, 967]},
 {'id': 701,
  'start_y': 987,
  'start_x': 2272,
  'text': '23',
  'bbox': [2272, 987, 2298, 987, 2298, 1001, 2272, 1001]},
 {'id': 751,
  'start_y': 1169,
  'start_x': 2272,
  'text': '29',
  'bbox': [2272, 1169, 2301, 1169, 2301, 1187, 2272, 1187]},
 {'id': 708,
  'start_y': 1024,
  'start_x': 2273,
  'text': '28',
  'bbox': [2273, 1024, 2297, 1024, 2297, 1040, 2273, 1040]},
 {'id': 728,
  'start_y': 1100,
  'start_x': 2273,
  'text': '23',
  'bbox': [2273, 1100, 2297, 1100, 2297, 1112, 2273, 1112]},
 {'id': 806,
  'start_y': 1205,
  'start_x': 2273,
  'text': '26',
  'bbox': [2273, 1205, 2299, 1205, 2299, 1219, 2273, 1219]},
 {'id': 361,
  'start_y': 629,
  'start_x': 2274,
  'text': '39',
  'bbox': [2274, 629, 2297, 629, 2297, 643, 2274, 643]},
 {'id': 753,
  'start_y': 1136,
  'start_x': 2274,
  'text': '22',
  'bbox': [2274, 1136, 2299, 1136, 2299, 1150, 2274, 1150]},
 {'id': 830,
  'start_y': 1278,
  'start_x': 2275,
  'text': '23',
  'bbox': [2275, 1278, 2301, 1278, 2301, 1292, 2275, 1292]},
 {'id': 842,
  'start_y': 1305,
  'start_x': 2276,
  'text': '25',
  'bbox': [2275, 1306, 2305, 1305, 2306, 1333, 2276, 1334]},
 {'id': 809,
  'start_y': 1244,
  'start_x': 2277,
  'text': '36',
  'bbox': [2277, 1244, 2303, 1244, 2303, 1257, 2277, 1257]},
 {'id': 269,
  'start_y': 403,
  'start_x': 2287,
  'text': ')',
  'bbox': [2287, 403, 2295, 403, 2295, 417, 2287, 417]},
 {'id': 321,
  'start_y': 515,
  'start_x': 2293,
  'text': '42',
  'bbox': [2266, 533, 2266, 515, 2293, 515, 2293, 533]},
 {'id': 95,
  'start_y': 320,
  'start_x': 2295,
  'text': 'BAUR',
  'bbox': [2295, 320, 2343, 320, 2343, 333, 2295, 333]},
 {'id': 906,
  'start_y': 1361,
  'start_x': 2300,
  'text': 'I',
  'bbox': [2300, 1361, 2308, 1361, 2308, 1377, 2300, 1377]},
 {'id': 286,
  'start_y': 441,
  'start_x': 2302,
  'text': 'filipi',
  'bbox': [2304, 437, 2349, 441, 2347, 466, 2302, 462]},
 {'id': 114,
  'start_y': 357,
  'start_x': 2304,
  'text': 'Nations',
  'bbox': [2304, 356, 2351, 357, 2351, 375, 2304, 374]},
 {'id': 694,
  'start_y': 917,
  'start_x': 2305,
  'text': 'Filip',
  'bbox': [2306, 915, 2340, 917, 2339, 942, 2305, 940]},
 {'id': 555,
  'start_y': 873,
  'start_x': 2306,
  'text': 'chinese',
  'bbox': [2305, 876, 2361, 873, 2362, 890, 2306, 893]},
 {'id': 907,
  'start_y': 1359,
  'start_x': 2313,
  'text': 'HEREBY',
  'bbox': [2313, 1360, 2371, 1359, 2371, 1376, 2313, 1377]},
 {'id': 270,
  'start_y': 401,
  'start_x': 2316,
  'text': '(',
  'bbox': [2317, 401, 2323, 401, 2322, 415, 2316, 415]},
 {'id': 113,
  'start_y': 371,
  'start_x': 2319,
  'text': 'lity',
  'bbox': [2319, 371, 2338, 371, 2338, 382, 2319, 382]},
 {'id': 271,
  'start_y': 402,
  'start_x': 2322,
  'text': '18',
  'bbox': [2323, 401, 2339, 402, 2338, 416, 2322, 415]},
 {'id': 700,
  'start_y': 952,
  'start_x': 2322,
  'text': '"',
  'bbox': [2322, 952, 2329, 952, 2329, 960, 2322, 960]},
 {'id': 918,
  'start_y': 1378,
  'start_x': 2324,
  'text': 'for',
  'bbox': [2324, 1378, 2341, 1378, 2341, 1393, 2324, 1393]},
 {'id': 702,
  'start_y': 987,
  'start_x': 2325,
  'text': '"',
  'bbox': [2325, 987, 2333, 987, 2333, 995, 2325, 995]},
 {'id': 312,
  'start_y': 472,
  'start_x': 2326,
  'text': 'al',
  'bbox': [2326, 472, 2334, 472, 2334, 481, 2326, 481]},
 {'id': 501,
  'start_y': 807,
  'start_x': 2326,
  'text': '"',
  'bbox': [2326, 807, 2334, 807, 2334, 815, 2326, 815]},
 {'id': 346,
  'start_y': 588,
  'start_x': 2328,
  'text': '"',
  'bbox': [2328, 588, 2336, 588, 2336, 596, 2328, 596]},
 {'id': 322,
  'start_y': 515,
  'start_x': 2329,
  'text': '"',
  'bbox': [2329, 515, 2338, 515, 2338, 524, 2329, 524]},
 {'id': 332,
  'start_y': 549,
  'start_x': 2329,
  'text': '1',
  'bbox': [2329, 549, 2335, 549, 2335, 558, 2329, 558]},
 {'id': 707,
  'start_y': 1022,
  'start_x': 2329,
  'text': 'a',
  'bbox': [2329, 1022, 2336, 1022, 2336, 1028, 2329, 1028]},
 {'id': 714,
  'start_y': 1059,
  'start_x': 2329,
  'text': '4',
  'bbox': [2329, 1059, 2337, 1059, 2337, 1070, 2329, 1070]},
 {'id': 727,
  'start_y': 1096,
  'start_x': 2330,
  'text': '"',
  'bbox': [2330, 1096, 2337, 1096, 2337, 1103, 2330, 1103]},
 {'id': 448,
  'start_y': 697,
  'start_x': 2331,
  'text': '4',
  'bbox': [2331, 697, 2339, 697, 2339, 705, 2331, 705]},
 {'id': 843,
  'start_y': 1311,
  'start_x': 2331,
  'text': '"',
  'bbox': [2331, 1311, 2342, 1311, 2342, 1320, 2331, 1320]},
 {'id': 360,
  'start_y': 627,
  'start_x': 2332,
  'text': '"',
  'bbox': [2332, 627, 2338, 627, 2338, 633, 2332, 633]},
 {'id': 458,
  'start_y': 738,
  'start_x': 2332,
  'text': '"',
  'bbox': [2332, 738, 2339, 738, 2339, 743, 2332, 743]},
 {'id': 752,
  'start_y': 1130,
  'start_x': 2332,
  'text': '"',
  'bbox': [2332, 1130, 2340, 1130, 2340, 1139, 2332, 1139]},
 {'id': 815,
  'start_y': 1242,
  'start_x': 2333,
  'text': 'B',
  'bbox': [2333, 1242, 2341, 1242, 2341, 1250, 2333, 1250]},
 {'id': 831,
  'start_y': 1276,
  'start_x': 2333,
  'text': '"',
  'bbox': [2333, 1276, 2341, 1276, 2341, 1284, 2333, 1284]},
 {'id': 333,
  'start_y': 549,
  'start_x': 2334,
  'text': '/',
  'bbox': [2334, 549, 2340, 549, 2340, 558, 2334, 558]},
 {'id': 383,
  'start_y': 659,
  'start_x': 2334,
  'text': '"',
  'bbox': [2334, 659, 2341, 659, 2341, 666, 2334, 666]},
 {'id': 472,
  'start_y': 772,
  'start_x': 2334,
  'text': '.',
  'bbox': [2334, 772, 2341, 772, 2341, 779, 2334, 779]},
 {'id': 528,
  'start_y': 841,
  'start_x': 2334,
  'text': '"',
  'bbox': [2326, 848, 2326, 841, 2334, 841, 2334, 848]},
 {'id': 754,
  'start_y': 1168,
  'start_x': 2334,
  'text': '"',
  'bbox': [2334, 1168, 2342, 1168, 2342, 1179, 2334, 1179]},
 {'id': 272,
  'start_y': 401,
  'start_x': 2337,
  'text': ')',
  'bbox': [2338, 401, 2345, 401, 2344, 415, 2337, 415]},
 {'id': 810,
  'start_y': 1204,
  'start_x': 2337,
  'text': '"',
  'bbox': [2337, 1204, 2346, 1204, 2346, 1213, 2337, 1213]},
 {'id': 919,
  'start_y': 1376,
  'start_x': 2344,
  'text': 'registration',
  'bbox': [2344, 1378, 2415, 1376, 2415, 1391, 2344, 1393]},
 {'id': 115,
  'start_y': 358,
  'start_x': 2362,
  'text': 'Religion',
  'bbox': [2362, 357, 2409, 358, 2409, 376, 2362, 375]},
 {'id': 273,
  'start_y': 401,
  'start_x': 2373,
  'text': '(',
  'bbox': [2373, 401, 2380, 401, 2380, 417, 2373, 417]},
 {'id': 908,
  'start_y': 1358,
  'start_x': 2376,
  'text': 'CERTIFY',
  'bbox': [2376, 1359, 2435, 1358, 2435, 1375, 2376, 1376]},
 {'id': 274,
  'start_y': 401,
  'start_x': 2379,
  'text': '19',
  'bbox': [2379, 401, 2396, 401, 2396, 417, 2379, 417]},
 {'id': 276,
  'start_y': 394,
  'start_x': 2384,
  'text': '161',
  'bbox': [2384, 394, 2398, 394, 2398, 427, 2384, 427]},
 {'id': 275,
  'start_y': 401,
  'start_x': 2394,
  'text': ')',
  'bbox': [2394, 401, 2402, 401, 2402, 417, 2394, 417]},
 {'id': 920,
  'start_y': 1376,
  'start_x': 2419,
  'text': 'in',
  'bbox': [2419, 1376, 2431, 1376, 2431, 1390, 2419, 1390]},
 {'id': 116,
  'start_y': 360,
  'start_x': 2425,
  'text': 'Day',
  'bbox': [2425, 359, 2452, 360, 2452, 377, 2425, 376]},
 {'id': 715,
  'start_y': 1054,
  'start_x': 2425,
  'text': '23',
  'bbox': [2425, 1054, 2454, 1054, 2454, 1076, 2425, 1076]},
 {'id': 729,
  'start_y': 1090,
  'start_x': 2426,
  'text': '22',
  'bbox': [2426, 1090, 2454, 1090, 2454, 1118, 2426, 1118]},
 {'id': 287,
  'start_y': 400,
  'start_x': 2427,
  'text': '(',
  'bbox': [2427, 400, 2434, 400, 2434, 416, 2427, 416]},
 {'id': 449,
  'start_y': 694,
  'start_x': 2427,
  'text': '29',
  'bbox': [2426, 695, 2453, 694, 2454, 717, 2427, 718]},
 {'id': 811,
  'start_y': 1207,
  'start_x': 2427,
  'text': '24',
  'bbox': [2425, 1209, 2450, 1207, 2451, 1226, 2427, 1228]},
 {'id': 350,
  'start_y': 584,
  'start_x': 2429,
  'text': '30',
  'bbox': [2429, 584, 2451, 584, 2451, 603, 2429, 603]},
 {'id': 695,
  'start_y': 912,
  'start_x': 2430,
  'text': '20',
  'bbox': [2430, 912, 2455, 912, 2455, 929, 2430, 929]},
 {'id': 703,
  'start_y': 985,
  'start_x': 2430,
  'text': '19',
  'bbox': [2430, 985, 2449, 985, 2449, 1005, 2430, 1005]},
 {'id': 288,
  'start_y': 400,
  'start_x': 2431,
  'text': '20',
  'bbox': [2431, 400, 2450, 400, 2450, 416, 2431, 416]},
 {'id': 313,
  'start_y': 474,
  'start_x': 2431,
  'text': '190',
  'bbox': [2430, 476, 2476, 474, 2477, 493, 2431, 495]},
 {'id': 347,
  'start_y': 547,
  'start_x': 2431,
  'text': '18',
  'bbox': [2430, 548, 2450, 547, 2451, 565, 2431, 566]},
 {'id': 529,
  'start_y': 839,
  'start_x': 2432,
  'text': '15',
  'bbox': [2432, 839, 2454, 839, 2454, 855, 2432, 855]},
 {'id': 921,
  'start_y': 1374,
  'start_x': 2434,
  'text': 'this',
  'bbox': [2434, 1375, 2457, 1374, 2457, 1389, 2434, 1390]},
 {'id': 816,
  'start_y': 1237,
  'start_x': 2435,
  'text': '2',
  'bbox': [2435, 1237, 2454, 1237, 2454, 1258, 2435, 1258]},
 {'id': 473,
  'start_y': 765,
  'start_x': 2440,
  'text': '4',
  'bbox': [2440, 765, 2454, 765, 2454, 783, 2440, 783]},
 {'id': 909,
  'start_y': 1356,
  'start_x': 2440,
  'text': 'that',
  'bbox': [2440, 1357, 2466, 1356, 2466, 1373, 2440, 1374]},
 {'id': 289,
  'start_y': 400,
  'start_x': 2448,
  'text': ')',
  'bbox': [2448, 400, 2456, 400, 2456, 416, 2448, 416]},
 {'id': 96,
  'start_y': 314,
  'start_x': 2451,
  'text': 'DATE',
  'bbox': [2451, 314, 2490, 314, 2490, 330, 2451, 330]},
 {'id': 296,
  'start_y': 435,
  'start_x': 2460,
  'text': 'Jmc',
  'bbox': [2461, 433, 2502, 435, 2501, 453, 2460, 451]},
 {'id': 351,
  'start_y': 584,
  'start_x': 2460,
  'text': 'June',
  'bbox': [2460, 584, 2504, 584, 2504, 602, 2460, 602]},
 {'id': 922,
  'start_y': 1374,
  'start_x': 2461,
  'text': 'office',
  'bbox': [2461, 1375, 2496, 1374, 2496, 1388, 2461, 1389]},
 {'id': 385,
  'start_y': 658,
  'start_x': 2462,
  'text': 'Jan',
  'bbox': [2462, 658, 2499, 658, 2499, 674, 2462, 674]},
 {'id': 696,
  'start_y': 912,
  'start_x': 2462,
  'text': 'Jan.',
  'bbox': [2462, 912, 2503, 912, 2503, 929, 2462, 929]},
 {'id': 348,
  'start_y': 545,
  'start_x': 2463,
  'text': 'Oct.',
  'bbox': [2462, 547, 2499, 545, 2500, 562, 2463, 564]},
 {'id': 362,
  'start_y': 621,
  'start_x': 2463,
  'text': 'Arg',
  'bbox': [2463, 621, 2507, 621, 2507, 645, 2463, 645]},
 {'id': 704,
  'start_y': 985,
  'start_x': 2464,
  'text': 'A',
  'bbox': [2464, 985, 2482, 985, 2482, 1004, 2464, 1004]},
 {'id': 323,
  'start_y': 508,
  'start_x': 2465,
  'text': 'Oct.',
  'bbox': [2465, 507, 2502, 508, 2502, 526, 2465, 525]},
 {'id': 450,
  'start_y': 692,
  'start_x': 2465,
  'text': 'Sept',
  'bbox': [2464, 694, 2505, 692, 2506, 714, 2465, 716]},
 {'id': 716,
  'start_y': 1054,
  'start_x': 2466,
  'text': 'Arg',
  'bbox': [2466, 1054, 2500, 1054, 2500, 1076, 2466, 1076]},
 {'id': 730,
  'start_y': 1088,
  'start_x': 2467,
  'text': 'Sept.',
  'bbox': [2467, 1089, 2509, 1088, 2509, 1116, 2467, 1117]},
 {'id': 474,
  'start_y': 763,
  'start_x': 2468,
  'text': 'Oct.',
  'bbox': [2468, 764, 2506, 763, 2506, 781, 2468, 782]},
 {'id': 530,
  'start_y': 839,
  'start_x': 2468,
  'text': 'Oct.',
  'bbox': [2468, 839, 2508, 839, 2508, 855, 2468, 855]},
 {'id': 755,
  'start_y': 1164,
  'start_x': 2468,
  'text': 'July',
  'bbox': [2468, 1163, 2507, 1164, 2507, 1189, 2468, 1188]},
 {'id': 502,
  'start_y': 802,
  'start_x': 2469,
  'text': 'Nov',
  'bbox': [2470, 801, 2506, 802, 2505, 820, 2469, 819]},
 {'id': 910,
  'start_y': 1356,
  'start_x': 2469,
  'text': 'the',
  'bbox': [2469, 1357, 2492, 1356, 2492, 1372, 2469, 1373]},
 {'id': 290,
  'start_y': 400,
  'start_x': 2470,
  'text': '(',
  'bbox': [2470, 400, 2477, 400, 2477, 416, 2470, 416]},
 {'id': 812,
  'start_y': 1202,
  'start_x': 2470,
  'text': 'July',
  'bbox': [2468, 1205, 2504, 1202, 2505, 1221, 2470, 1224]},
 {'id': 117,
  'start_y': 360,
  'start_x': 2471,
  'text': 'Mo.',
  'bbox': [2471, 360, 2495, 360, 2495, 377, 2471, 377]},
 {'id': 817,
  'start_y': 1234,
  'start_x': 2473,
  'text': 'Feb',
  'bbox': [2473, 1235, 2511, 1234, 2511, 1256, 2473, 1257]},
 {'id': 291,
  'start_y': 400,
  'start_x': 2474,
  'text': '21',
  'bbox': [2474, 400, 2490, 400, 2490, 416, 2474, 416]},
 {'id': 292,
  'start_y': 400,
  'start_x': 2490,
  'text': ')',
  'bbox': [2490, 400, 2498, 400, 2498, 416, 2490, 416]},
 {'id': 97,
  'start_y': 314,
  'start_x': 2494,
  'text': 'AND',
  'bbox': [2494, 314, 2528, 314, 2528, 329, 2494, 329]},
 {'id': 717,
  'start_y': 1054,
  'start_x': 2495,
  'text': '.',
  'bbox': [2495, 1054, 2510, 1054, 2510, 1076, 2495, 1076]},
 {'id': 923,
  'start_y': 1374,
  'start_x': 2496,
  'text': '.',
  'bbox': [2496, 1374, 2500, 1374, 2500, 1388, 2496, 1388]},
 {'id': 363,
  'start_y': 621,
  'start_x': 2497,
  'text': '.',
  'bbox': [2497, 621, 2519, 621, 2519, 645, 2497, 645]},
 {'id': 911,
  'start_y': 1355,
  'start_x': 2498,
  'text': 'entries',
  'bbox': [2498, 1356, 2542, 1355, 2542, 1372, 2498, 1373]},
 {'id': 818,
  'start_y': 1235,
  'start_x': 2504,
  'text': '-',
  'bbox': [2504, 1235, 2517, 1235, 2517, 1256, 2504, 1256]},
 {'id': 297,
  'start_y': 438,
  'start_x': 2507,
  'text': '1948',
  'bbox': [2508, 436, 2544, 438, 2543, 456, 2507, 454]},
 {'id': 475,
  'start_y': 762,
  'start_x': 2509,
  'text': '14',
  'bbox': [2509, 763, 2540, 762, 2540, 780, 2509, 781]},
 {'id': 352,
  'start_y': 584,
  'start_x': 2510,
  'text': '1959',
  'bbox': [2510, 584, 2543, 584, 2543, 602, 2510, 602]},
 {'id': 531,
  'start_y': 839,
  'start_x': 2510,
  'text': '162',
  'bbox': [2510, 839, 2547, 839, 2547, 855, 2510, 855]},
 {'id': 118,
  'start_y': 361,
  'start_x': 2511,
  'text': 'Year',
  'bbox': [2511, 360, 2544, 361, 2544, 379, 2511, 378]},
 {'id': 349,
  'start_y': 542,
  'start_x': 2511,
  'text': '1953',
  'bbox': [2510, 544, 2549, 542, 2550, 560, 2511, 562]},
 {'id': 697,
  'start_y': 912,
  'start_x': 2511,
  'text': '1964',
  'bbox': [2511, 912, 2548, 912, 2548, 929, 2511, 929]},
 {'id': 364,
  'start_y': 621,
  'start_x': 2512,
  'text': 'Al',
  'bbox': [2512, 621, 2547, 621, 2547, 645, 2512, 645]},
 {'id': 293,
  'start_y': 400,
  'start_x': 2513,
  'text': '(',
  'bbox': [2513, 400, 2521, 400, 2521, 416, 2513, 416]},
 {'id': 314,
  'start_y': 470,
  'start_x': 2513,
  'text': '1903',
  'bbox': [2512, 472, 2549, 470, 2550, 489, 2513, 491]},
 {'id': 459,
  'start_y': 729,
  'start_x': 2513,
  'text': '1957',
  'bbox': [2513, 729, 2547, 729, 2547, 746, 2513, 746]},
 {'id': 503,
  'start_y': 804,
  'start_x': 2513,
  'text': '1950',
  'bbox': [2514, 803, 2551, 804, 2550, 821, 2513, 820]},
 {'id': 324,
  'start_y': 510,
  'start_x': 2514,
  'text': '1962',
  'bbox': [2514, 509, 2551, 510, 2551, 527, 2514, 526]},
 {'id': 705,
  'start_y': 984,
  'start_x': 2514,
  'text': '1964',
  'bbox': [2514, 984, 2552, 984, 2552, 1004, 2514, 1004]},
 {'id': 731,
  'start_y': 1088,
  'start_x': 2515,
  'text': '1963',
  'bbox': [2515, 1089, 2553, 1088, 2553, 1115, 2515, 1116]},
 {'id': 756,
  'start_y': 1164,
  'start_x': 2515,
  'text': '1957',
  'bbox': [2515, 1163, 2556, 1164, 2556, 1190, 2515, 1189]},
 {'id': 844,
  'start_y': 1307,
  'start_x': 2515,
  'text': '1954',
  'bbox': [2515, 1307, 2551, 1307, 2551, 1325, 2515, 1325]},
 {'id': 451,
  'start_y': 690,
  'start_x': 2516,
  'text': '1954',
  'bbox': [2515, 692, 2554, 690, 2555, 713, 2516, 715]},
 {'id': 718,
  'start_y': 1054,
  'start_x': 2516,
  'text': '1964',
  'bbox': [2516, 1054, 2555, 1054, 2555, 1076, 2516, 1076]},
 {'id': 813,
  'start_y': 1198,
  'start_x': 2516,
  'text': '1963',
  'bbox': [2514, 1201, 2550, 1198, 2551, 1217, 2516, 1220]},
 {'id': 819,
  'start_y': 1235,
  'start_x': 2516,
  'text': '19',
  'bbox': [2516, 1235, 2536, 1235, 2536, 1256, 2516, 1256]},
 {'id': 386,
  'start_y': 658,
  'start_x': 2517,
  'text': 'P',
  'bbox': [2517, 658, 2531, 658, 2531, 673, 2517, 673]},
 {'id': 294,
  'start_y': 400,
  'start_x': 2518,
  'text': '22',
  'bbox': [2518, 400, 2536, 400, 2536, 416, 2518, 416]},
 {'id': 98,
  'start_y': 312,
  'start_x': 2531,
  'text': 'PLACE',
  'bbox': [2531, 313, 2582, 312, 2582, 328, 2531, 329]},
 {'id': 295,
  'start_y': 400,
  'start_x': 2535,
  'text': ')',
  'bbox': [2535, 400, 2543, 400, 2543, 416, 2535, 416]},
 {'id': 912,
  'start_y': 1354,
  'start_x': 2549,
  'text': 'herein',
  'bbox': [2549, 1355, 2591, 1354, 2591, 1371, 2549, 1372]},
 {'id': 719,
  'start_y': 1054,
  'start_x': 2566,
  'text': 'Van',
  'bbox': [2566, 1054, 2606, 1054, 2606, 1076, 2566, 1076]},
 {'id': 732,
  'start_y': 1087,
  'start_x': 2576,
  'text': 'Bughs',
  'bbox': [2576, 1088, 2636, 1087, 2636, 1114, 2576, 1115]},
 {'id': 757,
  'start_y': 1165,
  'start_x': 2578,
  'text': 'Abuyrg',
  'bbox': [2578, 1164, 2653, 1165, 2653, 1191, 2578, 1190]},
 {'id': 820,
  'start_y': 1232,
  'start_x': 2580,
  'text': 'Bugho',
  'bbox': [2580, 1233, 2642, 1232, 2642, 1254, 2580, 1255]},
 {'id': 353,
  'start_y': 580,
  'start_x': 2581,
  'text': 'San',
  'bbox': [2581, 580, 2630, 580, 2630, 599, 2581, 599]},
 {'id': 814,
  'start_y': 1196,
  'start_x': 2581,
  'text': 'Jolasa',
  'bbox': [2581, 1196, 2645, 1196, 2645, 1216, 2581, 1216]},
 {'id': 298,
  'start_y': 400,
  'start_x': 2582,
  'text': '(',
  'bbox': [2582, 400, 2589, 400, 2589, 413, 2582, 413]},
 {'id': 99,
  'start_y': 313,
  'start_x': 2585,
  'text': 'OF',
  'bbox': [2585, 313, 2608, 313, 2608, 328, 2585, 328]},
 {'id': 299,
  'start_y': 399,
  'start_x': 2586,
  'text': 'a',
  'bbox': [2586, 399, 2595, 399, 2595, 412, 2586, 412]},
 {'id': 309,
  'start_y': 433,
  'start_x': 2586,
  'text': 'Abuyog',
  'bbox': [2586, 434, 2665, 433, 2665, 466, 2586, 467]},
 {'id': 365,
  'start_y': 623,
  'start_x': 2589,
  'text': 'Bugho',
  'bbox': [2589, 622, 2650, 623, 2650, 645, 2589, 644]},
 {'id': 706,
  'start_y': 983,
  'start_x': 2589,
  'text': 'Bugho',
  'bbox': [2589, 982, 2655, 983, 2655, 1008, 2589, 1007]},
 {'id': 452,
  'start_y': 693,
  'start_x': 2590,
  'text': 'Bugho',
  'bbox': [2590, 693, 2651, 693, 2651, 715, 2590, 715]},
 {'id': 315,
  'start_y': 469,
  'start_x': 2591,
  'text': 'Tacloban',
  'bbox': [2591, 470, 2686, 469, 2686, 488, 2591, 489]},
 {'id': 387,
  'start_y': 656,
  'start_x': 2591,
  'text': 'Binulho',
  'bbox': [2591, 656, 2665, 656, 2665, 674, 2591, 674]},
 {'id': 460,
  'start_y': 731,
  'start_x': 2591,
  'text': 'Abugg',
  'bbox': [2592, 728, 2664, 731, 2663, 754, 2591, 751]},
 {'id': 532,
  'start_y': 835,
  'start_x': 2591,
  'text': 'Bugh',
  'bbox': [2591, 836, 2648, 835, 2648, 856, 2591, 857]},
 {'id': 698,
  'start_y': 908,
  'start_x': 2592,
  'text': 'Bugh',
  'bbox': [2592, 907, 2650, 908, 2650, 930, 2592, 929]},
 {'id': 325,
  'start_y': 512,
  'start_x': 2593,
  'text': 'Bugho',
  'bbox': [2593, 512, 2661, 512, 2661, 536, 2593, 536]},
 {'id': 300,
  'start_y': 399,
  'start_x': 2594,
  'text': ')',
  'bbox': [2594, 399, 2601, 399, 2601, 412, 2594, 412]},
 {'id': 913,
  'start_y': 1354,
  'start_x': 2596,
  'text': 'are',
  'bbox': [2596, 1354, 2618, 1354, 2618, 1370, 2596, 1370]},
 {'id': 720,
  'start_y': 1054,
  'start_x': 2608,
  'text': 'Vare',
  'bbox': [2608, 1054, 2650, 1054, 2650, 1076, 2608, 1076]},
 {'id': 100,
  'start_y': 311,
  'start_x': 2611,
  'text': 'MARRIAGE',
  'bbox': [2611, 312, 2691, 311, 2691, 327, 2611, 328]},
 {'id': 302,
  'start_y': 369,
  'start_x': 2622,
  'text': '(',
  'bbox': [2622, 369, 2628, 369, 2628, 382, 2622, 382]},
 {'id': 303,
  'start_y': 367,
  'start_x': 2624,
  'text': 'Municipality',
  'bbox': [2624, 368, 2694, 367, 2694, 381, 2624, 382]},
 {'id': 914,
  'start_y': 1352,
  'start_x': 2624,
  'text': 'true',
  'bbox': [2624, 1353, 2650, 1352, 2650, 1369, 2624, 1370]},
 {'id': 476,
  'start_y': 769,
  'start_x': 2629,
  'text': '4',
  'bbox': [2629, 769, 2636, 769, 2636, 777, 2629, 777]},
 {'id': 504,
  'start_y': 806,
  'start_x': 2635,
  'text': 'B',
  'bbox': [2635, 806, 2642, 806, 2642, 815, 2635, 815]},
 {'id': 721,
  'start_y': 1054,
  'start_x': 2643,
  'text': ',',
  'bbox': [2643, 1054, 2656, 1054, 2656, 1076, 2643, 1076]},
 {'id': 733,
  'start_y': 1087,
  'start_x': 2644,
  'text': ',',
  'bbox': [2644, 1087, 2660, 1087, 2660, 1114, 2644, 1114]},
 {'id': 354,
  'start_y': 580,
  'start_x': 2647,
  'text': 'Antonio',
  'bbox': [2647, 580, 2727, 580, 2727, 599, 2647, 599]},
 {'id': 915,
  'start_y': 1352,
  'start_x': 2655,
  'text': 'and',
  'bbox': [2655, 1353, 2680, 1352, 2680, 1368, 2655, 1369]},
 {'id': 310,
  'start_y': 434,
  'start_x': 2661,
  'text': ',',
  'bbox': [2661, 434, 2679, 434, 2679, 466, 2661, 466]},
 {'id': 301,
  'start_y': 347,
  'start_x': 2671,
  'text': 'Place',
  'bbox': [2671, 347, 2709, 347, 2709, 359, 2671, 359]},
 {'id': 722,
  'start_y': 1051,
  'start_x': 2677,
  'text': 'Occ',
  'bbox': [2677, 1051, 2707, 1051, 2707, 1067, 2677, 1067]},
 {'id': 306,
  'start_y': 398,
  'start_x': 2683,
  'text': '(',
  'bbox': [2683, 398, 2688, 398, 2688, 411, 2683, 411]},
 {'id': 734,
  'start_y': 1088,
  'start_x': 2684,
  'text': 'Ly',
  'bbox': [2684, 1088, 2718, 1088, 2718, 1113, 2684, 1113]},
 {'id': 307,
  'start_y': 397,
  'start_x': 2686,
  'text': '23',
  'bbox': [2686, 397, 2703, 397, 2703, 410, 2686, 410]},
 {'id': 916,
  'start_y': 1351,
  'start_x': 2687,
  'text': 'cor',
  'bbox': [2687, 1352, 2712, 1351, 2712, 1367, 2687, 1368]},
 {'id': 311,
  'start_y': 434,
  'start_x': 2688,
  'text': 'leyt',
  'bbox': [2688, 434, 2729, 434, 2729, 466, 2688, 466]},
 {'id': 304,
  'start_y': 368,
  'start_x': 2693,
  'text': ',',
  'bbox': [2693, 368, 2697, 368, 2697, 381, 2693, 381]},
 {'id': 101,
  'start_y': 312,
  'start_x': 2695,
  'text': 'OF',
  'bbox': [2695, 312, 2717, 312, 2717, 327, 2695, 327]},
 {'id': 305,
  'start_y': 367,
  'start_x': 2702,
  'text': 'Provi',
  'bbox': [2702, 367, 2727, 367, 2727, 381, 2702, 381]},
 {'id': 308,
  'start_y': 397,
  'start_x': 2703,
  'text': ')',
  'bbox': [2703, 397, 2708, 397, 2708, 410, 2703, 410]},
 {'id': 723,
  'start_y': 1051,
  'start_x': 2703,
  'text': '.',
  'bbox': [2703, 1051, 2711, 1051, 2711, 1067, 2703, 1067]},
 {'id': 316,
  'start_y': 470,
  'start_x': 2710,
  'text': 'C',
  'bbox': [2710, 470, 2729, 470, 2729, 488, 2710, 488]},
 {'id': 326,
  'start_y': 504,
  'start_x': 2718,
  'text': 'L',
  'bbox': [2718, 504, 2733, 504, 2733, 525, 2718, 525]},
 {'id': 102,
  'start_y': 312,
  'start_x': 2721,
  'text': 'P',
  'bbox': [2721, 312, 2731, 312, 2731, 327, 2721, 327]}]


const drawerWidth = 400;

export default function PermanentDrawerRight() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [annotations, setAnnotations] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100); // Zoom level state
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Position state
  const [boxId, setBoxId] = useState()

  const[annotationColor,setAnnotationColor] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  
  //  const [pageSize, setPageSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight
  // });

  const [pageSize, setPageSize] = useState({
    width: (window.innerWidth)-(window.innerWidth*(35/100)),
    height: (window.innerHeight)-(window.innerHeight*(16/100))
  });

  const onResize = () => {
    setPageSize({ width: window.innerWidth, height: window.innerHeight });
  };


  const disableWheelZoom = (event) => {
    console.log("Wheel event detected, preventing default action");
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);



  const colors = ['#39FF14', '#FF6FFF', '#15F4EE', '#F7FD04','#FF5B5B', '#FFAA1D', '#4CA0FF', '#AA63FF'];

  const getSequentialColor = () => {
      const color = colors[colorIndex];
      setColorIndex((colorIndex + 1) % colors.length); 
      return color;
  };

  const location = useLocation();
  const labels = location.state ? location.state.labels : [];
  const defaulttablelabels = location.state ? location.state.defaulttablelabels : [];
  const defaulttableName = location.state ? location.state.defaulttableName : [];

  const [colorIndex, setColorIndex] = useState(0);
  const [tableopen, setTableOpen] = React.useState(false);
  const [tableName, setTableName] = useState("");
  const [tableNameError, settableNameError] = useState(false);

  const [tablelabels, setTablelabels] = useState([]);
  const [newTableLabel, setNewTableLabel] = useState('');

  const [finalTable, setfinalTable] = useState([]);


  const handleTableSubmit = (event) => {
    event.preventDefault();
    if (tableName.trim() === '') {
      settableNameError(true);
    } else {
      settableNameError(false);
      const newTable = {
        id: Date.now(),
        name: tableName,
        labels: [...tablelabels]
      };
      setfinalTable([...finalTable, newTable]);
      setDialogOpen(false);
      setTableName("");
      setTablelabels([]);
      setNewTableLabel('');
      handleClose();
    }
  };

  const handleAddTableLabel = (event) => {
    event.preventDefault();
    if (newTableLabel.trim() === '') return;
    const color = getSequentialColor();
    setTablelabels([...tablelabels, { id: Date.now(), name: newTableLabel, color }]);
    setNewTableLabel('');
  };

  const handleRemoveTableLabel = (id) => {
      setTablelabels(tablelabels.filter(label => label.id !== id));
  };


  useEffect(() => {
    // Reset selection when rows or annotations change
    setSelectedRow(null);
    setSelectedAnnotation(null);
  }, [rows, annotations]);

  //setting a highlight on row click
  const rowSelect = (params) => {
    // Deselect previously selected row
    if (selectedRow && selectedRow.id === params.row.id) {
      setSelectedRow(null);
      setSelectedAnnotation(null);
    } else {
      setSelectedRow(params.row); // Update selected row state
      selectAnnotation(params.row.boxID); // Select corresponding annotation
    }
  };
  const selectAnnotation = (annotationId) => {
    const selectedAnnotation = annotations.find(annotation => annotation.id === annotationId);
    if (selectedAnnotation) {
      setSelectedAnnotation(selectedAnnotation);
      console.log('Annotation selected:', selectedAnnotation);
    }
  };

  function handleDeleteRow(t_id, box_id){
    try {
        // Remove the row from the rows array
        const updatedRows = [];
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.id !== t_id && row.text !== "") {
                updatedRows.push({
                    ...row,
                    id: updatedRows.length + 1,
                });
            }
        }

        // Remove the annotation where id matches box_id
        for (let i = 0; i < annotations.length; i++) {
          if (annotations[i].id === box_id) {
              annotations.splice(i, 1); // Remove 1 element at index i
              break; // Assuming box_id is unique, exit loop after first removal
          }
      }

      setRows(updatedRows);
      setAnnotations([...annotations]);
      // processRowUpdate();
        // setAnnotations(updatedAnnotations);
    } catch (error) {
        console.error("Error occurred while deleting row:", error);
    }
  };

  const handleClickOpen = () => {
    setTableOpen(true);
  };


  const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    // { field: 'boxID', headerName: 'Box ID', width: 150 },
    { field: 'label', headerName: 'Label', width: 130 },
    { 
      field: 'text', 
      headerName: 'Text',
      width: 190,
      editable: true},
    // { 
    //   field: 'action', 
    //   headerName: 'Actions',
    //   width: 150,
    //   renderCell: (params) => (
    //     <DeleteIcon style={{color:"#9E3C5D", cursor:"pointer"}} 
    //     onClick={() => handleDeleteRow(params.row.id,params.row.boxID)} />
    // ),
    // },
  
    // { field: 'bbox', headerName: 'Bounding Box', width: 630 ,},
  ];

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddTable = (columns, rows) => {
    console.log(`Columns: ${columns}, Rows: ${rows}`);
    // Add your table creation logic here
  };

 


  // const onResize = () => {
  //   setPageSize({ width: window.innerWidth, height: window.innerHeight });
  // };
  
  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('wheel', disableWheelZoom, { passive: false });


    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          moveUp();
          break;
        case 'ArrowDown':
          moveDown();
          break;
        case 'ArrowLeft':
          moveLeft();
          break;
        case 'ArrowRight':
          moveRight();
          break;
        default:
          break;
      }
    };

    

    window.addEventListener('keydown', handleKeyDown);
    // window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', disableWheelZoom);
      // window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    updateTable();
  }, [annotations]);


  const onSelect = (id) => {
    selectAnnotation(id)
    console.log("Selected annotation ID:", id);

    const selectedRow = rows.find(row => row.boxID === id);
      if (selectedRow) {
        setSelectedRow(selectedRow);
        console.log("Selected row:",selectedRow);
        setSelectedRowId(selectedRow.id); // Add this line
        console.log("Selected row ID:", selectedRow.id);
      }
  };



  const onChange = (data) => {
    // console.log("Annotation data changed:", data);
    if (selectedLabel) {
      setAnnotationColor(selectedLabel.color);
      const lastAnnotation = data[data.length - 1];
      // lastAnnotation.comment = selectedLabel.name;
      // lastAnnotation.boxID = boxId;
      // lastAnnotation.color = selectedLabel.color
      const updatedAnnotations = data.map(annotation =>
        annotation.id === lastAnnotation.id ? lastAnnotation : annotation
      );

      setAnnotations(updatedAnnotations);
      
      // console.log("Updated annotations:", updatedAnnotations);
    } else {
      setAnnotations(data);
    }
  };

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setAnnotationColor(label.color);  
    setSelectedType("Labels");
    console.info('Selected label:', label);
  };

  // Annotation Style during annotation
  const getAnnotationStyle = () => {
    if (selectedLabel) {
      return {
        padding: 2,
        fontSize: 12,
        fontColor: "black",
        // fontBackground: selectedLabel.color,
        fontFamily: "Roboto",
        borderRadius: 999,
        lineWidth: 2,
        shapeBackground: "hsla(120, 100%, 50%, 0.3)",
        shapeStrokeStyle: annotationColor,
        shadowBlur: 10,
        shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
        transformerBackground: "#5c7cfa",
        transformerSize: 10
      };
    } else {
      return {
        padding: 2,
        fontSize: 12,
        fontColor: "red",
        fontBackground: "#f8f9fa",
        fontFamily: "Roboto",
        borderRadius: 999,
        lineWidth: 2,
        shapeBackground: "hsla(120, 100%, 50%, 0.3)",
        shapeStrokeStyle: "#f8f9fa",
        shadowBlur: 10,
        shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
        transformerBackground: "#5c7cfa",
        transformerSize: 10
      };
    }
  };

  // Control Buttons
  const zoomIn = () => {
    setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 10, 200)); // Max zoom level 200%
  };

  const zoomOut = () => {
    setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 10, 10)); // Min zoom level 10%
  };

  const moveLeft = () => {
    setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x - 10 }));
  };

  const moveRight = () => {
    setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x + 10 }));
  };

  const moveUp = () => {
    setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y - 10 }));
  };

  const moveDown = () => {
    setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y + 10 }));
  };


  const get_ocr_text = () => {
    let text = '';
    const { x, y, width, height } = annotations[annotations.length - 1].mark;
    const bbox = [x, y, x + width, y + height];

    ocrDatas.forEach((item) => {
        const lineBox = item.bbox;
        if (
            lineBox[0] >= bbox[0] &&
            lineBox[1] >= bbox[1] &&
            lineBox[4] <= bbox[2] &&
            lineBox[5] <= bbox[3]
        ) {
            text = `${text} ${item.text}`;
        }
    });

    text = text.trim();
    // console.log(text);
    return text;
};

const updateTable = () => {
  const annotationIds = new Set(annotations.map(annotation => annotation.id));
  const rowIds = new Set(rows.map(row => row.AnnotationId));

  if (rowIds.size > 0) {
    for (let i = 0; i < rows.length; i++) {
      const annotationId = rows[i].AnnotationId;
      let found = false;
      for (const element of annotationIds) {
        if (element === annotationId) {
          found = true;
          break; 
        }
      }
      if (found == false) { 
        handleDeleteRow(rows[i].id, annotationId);
      }
    }
  }
}


const addToTable = () => {
  if (!annotations || annotations.length === 0) {
      return;
  }
  
  const lastAnnotation = annotations[annotations.length - 1];
  if (!lastAnnotation.mark) {
      return;
  }

  const { height, width, x, y } = lastAnnotation.mark;
  const BBID = lastAnnotation.id;

  setBoxId(BBID);

  const corners = [
      [parseFloat(x.toFixed(3)), parseFloat(y.toFixed(3))], // Top-left corner
      [parseFloat((x + width).toFixed(3)), parseFloat(y.toFixed(3))], // Top-right corner
      [parseFloat((x + width).toFixed(3)), parseFloat((y + height).toFixed(3))], // Bottom-right corner
      [parseFloat(x.toFixed(3)), parseFloat((y + height).toFixed(3))] // Bottom-left corner
  ];

  const data = get_ocr_text();

  if (data === "") {
      return;
  }

  const r_label = selectedLabel ? selectedLabel.name : 'No label selected';
  const stat = {
      id: rows.length + 1,
      boxID: BBID,
      AnnotationId: lastAnnotation.id,
      label: r_label,
      text: data,
  };

  // Check if the boxID already exists in rows
  const boxIDExists = rows.some(row => row.boxID === BBID);
  if (boxIDExists) {
      console.log("BoxID already exists. Skipping entry.");
      return; // Exit the function if the boxID already exists
  }

  if (rows.length > 0) {
      const lastRow = rows[rows.length - 1];
      if (lastRow.text === stat.text) {
          console.log("Text in the last row is the same as above row.");
      } else {
          setRows([...rows, stat]);
          console.log("Updated rows:", rows);
      }
  } else {
      setRows([stat]);
      console.log("Updated rows:", rows);      
  }
};
const handleClose = () => {
    setTableOpen(false);
};

const processRowUpdate = (newRow, oldRow) => {
  const updatedRows = rows.map((row) => (row.id === oldRow.id ? newRow : row));
  setRows(updatedRows);
  return newRow;
};

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, mr: `${drawerWidth}px` }}
      >
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1 }}
      >
        <div>

          <div style={{ height: pageSize.height, width: pageSize.width, overflow: 'scroll', position: 'relative', backgroundColor:"#f1f1f1", borderRadius:"10px", border:"5px solid #d8d8d8"  }}>
            <div onMouseUp={addToTable} style={{ transform: `scale(${zoomLevel / 100}) translate(${position.x}px, ${position.y}px)`, transformOrigin: 'top left', minHeight: pageSize.height, minWidth: pageSize.width, display:'inline-block' }}>
              <ReactPictureAnnotation
                image={Sample}
                onSelect={onSelect}
                onChange={onChange}
                width={pageSize.width}
                height={pageSize.height}
                annotationData={annotations}
                annotationStyle={getAnnotationStyle()}
                annotations={annotations}
                selectedId={selectedAnnotation ? selectedAnnotation.id : null}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={zoomOut}>-</Button>
            <span style={{ margin: '0 10px' }}>{zoomLevel}%</span>
            <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={zoomIn}>+</Button>

            <Button sx={{ marginRight: "5px", marginLeft: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveLeft}>&larr;</Button>
            <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveUp}>&uarr;</Button>
            <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveDown}>&darr;</Button>
            <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" onClick={moveRight}>&rarr;</Button>
          </div>
        </div>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <Toolbar />
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
        >
          <Divider></Divider>
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{}}
            >
              <LabelIcon></LabelIcon>
              <Typography variant='h6' style={{marginLeft:'10px'}}>
                Labels
              </Typography>  
            </AccordionSummary>
            <AccordionDetails>
              <Card sx={{ minWidth: 275, backgroundColor:"#f1f1f1", borderRadius:3 }}>
                <CardContent>
                  {labels.map(label => (
                    <Chip
                      key={label.id}
                      label={label.name}
                      onClick={() => handleLabelClick(label)}
                      style={{ backgroundColor: label.color, margin: '5px' }}
                    />
                  ))}
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded disableGutters >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <TableChartIcon></TableChartIcon>
              <Typography variant='h6' style={{marginLeft:'10px'}}>
                Tables
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <div style={{marginBottom:"5px" }}>
                <Button variant="outlined" onClick={handleClickOpen}>
                  Add Table
                </Button> 
                <Dialog
                    open={tableopen}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    id: 'form-dialog',
                    onSubmit: handleTableSubmit
                    }}
                >
                    <DialogTitle>Table Setup</DialogTitle>
                      <DialogContent>
                        <TextField
                            autoFocus
                            required
                            onChange={e => setTableName(e.target.value)}
                            value={tableName}
                            error={tableNameError}
                            margin="dense"
                            id="table"
                            name="table"
                            label="Table Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            sx={{ mb: 3 }}   
                        />
                        <form autoComplete="off" onSubmit={handleAddTableLabel}>
                          <TextField
                              value={newTableLabel}
                              onChange={(e) => setNewTableLabel(e.target.value)}
                              placeholder="Add Label names"
                              sx={{ mb: 3 }}
                              fullWidth
                          />
                          <Button
                              variant="outlined"
                              color="secondary"
                              onClick={handleAddTableLabel}
                              sx={{ mb: 2 }}
                          >
                              Add
                          </Button>
                        
                          <div>
                            {tablelabels.map(label => (
                              <Chip
                                key={label.id}
                                label={label.name}
                                onDelete={() => handleRemoveTableLabel(label.id)}
                                style={{ backgroundColor: label.color, marginBottom: '10px', marginRight: '10px' }}
                              />
                            ))}
                          </div>
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit'>Done</Button>
                      </DialogActions>
                </Dialog>  
              </div>
              <Card sx={{ minWidth: 275, backgroundColor:"#f1f1f1", borderRadius:3 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                  {defaulttableName}
                  </Typography>
                  <CardContent>
                    {defaulttablelabels.map(label => (
                        <Chip
                        key={label.id}
                        label={label.name}
                        // onDelete={() => handleRemovedefaultTableLabel(label.id)}
                        style={{ backgroundColor: label.color, marginBottom: '10px', marginRight: '10px' }}
                        />
                    ))}
                  </CardContent>
                </CardContent>  
              </Card>
              {finalTable.map(table => (
              <Card sx={{ minWidth: 275, marginTop:1, backgroundColor:"#f1f1f1", borderRadius:3 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {table.name}
                  </Typography>
                  <CardContent>
                    {table.labels.map(label => (
                        <Chip
                        key={label.id}
                        label={label.name}
                        // onDelete={() => handleRemoveTableLabel(label.id)}
                        style={{ backgroundColor: label.color, marginBottom: '10px', marginRight: '10px' }}
                        />
                    ))}
                  </CardContent>
                </CardContent>  
              </Card>
              ))}  
            </AccordionDetails>
          </Accordion>



          <Divider></Divider>


          <Accordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <TableViewIcon></TableViewIcon>
              <Typography variant='h6' style={{marginLeft:'10px'}}>
                Data Table
              </Typography>
            </AccordionSummary>
                <AccordionDetails>
                  <div style={{ backgroundColor: '#f1f1f1', height: '400px' }}>
                    <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        processRowUpdate={processRowUpdate}
                        onRowClick={rowSelect}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                          },
                        }}
                        pageSizeOptions={[50, 100]}
                        getRowClassName={(params) =>
                          params.id === selectedRowId ? 'selected-row' : '' // Add this line
                        }
                        sx={{
                          '& .selected-row': {
                            backgroundColor: '#3384FF !important', // Change this to your desired color
                            color: '#000',
                            '&:hover': {
                              backgroundColor: 'red', // Change this to your desired color
                            },
                          },
                        }}
                        // checkboxSelection
                      />
                    </div>
                  </div>
                </AccordionDetails>
                </Accordion>
              </List>
      </Drawer>
    </Box>
  );
}
