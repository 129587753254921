import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImportDropbox from './ImportDropbox';
import { Button } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{width: 1200, height: 700,}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function ImportAnnotation() {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return(
        <Box >
          <Box sx={{ bgcolor: 'background.paper', width: 1250, mt:5, border: '5px solid #674f87', borderRadius: 5, marginInline: 'auto', position:'relative' }}>
            <AppBar position="static" sx={{background: 'linear-gradient(to right, #ef798a, #674f87)', borderRadius: '13px 13px 0px 0px'}}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab sx={{fontWeight:'bold'}} label="Upload Data" {...a11yProps(0)} />
                <Tab sx={{fontWeight:'bold'}} label="Import Dataset" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Box sx={{display: 'flex', width: 1000,height: 600, marginInline:'120px', marginBlock:'25px' }}>
                  <ImportDropbox />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end',}}>
                  <Button variant="contained" sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)' }}>
                    Upload
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Box sx={{display: 'flex', width: 1000,height: 600, marginInline:'120px', marginBlock:'25px' }}>
                  <ImportDropbox />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end',}}>
                  <Button variant="contained" sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)' }}>
                    Upload
                  </Button>
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Box>
    );
}