import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import DataTable from '../../components/TableSetup/datatable';



export default function  WhatsNew (){
	const [value, setValue] = React.useState('1');
  return (
    <GridWrapper>
      <DataTable>
      </DataTable>  
    </GridWrapper>
  );
};