import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import './datatable.css'

export default function DataTable() {
    const [rows, setRows] = useState([
        { id: 1, name: 'Tag 1', matching: 'Automatic' },
        { id: 2, name: 'Tag 2', matching: 'Automatic' },
        { id: 3, name: 'Tag 3', matching: 'Automatic' },
        { id: 4, name: 'Tag 4', matching: 'Automatic' },
        { id: 5, name: 'Tag 5', matching: 'Automatic' },
        { id: 6, name: 'Tag 6', matching: 'Automatic' },
        { id: 7, name: 'Tag 7', matching: 'Automatic' },
        { id: 8, name: 'Tag 8', matching: 'Automatic' },
        { id: 9, name: 'Tag 9', matching: 'Automatic' },
    ]);

    const columns = [
        { field: 'id', headerName: 'ID' , width: 300 },
        { field: 'name', headerName: 'Name', width: 450 },
        { field: 'matching', headerName: 'Matching', width: 450 },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(params.id)}
                    style={{color:'#9E3C5D', backgroundColor:'#f1f1f1', border:'1px solid #9E3C5D '}}
                >
                    Delete
                </Button>
            ),
        },
    ];

    
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [newMatching, setNewMatching] = useState('Automatic');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const newId = rows.length + 1;
        const newRow = { id: newId, name: newName, matching: newMatching };
        setRows([...rows, newRow]);
        setNewName('');
        setNewMatching('Automatic');
        handleClose();
    };

    const handleDelete = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };
    
    return (

        <div>
            <h1 style={{marginTop:'30px', fontFamily:'sans-serif',color:'#432F60'}}>
                Tags
            </h1>
            <div className='DialogueBox' style={{ marginTop: '30px', marginLeft: '88%' }}>
                <React.Fragment>
                    <Button variant="contained" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon />} style={{color:'#9E3C5D', backgroundColor:'#f1f1f1', border:'1px solid #9E3C5D '}} >
                        Create
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                        }}
                    >
                        <DialogTitle>Create New Tag</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                
                            />
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Matching
                                </InputLabel>
                                <NativeSelect
                                value={newMatching}
                                onChange={(e) => setNewMatching(e.target.value)}
                                
                                // inputProps={{
                                //     name: 'matching',
                                //     id: 'uncontrolled-native',
                                // }}
                                >
                                <option value={'Automatic'}>Automatic</option>
                                <option value={'Manual'}>Manual</option>
                                </NativeSelect>
                                
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant='outlined' onClick={handleSave} disabled={!newName.trim()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </div>
            <div className='Table' style={{ height: 631, width: '95%', marginTop: '20px' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                    
                />
            </div>
        </div>
    );
}
