import React from 'react';
import TrainingSetup from '../../components/TrainingSetup/TrainingSetup';





export default function  ModelTraining (){
	const [value, setValue] = React.useState('1');
  return (
      <div >
        <TrainingSetup></TrainingSetup>
      </div>     
  );
};