
import { useNavigate } from "react-router-dom";
import React , { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { cardStyles } from './styles';
export default function BasicCard({ header, content, icon ,click_element,project_id}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = (click_element) => {
    console.log('selected id ',project_id)
    console.log('swindow.globalVar1 ',window.globalVar1)
      window.globalVar1 = project_id;
      navigate(click_element);
  };
  const IconComponent = icon;
  return (
    <Card sx={ cardStyles.card } onClick={() => handleClick(click_element,project_id)} >
      <Box sx={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
        <Card sx={{display: 'flex', width: '80%',height: '80%', borderRadius: '10px'}}>
            <CardMedia  
            component="div"
            sx={ cardStyles.cardmedia}
            >
            <IconComponent /> 
            </CardMedia>
        </Card>
     </Box>

      {/* Box with 80% width */}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h9" className="header-typography">
          {header}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          {content}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}